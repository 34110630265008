import { reactive as aa, ref as q, defineComponent as nt, useSlots as Xa, toRef as ma, onMounted as ot, onUnmounted as Ja, watch as Yt, computed as V, openBlock as k, createElementBlock as U, normalizeClass as Me, unref as f, createVNode as bt, mergeProps as Ve, isRef as $t, createSlots as Xe, renderList as Re, withCtx as ve, renderSlot as z, normalizeProps as Je, guardReactiveProps as yt, createBlock as be, Teleport as mn, createCommentVNode as S, withKeys as fe, withModifiers as ht, Transition as Ft, createElementVNode as se, Fragment as _e, normalizeStyle as Nt, createTextVNode as dt, toDisplayString as Be, resolveDynamicComponent as sa, nextTick as Bt, withDirectives as da, vShow as ca, onBeforeUpdate as fn, getCurrentScope as pn, onScopeDispose as yn, resolveComponent as hn } from "vue";
function at(e) {
  "@babel/helpers - typeof";
  return at = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(r) {
    return typeof r;
  } : function(r) {
    return r && typeof Symbol == "function" && r.constructor === Symbol && r !== Symbol.prototype ? "symbol" : typeof r;
  }, at(e);
}
function ae(e) {
  if (e === null || e === !0 || e === !1)
    return NaN;
  var r = Number(e);
  return isNaN(r) ? r : r < 0 ? Math.ceil(r) : Math.floor(r);
}
function G(e, r) {
  if (r.length < e)
    throw new TypeError(e + " argument" + (e > 1 ? "s" : "") + " required, but only " + r.length + " present");
}
function oe(e) {
  G(1, arguments);
  var r = Object.prototype.toString.call(e);
  return e instanceof Date || at(e) === "object" && r === "[object Date]" ? new Date(e.getTime()) : typeof e == "number" || r === "[object Number]" ? new Date(e) : ((typeof e == "string" || r === "[object String]") && typeof console < "u" && (console.warn("Starting with v2.0.0-beta.1 date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings. See: https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments"), console.warn(new Error().stack)), /* @__PURE__ */ new Date(NaN));
}
function gt(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r);
  return isNaN(t) ? /* @__PURE__ */ new Date(NaN) : (t && a.setDate(a.getDate() + t), a);
}
function st(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r);
  if (isNaN(t))
    return /* @__PURE__ */ new Date(NaN);
  if (!t)
    return a;
  var n = a.getDate(), l = new Date(a.getTime());
  l.setMonth(a.getMonth() + t + 1, 0);
  var o = l.getDate();
  return n >= o ? l : (a.setFullYear(l.getFullYear(), l.getMonth(), n), a);
}
function Ir(e, r) {
  if (G(2, arguments), !r || at(r) !== "object") return /* @__PURE__ */ new Date(NaN);
  var a = r.years ? ae(r.years) : 0, t = r.months ? ae(r.months) : 0, n = r.weeks ? ae(r.weeks) : 0, l = r.days ? ae(r.days) : 0, o = r.hours ? ae(r.hours) : 0, u = r.minutes ? ae(r.minutes) : 0, v = r.seconds ? ae(r.seconds) : 0, h = oe(e), _ = t || a ? st(h, t + a * 12) : h, m = l || n ? gt(_, l + n * 7) : _, s = u + o * 60, w = v + s * 60, I = w * 1e3, R = new Date(m.getTime() + I);
  return R;
}
function gn(e, r) {
  G(2, arguments);
  var a = oe(e).getTime(), t = ae(r);
  return new Date(a + t);
}
var wn = {};
function Dt() {
  return wn;
}
function It(e, r) {
  var a, t, n, l, o, u, v, h;
  G(1, arguments);
  var _ = Dt(), m = ae((a = (t = (n = (l = r == null ? void 0 : r.weekStartsOn) !== null && l !== void 0 ? l : r == null || (o = r.locale) === null || o === void 0 || (u = o.options) === null || u === void 0 ? void 0 : u.weekStartsOn) !== null && n !== void 0 ? n : _.weekStartsOn) !== null && t !== void 0 ? t : (v = _.locale) === null || v === void 0 || (h = v.options) === null || h === void 0 ? void 0 : h.weekStartsOn) !== null && a !== void 0 ? a : 0);
  if (!(m >= 0 && m <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  var s = oe(e), w = s.getDay(), I = (w < m ? 7 : 0) + w - m;
  return s.setDate(s.getDate() - I), s.setHours(0, 0, 0, 0), s;
}
function fa(e) {
  return G(1, arguments), It(e, {
    weekStartsOn: 1
  });
}
function bn(e) {
  G(1, arguments);
  var r = oe(e), a = r.getFullYear(), t = /* @__PURE__ */ new Date(0);
  t.setFullYear(a + 1, 0, 4), t.setHours(0, 0, 0, 0);
  var n = fa(t), l = /* @__PURE__ */ new Date(0);
  l.setFullYear(a, 0, 4), l.setHours(0, 0, 0, 0);
  var o = fa(l);
  return r.getTime() >= n.getTime() ? a + 1 : r.getTime() >= o.getTime() ? a : a - 1;
}
function _n(e) {
  G(1, arguments);
  var r = bn(e), a = /* @__PURE__ */ new Date(0);
  a.setFullYear(r, 0, 4), a.setHours(0, 0, 0, 0);
  var t = fa(a);
  return t;
}
function pa(e) {
  var r = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate(), e.getHours(), e.getMinutes(), e.getSeconds(), e.getMilliseconds()));
  return r.setUTCFullYear(e.getFullYear()), e.getTime() - r.getTime();
}
function vr(e) {
  G(1, arguments);
  var r = oe(e);
  return r.setHours(0, 0, 0, 0), r;
}
var kn = 864e5;
function Dn(e, r) {
  G(2, arguments);
  var a = vr(e), t = vr(r), n = a.getTime() - pa(a), l = t.getTime() - pa(t);
  return Math.round((n - l) / kn);
}
function Tn(e, r) {
  G(2, arguments);
  var a = ae(r), t = a * 7;
  return gt(e, t);
}
function Ur(e, r) {
  G(2, arguments);
  var a = ae(r);
  return st(e, a * 12);
}
var er = 6e4, tr = 36e5, xn = 1e3;
function Rr(e) {
  return G(1, arguments), e instanceof Date || at(e) === "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function Jt(e) {
  if (G(1, arguments), !Rr(e) && typeof e != "number")
    return !1;
  var r = oe(e);
  return !isNaN(Number(r));
}
function xa(e) {
  G(1, arguments);
  var r = oe(e), a = r.getMonth();
  return r.setFullYear(r.getFullYear(), a + 1, 0), r.setHours(23, 59, 59, 999), r;
}
function mr(e, r) {
  var a;
  G(1, arguments);
  var t = e || {}, n = oe(t.start), l = oe(t.end), o = l.getTime();
  if (!(n.getTime() <= o))
    throw new RangeError("Invalid interval");
  var u = [], v = n;
  v.setHours(0, 0, 0, 0);
  var h = Number((a = void 0) !== null && a !== void 0 ? a : 1);
  if (h < 1 || isNaN(h)) throw new RangeError("`options.step` must be a number greater than 1");
  for (; v.getTime() <= o; )
    u.push(oe(v)), v.setDate(v.getDate() + h), v.setHours(0, 0, 0, 0);
  return u;
}
function Ma(e) {
  G(1, arguments);
  var r = oe(e);
  return r.setDate(1), r.setHours(0, 0, 0, 0), r;
}
function ra(e, r) {
  var a, t, n, l, o, u, v, h;
  G(1, arguments);
  var _ = Dt(), m = ae((a = (t = (n = (l = r == null ? void 0 : r.weekStartsOn) !== null && l !== void 0 ? l : r == null || (o = r.locale) === null || o === void 0 || (u = o.options) === null || u === void 0 ? void 0 : u.weekStartsOn) !== null && n !== void 0 ? n : _.weekStartsOn) !== null && t !== void 0 ? t : (v = _.locale) === null || v === void 0 || (h = v.options) === null || h === void 0 ? void 0 : h.weekStartsOn) !== null && a !== void 0 ? a : 0);
  if (!(m >= 0 && m <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  var s = oe(e), w = s.getDay(), I = (w < m ? -7 : 0) + 6 - (w - m);
  return s.setDate(s.getDate() + I), s.setHours(23, 59, 59, 999), s;
}
function Er(e, r) {
  G(2, arguments);
  var a = ae(r);
  return gn(e, -a);
}
var Mn = 864e5;
function Cn(e) {
  G(1, arguments);
  var r = oe(e), a = r.getTime();
  r.setUTCMonth(0, 1), r.setUTCHours(0, 0, 0, 0);
  var t = r.getTime(), n = a - t;
  return Math.floor(n / Mn) + 1;
}
function Lt(e) {
  G(1, arguments);
  var r = 1, a = oe(e), t = a.getUTCDay(), n = (t < r ? 7 : 0) + t - r;
  return a.setUTCDate(a.getUTCDate() - n), a.setUTCHours(0, 0, 0, 0), a;
}
function Wr(e) {
  G(1, arguments);
  var r = oe(e), a = r.getUTCFullYear(), t = /* @__PURE__ */ new Date(0);
  t.setUTCFullYear(a + 1, 0, 4), t.setUTCHours(0, 0, 0, 0);
  var n = Lt(t), l = /* @__PURE__ */ new Date(0);
  l.setUTCFullYear(a, 0, 4), l.setUTCHours(0, 0, 0, 0);
  var o = Lt(l);
  return r.getTime() >= n.getTime() ? a + 1 : r.getTime() >= o.getTime() ? a : a - 1;
}
function Pn(e) {
  G(1, arguments);
  var r = Wr(e), a = /* @__PURE__ */ new Date(0);
  a.setUTCFullYear(r, 0, 4), a.setUTCHours(0, 0, 0, 0);
  var t = Lt(a);
  return t;
}
var On = 6048e5;
function Vr(e) {
  G(1, arguments);
  var r = oe(e), a = Lt(r).getTime() - Pn(r).getTime();
  return Math.round(a / On) + 1;
}
function Ut(e, r) {
  var a, t, n, l, o, u, v, h;
  G(1, arguments);
  var _ = Dt(), m = ae((a = (t = (n = (l = r == null ? void 0 : r.weekStartsOn) !== null && l !== void 0 ? l : r == null || (o = r.locale) === null || o === void 0 || (u = o.options) === null || u === void 0 ? void 0 : u.weekStartsOn) !== null && n !== void 0 ? n : _.weekStartsOn) !== null && t !== void 0 ? t : (v = _.locale) === null || v === void 0 || (h = v.options) === null || h === void 0 ? void 0 : h.weekStartsOn) !== null && a !== void 0 ? a : 0);
  if (!(m >= 0 && m <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  var s = oe(e), w = s.getUTCDay(), I = (w < m ? 7 : 0) + w - m;
  return s.setUTCDate(s.getUTCDate() - I), s.setUTCHours(0, 0, 0, 0), s;
}
function ar(e, r) {
  var a, t, n, l, o, u, v, h;
  G(1, arguments);
  var _ = oe(e), m = _.getUTCFullYear(), s = Dt(), w = ae((a = (t = (n = (l = r == null ? void 0 : r.firstWeekContainsDate) !== null && l !== void 0 ? l : r == null || (o = r.locale) === null || o === void 0 || (u = o.options) === null || u === void 0 ? void 0 : u.firstWeekContainsDate) !== null && n !== void 0 ? n : s.firstWeekContainsDate) !== null && t !== void 0 ? t : (v = s.locale) === null || v === void 0 || (h = v.options) === null || h === void 0 ? void 0 : h.firstWeekContainsDate) !== null && a !== void 0 ? a : 1);
  if (!(w >= 1 && w <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var I = /* @__PURE__ */ new Date(0);
  I.setUTCFullYear(m + 1, 0, w), I.setUTCHours(0, 0, 0, 0);
  var R = Ut(I, r), K = /* @__PURE__ */ new Date(0);
  K.setUTCFullYear(m, 0, w), K.setUTCHours(0, 0, 0, 0);
  var X = Ut(K, r);
  return _.getTime() >= R.getTime() ? m + 1 : _.getTime() >= X.getTime() ? m : m - 1;
}
function $n(e, r) {
  var a, t, n, l, o, u, v, h;
  G(1, arguments);
  var _ = Dt(), m = ae((a = (t = (n = (l = r == null ? void 0 : r.firstWeekContainsDate) !== null && l !== void 0 ? l : r == null || (o = r.locale) === null || o === void 0 || (u = o.options) === null || u === void 0 ? void 0 : u.firstWeekContainsDate) !== null && n !== void 0 ? n : _.firstWeekContainsDate) !== null && t !== void 0 ? t : (v = _.locale) === null || v === void 0 || (h = v.options) === null || h === void 0 ? void 0 : h.firstWeekContainsDate) !== null && a !== void 0 ? a : 1), s = ar(e, r), w = /* @__PURE__ */ new Date(0);
  w.setUTCFullYear(s, 0, m), w.setUTCHours(0, 0, 0, 0);
  var I = Ut(w, r);
  return I;
}
var Nn = 6048e5;
function Br(e, r) {
  G(1, arguments);
  var a = oe(e), t = Ut(a, r).getTime() - $n(a, r).getTime();
  return Math.round(t / Nn) + 1;
}
function De(e, r) {
  for (var a = e < 0 ? "-" : "", t = Math.abs(e).toString(); t.length < r; )
    t = "0" + t;
  return a + t;
}
var wt = {
  // Year
  y: function(r, a) {
    var t = r.getUTCFullYear(), n = t > 0 ? t : 1 - t;
    return De(a === "yy" ? n % 100 : n, a.length);
  },
  // Month
  M: function(r, a) {
    var t = r.getUTCMonth();
    return a === "M" ? String(t + 1) : De(t + 1, 2);
  },
  // Day of the month
  d: function(r, a) {
    return De(r.getUTCDate(), a.length);
  },
  // AM or PM
  a: function(r, a) {
    var t = r.getUTCHours() / 12 >= 1 ? "pm" : "am";
    switch (a) {
      case "a":
      case "aa":
        return t.toUpperCase();
      case "aaa":
        return t;
      case "aaaaa":
        return t[0];
      case "aaaa":
      default:
        return t === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h: function(r, a) {
    return De(r.getUTCHours() % 12 || 12, a.length);
  },
  // Hour [0-23]
  H: function(r, a) {
    return De(r.getUTCHours(), a.length);
  },
  // Minute
  m: function(r, a) {
    return De(r.getUTCMinutes(), a.length);
  },
  // Second
  s: function(r, a) {
    return De(r.getUTCSeconds(), a.length);
  },
  // Fraction of second
  S: function(r, a) {
    var t = a.length, n = r.getUTCMilliseconds(), l = Math.floor(n * Math.pow(10, t - 3));
    return De(l, a.length);
  }
}, Wt = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, Sn = {
  // Era
  G: function(r, a, t) {
    var n = r.getUTCFullYear() > 0 ? 1 : 0;
    switch (a) {
      // AD, BC
      case "G":
      case "GG":
      case "GGG":
        return t.era(n, {
          width: "abbreviated"
        });
      // A, B
      case "GGGGG":
        return t.era(n, {
          width: "narrow"
        });
      // Anno Domini, Before Christ
      case "GGGG":
      default:
        return t.era(n, {
          width: "wide"
        });
    }
  },
  // Year
  y: function(r, a, t) {
    if (a === "yo") {
      var n = r.getUTCFullYear(), l = n > 0 ? n : 1 - n;
      return t.ordinalNumber(l, {
        unit: "year"
      });
    }
    return wt.y(r, a);
  },
  // Local week-numbering year
  Y: function(r, a, t, n) {
    var l = ar(r, n), o = l > 0 ? l : 1 - l;
    if (a === "YY") {
      var u = o % 100;
      return De(u, 2);
    }
    return a === "Yo" ? t.ordinalNumber(o, {
      unit: "year"
    }) : De(o, a.length);
  },
  // ISO week-numbering year
  R: function(r, a) {
    var t = Wr(r);
    return De(t, a.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(r, a) {
    var t = r.getUTCFullYear();
    return De(t, a.length);
  },
  // Quarter
  Q: function(r, a, t) {
    var n = Math.ceil((r.getUTCMonth() + 1) / 3);
    switch (a) {
      // 1, 2, 3, 4
      case "Q":
        return String(n);
      // 01, 02, 03, 04
      case "QQ":
        return De(n, 2);
      // 1st, 2nd, 3rd, 4th
      case "Qo":
        return t.ordinalNumber(n, {
          unit: "quarter"
        });
      // Q1, Q2, Q3, Q4
      case "QQQ":
        return t.quarter(n, {
          width: "abbreviated",
          context: "formatting"
        });
      // 1, 2, 3, 4 (narrow quarter; could be not numerical)
      case "QQQQQ":
        return t.quarter(n, {
          width: "narrow",
          context: "formatting"
        });
      // 1st quarter, 2nd quarter, ...
      case "QQQQ":
      default:
        return t.quarter(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(r, a, t) {
    var n = Math.ceil((r.getUTCMonth() + 1) / 3);
    switch (a) {
      // 1, 2, 3, 4
      case "q":
        return String(n);
      // 01, 02, 03, 04
      case "qq":
        return De(n, 2);
      // 1st, 2nd, 3rd, 4th
      case "qo":
        return t.ordinalNumber(n, {
          unit: "quarter"
        });
      // Q1, Q2, Q3, Q4
      case "qqq":
        return t.quarter(n, {
          width: "abbreviated",
          context: "standalone"
        });
      // 1, 2, 3, 4 (narrow quarter; could be not numerical)
      case "qqqqq":
        return t.quarter(n, {
          width: "narrow",
          context: "standalone"
        });
      // 1st quarter, 2nd quarter, ...
      case "qqqq":
      default:
        return t.quarter(n, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(r, a, t) {
    var n = r.getUTCMonth();
    switch (a) {
      case "M":
      case "MM":
        return wt.M(r, a);
      // 1st, 2nd, ..., 12th
      case "Mo":
        return t.ordinalNumber(n + 1, {
          unit: "month"
        });
      // Jan, Feb, ..., Dec
      case "MMM":
        return t.month(n, {
          width: "abbreviated",
          context: "formatting"
        });
      // J, F, ..., D
      case "MMMMM":
        return t.month(n, {
          width: "narrow",
          context: "formatting"
        });
      // January, February, ..., December
      case "MMMM":
      default:
        return t.month(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone month
  L: function(r, a, t) {
    var n = r.getUTCMonth();
    switch (a) {
      // 1, 2, ..., 12
      case "L":
        return String(n + 1);
      // 01, 02, ..., 12
      case "LL":
        return De(n + 1, 2);
      // 1st, 2nd, ..., 12th
      case "Lo":
        return t.ordinalNumber(n + 1, {
          unit: "month"
        });
      // Jan, Feb, ..., Dec
      case "LLL":
        return t.month(n, {
          width: "abbreviated",
          context: "standalone"
        });
      // J, F, ..., D
      case "LLLLL":
        return t.month(n, {
          width: "narrow",
          context: "standalone"
        });
      // January, February, ..., December
      case "LLLL":
      default:
        return t.month(n, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Local week of year
  w: function(r, a, t, n) {
    var l = Br(r, n);
    return a === "wo" ? t.ordinalNumber(l, {
      unit: "week"
    }) : De(l, a.length);
  },
  // ISO week of year
  I: function(r, a, t) {
    var n = Vr(r);
    return a === "Io" ? t.ordinalNumber(n, {
      unit: "week"
    }) : De(n, a.length);
  },
  // Day of the month
  d: function(r, a, t) {
    return a === "do" ? t.ordinalNumber(r.getUTCDate(), {
      unit: "date"
    }) : wt.d(r, a);
  },
  // Day of year
  D: function(r, a, t) {
    var n = Cn(r);
    return a === "Do" ? t.ordinalNumber(n, {
      unit: "dayOfYear"
    }) : De(n, a.length);
  },
  // Day of week
  E: function(r, a, t) {
    var n = r.getUTCDay();
    switch (a) {
      // Tue
      case "E":
      case "EE":
      case "EEE":
        return t.day(n, {
          width: "abbreviated",
          context: "formatting"
        });
      // T
      case "EEEEE":
        return t.day(n, {
          width: "narrow",
          context: "formatting"
        });
      // Tu
      case "EEEEEE":
        return t.day(n, {
          width: "short",
          context: "formatting"
        });
      // Tuesday
      case "EEEE":
      default:
        return t.day(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(r, a, t, n) {
    var l = r.getUTCDay(), o = (l - n.weekStartsOn + 8) % 7 || 7;
    switch (a) {
      // Numerical value (Nth day of week with current locale or weekStartsOn)
      case "e":
        return String(o);
      // Padded numerical value
      case "ee":
        return De(o, 2);
      // 1st, 2nd, ..., 7th
      case "eo":
        return t.ordinalNumber(o, {
          unit: "day"
        });
      case "eee":
        return t.day(l, {
          width: "abbreviated",
          context: "formatting"
        });
      // T
      case "eeeee":
        return t.day(l, {
          width: "narrow",
          context: "formatting"
        });
      // Tu
      case "eeeeee":
        return t.day(l, {
          width: "short",
          context: "formatting"
        });
      // Tuesday
      case "eeee":
      default:
        return t.day(l, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(r, a, t, n) {
    var l = r.getUTCDay(), o = (l - n.weekStartsOn + 8) % 7 || 7;
    switch (a) {
      // Numerical value (same as in `e`)
      case "c":
        return String(o);
      // Padded numerical value
      case "cc":
        return De(o, a.length);
      // 1st, 2nd, ..., 7th
      case "co":
        return t.ordinalNumber(o, {
          unit: "day"
        });
      case "ccc":
        return t.day(l, {
          width: "abbreviated",
          context: "standalone"
        });
      // T
      case "ccccc":
        return t.day(l, {
          width: "narrow",
          context: "standalone"
        });
      // Tu
      case "cccccc":
        return t.day(l, {
          width: "short",
          context: "standalone"
        });
      // Tuesday
      case "cccc":
      default:
        return t.day(l, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(r, a, t) {
    var n = r.getUTCDay(), l = n === 0 ? 7 : n;
    switch (a) {
      // 2
      case "i":
        return String(l);
      // 02
      case "ii":
        return De(l, a.length);
      // 2nd
      case "io":
        return t.ordinalNumber(l, {
          unit: "day"
        });
      // Tue
      case "iii":
        return t.day(n, {
          width: "abbreviated",
          context: "formatting"
        });
      // T
      case "iiiii":
        return t.day(n, {
          width: "narrow",
          context: "formatting"
        });
      // Tu
      case "iiiiii":
        return t.day(n, {
          width: "short",
          context: "formatting"
        });
      // Tuesday
      case "iiii":
      default:
        return t.day(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(r, a, t) {
    var n = r.getUTCHours(), l = n / 12 >= 1 ? "pm" : "am";
    switch (a) {
      case "a":
      case "aa":
        return t.dayPeriod(l, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return t.dayPeriod(l, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return t.dayPeriod(l, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return t.dayPeriod(l, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(r, a, t) {
    var n = r.getUTCHours(), l;
    switch (n === 12 ? l = Wt.noon : n === 0 ? l = Wt.midnight : l = n / 12 >= 1 ? "pm" : "am", a) {
      case "b":
      case "bb":
        return t.dayPeriod(l, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return t.dayPeriod(l, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return t.dayPeriod(l, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return t.dayPeriod(l, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(r, a, t) {
    var n = r.getUTCHours(), l;
    switch (n >= 17 ? l = Wt.evening : n >= 12 ? l = Wt.afternoon : n >= 4 ? l = Wt.morning : l = Wt.night, a) {
      case "B":
      case "BB":
      case "BBB":
        return t.dayPeriod(l, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return t.dayPeriod(l, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return t.dayPeriod(l, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(r, a, t) {
    if (a === "ho") {
      var n = r.getUTCHours() % 12;
      return n === 0 && (n = 12), t.ordinalNumber(n, {
        unit: "hour"
      });
    }
    return wt.h(r, a);
  },
  // Hour [0-23]
  H: function(r, a, t) {
    return a === "Ho" ? t.ordinalNumber(r.getUTCHours(), {
      unit: "hour"
    }) : wt.H(r, a);
  },
  // Hour [0-11]
  K: function(r, a, t) {
    var n = r.getUTCHours() % 12;
    return a === "Ko" ? t.ordinalNumber(n, {
      unit: "hour"
    }) : De(n, a.length);
  },
  // Hour [1-24]
  k: function(r, a, t) {
    var n = r.getUTCHours();
    return n === 0 && (n = 24), a === "ko" ? t.ordinalNumber(n, {
      unit: "hour"
    }) : De(n, a.length);
  },
  // Minute
  m: function(r, a, t) {
    return a === "mo" ? t.ordinalNumber(r.getUTCMinutes(), {
      unit: "minute"
    }) : wt.m(r, a);
  },
  // Second
  s: function(r, a, t) {
    return a === "so" ? t.ordinalNumber(r.getUTCSeconds(), {
      unit: "second"
    }) : wt.s(r, a);
  },
  // Fraction of second
  S: function(r, a) {
    return wt.S(r, a);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(r, a, t, n) {
    var l = n._originalDate || r, o = l.getTimezoneOffset();
    if (o === 0)
      return "Z";
    switch (a) {
      // Hours and optional minutes
      case "X":
        return pr(o);
      // Hours, minutes and optional seconds without `:` delimiter
      // Note: neither ISO-8601 nor JavaScript supports seconds in timezone offsets
      // so this token always has the same output as `XX`
      case "XXXX":
      case "XX":
        return Ot(o);
      // Hours, minutes and optional seconds with `:` delimiter
      // Note: neither ISO-8601 nor JavaScript supports seconds in timezone offsets
      // so this token always has the same output as `XXX`
      case "XXXXX":
      case "XXX":
      // Hours and minutes with `:` delimiter
      default:
        return Ot(o, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(r, a, t, n) {
    var l = n._originalDate || r, o = l.getTimezoneOffset();
    switch (a) {
      // Hours and optional minutes
      case "x":
        return pr(o);
      // Hours, minutes and optional seconds without `:` delimiter
      // Note: neither ISO-8601 nor JavaScript supports seconds in timezone offsets
      // so this token always has the same output as `xx`
      case "xxxx":
      case "xx":
        return Ot(o);
      // Hours, minutes and optional seconds with `:` delimiter
      // Note: neither ISO-8601 nor JavaScript supports seconds in timezone offsets
      // so this token always has the same output as `xxx`
      case "xxxxx":
      case "xxx":
      // Hours and minutes with `:` delimiter
      default:
        return Ot(o, ":");
    }
  },
  // Timezone (GMT)
  O: function(r, a, t, n) {
    var l = n._originalDate || r, o = l.getTimezoneOffset();
    switch (a) {
      // Short
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + fr(o, ":");
      // Long
      case "OOOO":
      default:
        return "GMT" + Ot(o, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(r, a, t, n) {
    var l = n._originalDate || r, o = l.getTimezoneOffset();
    switch (a) {
      // Short
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + fr(o, ":");
      // Long
      case "zzzz":
      default:
        return "GMT" + Ot(o, ":");
    }
  },
  // Seconds timestamp
  t: function(r, a, t, n) {
    var l = n._originalDate || r, o = Math.floor(l.getTime() / 1e3);
    return De(o, a.length);
  },
  // Milliseconds timestamp
  T: function(r, a, t, n) {
    var l = n._originalDate || r, o = l.getTime();
    return De(o, a.length);
  }
};
function fr(e, r) {
  var a = e > 0 ? "-" : "+", t = Math.abs(e), n = Math.floor(t / 60), l = t % 60;
  if (l === 0)
    return a + String(n);
  var o = r;
  return a + String(n) + o + De(l, 2);
}
function pr(e, r) {
  if (e % 60 === 0) {
    var a = e > 0 ? "-" : "+";
    return a + De(Math.abs(e) / 60, 2);
  }
  return Ot(e, r);
}
function Ot(e, r) {
  var a = r || "", t = e > 0 ? "-" : "+", n = Math.abs(e), l = De(Math.floor(n / 60), 2), o = De(n % 60, 2);
  return t + l + a + o;
}
var yr = function(r, a) {
  switch (r) {
    case "P":
      return a.date({
        width: "short"
      });
    case "PP":
      return a.date({
        width: "medium"
      });
    case "PPP":
      return a.date({
        width: "long"
      });
    case "PPPP":
    default:
      return a.date({
        width: "full"
      });
  }
}, Lr = function(r, a) {
  switch (r) {
    case "p":
      return a.time({
        width: "short"
      });
    case "pp":
      return a.time({
        width: "medium"
      });
    case "ppp":
      return a.time({
        width: "long"
      });
    case "pppp":
    default:
      return a.time({
        width: "full"
      });
  }
}, An = function(r, a) {
  var t = r.match(/(P+)(p+)?/) || [], n = t[1], l = t[2];
  if (!l)
    return yr(r, a);
  var o;
  switch (n) {
    case "P":
      o = a.dateTime({
        width: "short"
      });
      break;
    case "PP":
      o = a.dateTime({
        width: "medium"
      });
      break;
    case "PPP":
      o = a.dateTime({
        width: "long"
      });
      break;
    case "PPPP":
    default:
      o = a.dateTime({
        width: "full"
      });
      break;
  }
  return o.replace("{{date}}", yr(n, a)).replace("{{time}}", Lr(l, a));
}, za = {
  p: Lr,
  P: An
}, Yn = ["D", "DD"], In = ["YY", "YYYY"];
function Hr(e) {
  return Yn.indexOf(e) !== -1;
}
function Fr(e) {
  return In.indexOf(e) !== -1;
}
function ya(e, r, a) {
  if (e === "YYYY")
    throw new RangeError("Use `yyyy` instead of `YYYY` (in `".concat(r, "`) for formatting years to the input `").concat(a, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (e === "YY")
    throw new RangeError("Use `yy` instead of `YY` (in `".concat(r, "`) for formatting years to the input `").concat(a, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (e === "D")
    throw new RangeError("Use `d` instead of `D` (in `".concat(r, "`) for formatting days of the month to the input `").concat(a, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (e === "DD")
    throw new RangeError("Use `dd` instead of `DD` (in `".concat(r, "`) for formatting days of the month to the input `").concat(a, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
}
var Un = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, Rn = function(r, a, t) {
  var n, l = Un[r];
  return typeof l == "string" ? n = l : a === 1 ? n = l.one : n = l.other.replace("{{count}}", a.toString()), t != null && t.addSuffix ? t.comparison && t.comparison > 0 ? "in " + n : n + " ago" : n;
};
function Ia(e) {
  return function() {
    var r = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, a = r.width ? String(r.width) : e.defaultWidth, t = e.formats[a] || e.formats[e.defaultWidth];
    return t;
  };
}
var En = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, Wn = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, Vn = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, Bn = {
  date: Ia({
    formats: En,
    defaultWidth: "full"
  }),
  time: Ia({
    formats: Wn,
    defaultWidth: "full"
  }),
  dateTime: Ia({
    formats: Vn,
    defaultWidth: "full"
  })
}, Ln = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, Hn = function(r, a, t, n) {
  return Ln[r];
};
function Kt(e) {
  return function(r, a) {
    var t = a != null && a.context ? String(a.context) : "standalone", n;
    if (t === "formatting" && e.formattingValues) {
      var l = e.defaultFormattingWidth || e.defaultWidth, o = a != null && a.width ? String(a.width) : l;
      n = e.formattingValues[o] || e.formattingValues[l];
    } else {
      var u = e.defaultWidth, v = a != null && a.width ? String(a.width) : e.defaultWidth;
      n = e.values[v] || e.values[u];
    }
    var h = e.argumentCallback ? e.argumentCallback(r) : r;
    return n[h];
  };
}
var Fn = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, qn = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, Qn = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}, zn = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
}, Gn = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, Kn = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, Zn = function(r, a) {
  var t = Number(r), n = t % 100;
  if (n > 20 || n < 10)
    switch (n % 10) {
      case 1:
        return t + "st";
      case 2:
        return t + "nd";
      case 3:
        return t + "rd";
    }
  return t + "th";
}, jn = {
  ordinalNumber: Zn,
  era: Kt({
    values: Fn,
    defaultWidth: "wide"
  }),
  quarter: Kt({
    values: qn,
    defaultWidth: "wide",
    argumentCallback: function(r) {
      return r - 1;
    }
  }),
  month: Kt({
    values: Qn,
    defaultWidth: "wide"
  }),
  day: Kt({
    values: zn,
    defaultWidth: "wide"
  }),
  dayPeriod: Kt({
    values: Gn,
    defaultWidth: "wide",
    formattingValues: Kn,
    defaultFormattingWidth: "wide"
  })
};
function Zt(e) {
  return function(r) {
    var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, t = a.width, n = t && e.matchPatterns[t] || e.matchPatterns[e.defaultMatchWidth], l = r.match(n);
    if (!l)
      return null;
    var o = l[0], u = t && e.parsePatterns[t] || e.parsePatterns[e.defaultParseWidth], v = Array.isArray(u) ? Jn(u, function(m) {
      return m.test(o);
    }) : Xn(u, function(m) {
      return m.test(o);
    }), h;
    h = e.valueCallback ? e.valueCallback(v) : v, h = a.valueCallback ? a.valueCallback(h) : h;
    var _ = r.slice(o.length);
    return {
      value: h,
      rest: _
    };
  };
}
function Xn(e, r) {
  for (var a in e)
    if (e.hasOwnProperty(a) && r(e[a]))
      return a;
}
function Jn(e, r) {
  for (var a = 0; a < e.length; a++)
    if (r(e[a]))
      return a;
}
function eo(e) {
  return function(r) {
    var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, t = r.match(e.matchPattern);
    if (!t) return null;
    var n = t[0], l = r.match(e.parsePattern);
    if (!l) return null;
    var o = e.valueCallback ? e.valueCallback(l[0]) : l[0];
    o = a.valueCallback ? a.valueCallback(o) : o;
    var u = r.slice(n.length);
    return {
      value: o,
      rest: u
    };
  };
}
var to = /^(\d+)(th|st|nd|rd)?/i, ao = /\d+/i, ro = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, no = {
  any: [/^b/i, /^(a|c)/i]
}, oo = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, lo = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, io = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, uo = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
}, so = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, co = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, vo = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, mo = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, fo = {
  ordinalNumber: eo({
    matchPattern: to,
    parsePattern: ao,
    valueCallback: function(r) {
      return parseInt(r, 10);
    }
  }),
  era: Zt({
    matchPatterns: ro,
    defaultMatchWidth: "wide",
    parsePatterns: no,
    defaultParseWidth: "any"
  }),
  quarter: Zt({
    matchPatterns: oo,
    defaultMatchWidth: "wide",
    parsePatterns: lo,
    defaultParseWidth: "any",
    valueCallback: function(r) {
      return r + 1;
    }
  }),
  month: Zt({
    matchPatterns: io,
    defaultMatchWidth: "wide",
    parsePatterns: uo,
    defaultParseWidth: "any"
  }),
  day: Zt({
    matchPatterns: so,
    defaultMatchWidth: "wide",
    parsePatterns: co,
    defaultParseWidth: "any"
  }),
  dayPeriod: Zt({
    matchPatterns: vo,
    defaultMatchWidth: "any",
    parsePatterns: mo,
    defaultParseWidth: "any"
  })
}, qr = {
  code: "en-US",
  formatDistance: Rn,
  formatLong: Bn,
  formatRelative: Hn,
  localize: jn,
  match: fo,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
}, po = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, yo = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, ho = /^'([^]*?)'?$/, go = /''/g, wo = /[a-zA-Z]/;
function _t(e, r, a) {
  var t, n, l, o, u, v, h, _, m, s, w, I, R, K, X, B, A, H;
  G(2, arguments);
  var te = String(r), le = Dt(), g = (t = (n = a == null ? void 0 : a.locale) !== null && n !== void 0 ? n : le.locale) !== null && t !== void 0 ? t : qr, C = ae((l = (o = (u = (v = a == null ? void 0 : a.firstWeekContainsDate) !== null && v !== void 0 ? v : a == null || (h = a.locale) === null || h === void 0 || (_ = h.options) === null || _ === void 0 ? void 0 : _.firstWeekContainsDate) !== null && u !== void 0 ? u : le.firstWeekContainsDate) !== null && o !== void 0 ? o : (m = le.locale) === null || m === void 0 || (s = m.options) === null || s === void 0 ? void 0 : s.firstWeekContainsDate) !== null && l !== void 0 ? l : 1);
  if (!(C >= 1 && C <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var E = ae((w = (I = (R = (K = a == null ? void 0 : a.weekStartsOn) !== null && K !== void 0 ? K : a == null || (X = a.locale) === null || X === void 0 || (B = X.options) === null || B === void 0 ? void 0 : B.weekStartsOn) !== null && R !== void 0 ? R : le.weekStartsOn) !== null && I !== void 0 ? I : (A = le.locale) === null || A === void 0 || (H = A.options) === null || H === void 0 ? void 0 : H.weekStartsOn) !== null && w !== void 0 ? w : 0);
  if (!(E >= 0 && E <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  if (!g.localize)
    throw new RangeError("locale must contain localize property");
  if (!g.formatLong)
    throw new RangeError("locale must contain formatLong property");
  var Z = oe(e);
  if (!Jt(Z))
    throw new RangeError("Invalid time value");
  var Y = pa(Z), F = Er(Z, Y), ie = {
    firstWeekContainsDate: C,
    weekStartsOn: E,
    locale: g,
    _originalDate: Z
  }, ue = te.match(yo).map(function(x) {
    var P = x[0];
    if (P === "p" || P === "P") {
      var i = za[P];
      return i(x, g.formatLong);
    }
    return x;
  }).join("").match(po).map(function(x) {
    if (x === "''")
      return "'";
    var P = x[0];
    if (P === "'")
      return bo(x);
    var i = Sn[P];
    if (i)
      return !(a != null && a.useAdditionalWeekYearTokens) && Fr(x) && ya(x, r, String(e)), !(a != null && a.useAdditionalDayOfYearTokens) && Hr(x) && ya(x, r, String(e)), i(F, x, g.localize, ie);
    if (P.match(wo))
      throw new RangeError("Format string contains an unescaped latin alphabet character `" + P + "`");
    return x;
  }).join("");
  return ue;
}
function bo(e) {
  var r = e.match(ho);
  return r ? r[1].replace(go, "'") : e;
}
function _o(e, r) {
  if (e == null)
    throw new TypeError("assign requires that input parameter not be null or undefined");
  for (var a in r)
    Object.prototype.hasOwnProperty.call(r, a) && (e[a] = r[a]);
  return e;
}
function ko(e) {
  G(1, arguments);
  var r = oe(e), a = r.getDay();
  return a;
}
function Do(e) {
  G(1, arguments);
  var r = oe(e), a = r.getFullYear(), t = r.getMonth(), n = /* @__PURE__ */ new Date(0);
  return n.setFullYear(a, t + 1, 0), n.setHours(0, 0, 0, 0), n.getDate();
}
function ft(e) {
  G(1, arguments);
  var r = oe(e), a = r.getHours();
  return a;
}
var To = 6048e5;
function xo(e) {
  G(1, arguments);
  var r = oe(e), a = fa(r).getTime() - _n(r).getTime();
  return Math.round(a / To) + 1;
}
function pt(e) {
  G(1, arguments);
  var r = oe(e), a = r.getMinutes();
  return a;
}
function Oe(e) {
  G(1, arguments);
  var r = oe(e), a = r.getMonth();
  return a;
}
function Ht(e) {
  G(1, arguments);
  var r = oe(e), a = r.getSeconds();
  return a;
}
function $e(e) {
  return G(1, arguments), oe(e).getFullYear();
}
function rr(e, r) {
  G(2, arguments);
  var a = oe(e), t = oe(r);
  return a.getTime() > t.getTime();
}
function nr(e, r) {
  G(2, arguments);
  var a = oe(e), t = oe(r);
  return a.getTime() < t.getTime();
}
function Qr(e, r) {
  G(2, arguments);
  var a = oe(e), t = oe(r);
  return a.getTime() === t.getTime();
}
function hr(e, r) {
  (r == null || r > e.length) && (r = e.length);
  for (var a = 0, t = Array(r); a < r; a++) t[a] = e[a];
  return t;
}
function Mo(e, r) {
  if (e) {
    if (typeof e == "string") return hr(e, r);
    var a = {}.toString.call(e).slice(8, -1);
    return a === "Object" && e.constructor && (a = e.constructor.name), a === "Map" || a === "Set" ? Array.from(e) : a === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(a) ? hr(e, r) : void 0;
  }
}
function gr(e, r) {
  var a = typeof Symbol < "u" && e[Symbol.iterator] || e["@@iterator"];
  if (!a) {
    if (Array.isArray(e) || (a = Mo(e)) || r) {
      a && (e = a);
      var t = 0, n = function() {
      };
      return {
        s: n,
        n: function() {
          return t >= e.length ? {
            done: !0
          } : {
            done: !1,
            value: e[t++]
          };
        },
        e: function(h) {
          throw h;
        },
        f: n
      };
    }
    throw new TypeError(`Invalid attempt to iterate non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
  }
  var l, o = !0, u = !1;
  return {
    s: function() {
      a = a.call(e);
    },
    n: function() {
      var h = a.next();
      return o = h.done, h;
    },
    e: function(h) {
      u = !0, l = h;
    },
    f: function() {
      try {
        o || a.return == null || a.return();
      } finally {
        if (u) throw l;
      }
    }
  };
}
function L(e) {
  if (e === void 0) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return e;
}
function Ga(e, r) {
  return Ga = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(a, t) {
    return a.__proto__ = t, a;
  }, Ga(e, r);
}
function ge(e, r) {
  if (typeof r != "function" && r !== null) throw new TypeError("Super expression must either be null or a function");
  e.prototype = Object.create(r && r.prototype, {
    constructor: {
      value: e,
      writable: !0,
      configurable: !0
    }
  }), Object.defineProperty(e, "prototype", {
    writable: !1
  }), r && Ga(e, r);
}
function ha(e) {
  return ha = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function(r) {
    return r.__proto__ || Object.getPrototypeOf(r);
  }, ha(e);
}
function zr() {
  try {
    var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
    }));
  } catch {
  }
  return (zr = function() {
    return !!e;
  })();
}
function Co(e, r) {
  if (r && (at(r) == "object" || typeof r == "function")) return r;
  if (r !== void 0) throw new TypeError("Derived constructors may only return object or undefined");
  return L(e);
}
function we(e) {
  var r = zr();
  return function() {
    var a, t = ha(e);
    if (r) {
      var n = ha(this).constructor;
      a = Reflect.construct(t, arguments, n);
    } else a = t.apply(this, arguments);
    return Co(this, a);
  };
}
function pe(e, r) {
  if (!(e instanceof r)) throw new TypeError("Cannot call a class as a function");
}
function Po(e, r) {
  if (at(e) != "object" || !e) return e;
  var a = e[Symbol.toPrimitive];
  if (a !== void 0) {
    var t = a.call(e, r || "default");
    if (at(t) != "object") return t;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(e);
}
function Gr(e) {
  var r = Po(e, "string");
  return at(r) == "symbol" ? r : r + "";
}
function Oo(e, r) {
  for (var a = 0; a < r.length; a++) {
    var t = r[a];
    t.enumerable = t.enumerable || !1, t.configurable = !0, "value" in t && (t.writable = !0), Object.defineProperty(e, Gr(t.key), t);
  }
}
function ye(e, r, a) {
  return r && Oo(e.prototype, r), Object.defineProperty(e, "prototype", {
    writable: !1
  }), e;
}
function W(e, r, a) {
  return (r = Gr(r)) in e ? Object.defineProperty(e, r, {
    value: a,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = a, e;
}
var $o = 10, Kr = /* @__PURE__ */ function() {
  function e() {
    pe(this, e), W(this, "priority", void 0), W(this, "subPriority", 0);
  }
  return ye(e, [{
    key: "validate",
    value: function(a, t) {
      return !0;
    }
  }]), e;
}(), No = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a(t, n, l, o, u) {
    var v;
    return pe(this, a), v = r.call(this), v.value = t, v.validateValue = n, v.setValue = l, v.priority = o, u && (v.subPriority = u), v;
  }
  return ye(a, [{
    key: "validate",
    value: function(n, l) {
      return this.validateValue(n, this.value, l);
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return this.setValue(n, l, this.value, o);
    }
  }]), a;
}(Kr), So = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", $o), W(L(t), "subPriority", -1), t;
  }
  return ye(a, [{
    key: "set",
    value: function(n, l) {
      if (l.timestampIsSet)
        return n;
      var o = /* @__PURE__ */ new Date(0);
      return o.setFullYear(n.getUTCFullYear(), n.getUTCMonth(), n.getUTCDate()), o.setHours(n.getUTCHours(), n.getUTCMinutes(), n.getUTCSeconds(), n.getUTCMilliseconds()), o;
    }
  }]), a;
}(Kr), ke = /* @__PURE__ */ function() {
  function e() {
    pe(this, e), W(this, "incompatibleTokens", void 0), W(this, "priority", void 0), W(this, "subPriority", void 0);
  }
  return ye(e, [{
    key: "run",
    value: function(a, t, n, l) {
      var o = this.parse(a, t, n, l);
      return o ? {
        setter: new No(o.value, this.validate, this.set, this.priority, this.subPriority),
        rest: o.rest
      } : null;
    }
  }, {
    key: "validate",
    value: function(a, t, n) {
      return !0;
    }
  }]), e;
}(), Ao = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 140), W(L(t), "incompatibleTokens", ["R", "u", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        // AD, BC
        case "G":
        case "GG":
        case "GGG":
          return o.era(n, {
            width: "abbreviated"
          }) || o.era(n, {
            width: "narrow"
          });
        // A, B
        case "GGGGG":
          return o.era(n, {
            width: "narrow"
          });
        // Anno Domini, Before Christ
        case "GGGG":
        default:
          return o.era(n, {
            width: "wide"
          }) || o.era(n, {
            width: "abbreviated"
          }) || o.era(n, {
            width: "narrow"
          });
      }
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return l.era = o, n.setUTCFullYear(o, 0, 1), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), Ie = {
  month: /^(1[0-2]|0?\d)/,
  // 0 to 12
  date: /^(3[0-1]|[0-2]?\d)/,
  // 0 to 31
  dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
  // 0 to 366
  week: /^(5[0-3]|[0-4]?\d)/,
  // 0 to 53
  hour23h: /^(2[0-3]|[0-1]?\d)/,
  // 0 to 23
  hour24h: /^(2[0-4]|[0-1]?\d)/,
  // 0 to 24
  hour11h: /^(1[0-1]|0?\d)/,
  // 0 to 11
  hour12h: /^(1[0-2]|0?\d)/,
  // 0 to 12
  minute: /^[0-5]?\d/,
  // 0 to 59
  second: /^[0-5]?\d/,
  // 0 to 59
  singleDigit: /^\d/,
  // 0 to 9
  twoDigits: /^\d{1,2}/,
  // 0 to 99
  threeDigits: /^\d{1,3}/,
  // 0 to 999
  fourDigits: /^\d{1,4}/,
  // 0 to 9999
  anyDigitsSigned: /^-?\d+/,
  singleDigitSigned: /^-?\d/,
  // 0 to 9, -0 to -9
  twoDigitsSigned: /^-?\d{1,2}/,
  // 0 to 99, -0 to -99
  threeDigitsSigned: /^-?\d{1,3}/,
  // 0 to 999, -0 to -999
  fourDigitsSigned: /^-?\d{1,4}/
  // 0 to 9999, -0 to -9999
}, it = {
  basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
  basic: /^([+-])(\d{2})(\d{2})|Z/,
  basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
  extended: /^([+-])(\d{2}):(\d{2})|Z/,
  extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
};
function Ue(e, r) {
  return e && {
    value: r(e.value),
    rest: e.rest
  };
}
function Se(e, r) {
  var a = r.match(e);
  return a ? {
    value: parseInt(a[0], 10),
    rest: r.slice(a[0].length)
  } : null;
}
function ut(e, r) {
  var a = r.match(e);
  if (!a)
    return null;
  if (a[0] === "Z")
    return {
      value: 0,
      rest: r.slice(1)
    };
  var t = a[1] === "+" ? 1 : -1, n = a[2] ? parseInt(a[2], 10) : 0, l = a[3] ? parseInt(a[3], 10) : 0, o = a[5] ? parseInt(a[5], 10) : 0;
  return {
    value: t * (n * tr + l * er + o * xn),
    rest: r.slice(a[0].length)
  };
}
function Zr(e) {
  return Se(Ie.anyDigitsSigned, e);
}
function Ae(e, r) {
  switch (e) {
    case 1:
      return Se(Ie.singleDigit, r);
    case 2:
      return Se(Ie.twoDigits, r);
    case 3:
      return Se(Ie.threeDigits, r);
    case 4:
      return Se(Ie.fourDigits, r);
    default:
      return Se(new RegExp("^\\d{1," + e + "}"), r);
  }
}
function ga(e, r) {
  switch (e) {
    case 1:
      return Se(Ie.singleDigitSigned, r);
    case 2:
      return Se(Ie.twoDigitsSigned, r);
    case 3:
      return Se(Ie.threeDigitsSigned, r);
    case 4:
      return Se(Ie.fourDigitsSigned, r);
    default:
      return Se(new RegExp("^-?\\d{1," + e + "}"), r);
  }
}
function or(e) {
  switch (e) {
    case "morning":
      return 4;
    case "evening":
      return 17;
    case "pm":
    case "noon":
    case "afternoon":
      return 12;
    case "am":
    case "midnight":
    case "night":
    default:
      return 0;
  }
}
function jr(e, r) {
  var a = r > 0, t = a ? r : 1 - r, n;
  if (t <= 50)
    n = e || 100;
  else {
    var l = t + 50, o = Math.floor(l / 100) * 100, u = e >= l % 100;
    n = e + o - (u ? 100 : 0);
  }
  return a ? n : 1 - n;
}
function Xr(e) {
  return e % 400 === 0 || e % 4 === 0 && e % 100 !== 0;
}
var Yo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 130), W(L(t), "incompatibleTokens", ["Y", "R", "u", "w", "I", "i", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      var u = function(h) {
        return {
          year: h,
          isTwoDigitYear: l === "yy"
        };
      };
      switch (l) {
        case "y":
          return Ue(Ae(4, n), u);
        case "yo":
          return Ue(o.ordinalNumber(n, {
            unit: "year"
          }), u);
        default:
          return Ue(Ae(l.length, n), u);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l.isTwoDigitYear || l.year > 0;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      var u = n.getUTCFullYear();
      if (o.isTwoDigitYear) {
        var v = jr(o.year, u);
        return n.setUTCFullYear(v, 0, 1), n.setUTCHours(0, 0, 0, 0), n;
      }
      var h = !("era" in l) || l.era === 1 ? o.year : 1 - o.year;
      return n.setUTCFullYear(h, 0, 1), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), Io = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 130), W(L(t), "incompatibleTokens", ["y", "R", "u", "Q", "q", "M", "L", "I", "d", "D", "i", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      var u = function(h) {
        return {
          year: h,
          isTwoDigitYear: l === "YY"
        };
      };
      switch (l) {
        case "Y":
          return Ue(Ae(4, n), u);
        case "Yo":
          return Ue(o.ordinalNumber(n, {
            unit: "year"
          }), u);
        default:
          return Ue(Ae(l.length, n), u);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l.isTwoDigitYear || l.year > 0;
    }
  }, {
    key: "set",
    value: function(n, l, o, u) {
      var v = ar(n, u);
      if (o.isTwoDigitYear) {
        var h = jr(o.year, v);
        return n.setUTCFullYear(h, 0, u.firstWeekContainsDate), n.setUTCHours(0, 0, 0, 0), Ut(n, u);
      }
      var _ = !("era" in l) || l.era === 1 ? o.year : 1 - o.year;
      return n.setUTCFullYear(_, 0, u.firstWeekContainsDate), n.setUTCHours(0, 0, 0, 0), Ut(n, u);
    }
  }]), a;
}(ke), Uo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 130), W(L(t), "incompatibleTokens", ["G", "y", "Y", "u", "Q", "q", "M", "L", "w", "d", "D", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l) {
      return ga(l === "R" ? 4 : l.length, n);
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      var u = /* @__PURE__ */ new Date(0);
      return u.setUTCFullYear(o, 0, 4), u.setUTCHours(0, 0, 0, 0), Lt(u);
    }
  }]), a;
}(ke), Ro = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 130), W(L(t), "incompatibleTokens", ["G", "y", "Y", "R", "w", "I", "i", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l) {
      return ga(l === "u" ? 4 : l.length, n);
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCFullYear(o, 0, 1), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), Eo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 120), W(L(t), "incompatibleTokens", ["Y", "R", "q", "M", "L", "w", "I", "d", "D", "i", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        // 1, 2, 3, 4
        case "Q":
        case "QQ":
          return Ae(l.length, n);
        // 1st, 2nd, 3rd, 4th
        case "Qo":
          return o.ordinalNumber(n, {
            unit: "quarter"
          });
        // Q1, Q2, Q3, Q4
        case "QQQ":
          return o.quarter(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.quarter(n, {
            width: "narrow",
            context: "formatting"
          });
        // 1, 2, 3, 4 (narrow quarter; could be not numerical)
        case "QQQQQ":
          return o.quarter(n, {
            width: "narrow",
            context: "formatting"
          });
        // 1st quarter, 2nd quarter, ...
        case "QQQQ":
        default:
          return o.quarter(n, {
            width: "wide",
            context: "formatting"
          }) || o.quarter(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.quarter(n, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 1 && l <= 4;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCMonth((o - 1) * 3, 1), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), Wo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 120), W(L(t), "incompatibleTokens", ["Y", "R", "Q", "M", "L", "w", "I", "d", "D", "i", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        // 1, 2, 3, 4
        case "q":
        case "qq":
          return Ae(l.length, n);
        // 1st, 2nd, 3rd, 4th
        case "qo":
          return o.ordinalNumber(n, {
            unit: "quarter"
          });
        // Q1, Q2, Q3, Q4
        case "qqq":
          return o.quarter(n, {
            width: "abbreviated",
            context: "standalone"
          }) || o.quarter(n, {
            width: "narrow",
            context: "standalone"
          });
        // 1, 2, 3, 4 (narrow quarter; could be not numerical)
        case "qqqqq":
          return o.quarter(n, {
            width: "narrow",
            context: "standalone"
          });
        // 1st quarter, 2nd quarter, ...
        case "qqqq":
        default:
          return o.quarter(n, {
            width: "wide",
            context: "standalone"
          }) || o.quarter(n, {
            width: "abbreviated",
            context: "standalone"
          }) || o.quarter(n, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 1 && l <= 4;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCMonth((o - 1) * 3, 1), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), Vo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "incompatibleTokens", ["Y", "R", "q", "Q", "L", "w", "I", "D", "i", "e", "c", "t", "T"]), W(L(t), "priority", 110), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      var u = function(h) {
        return h - 1;
      };
      switch (l) {
        // 1, 2, ..., 12
        case "M":
          return Ue(Se(Ie.month, n), u);
        // 01, 02, ..., 12
        case "MM":
          return Ue(Ae(2, n), u);
        // 1st, 2nd, ..., 12th
        case "Mo":
          return Ue(o.ordinalNumber(n, {
            unit: "month"
          }), u);
        // Jan, Feb, ..., Dec
        case "MMM":
          return o.month(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.month(n, {
            width: "narrow",
            context: "formatting"
          });
        // J, F, ..., D
        case "MMMMM":
          return o.month(n, {
            width: "narrow",
            context: "formatting"
          });
        // January, February, ..., December
        case "MMMM":
        default:
          return o.month(n, {
            width: "wide",
            context: "formatting"
          }) || o.month(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.month(n, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 11;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCMonth(o, 1), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), Bo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 110), W(L(t), "incompatibleTokens", ["Y", "R", "q", "Q", "M", "w", "I", "D", "i", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      var u = function(h) {
        return h - 1;
      };
      switch (l) {
        // 1, 2, ..., 12
        case "L":
          return Ue(Se(Ie.month, n), u);
        // 01, 02, ..., 12
        case "LL":
          return Ue(Ae(2, n), u);
        // 1st, 2nd, ..., 12th
        case "Lo":
          return Ue(o.ordinalNumber(n, {
            unit: "month"
          }), u);
        // Jan, Feb, ..., Dec
        case "LLL":
          return o.month(n, {
            width: "abbreviated",
            context: "standalone"
          }) || o.month(n, {
            width: "narrow",
            context: "standalone"
          });
        // J, F, ..., D
        case "LLLLL":
          return o.month(n, {
            width: "narrow",
            context: "standalone"
          });
        // January, February, ..., December
        case "LLLL":
        default:
          return o.month(n, {
            width: "wide",
            context: "standalone"
          }) || o.month(n, {
            width: "abbreviated",
            context: "standalone"
          }) || o.month(n, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 11;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCMonth(o, 1), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke);
function Lo(e, r, a) {
  G(2, arguments);
  var t = oe(e), n = ae(r), l = Br(t, a) - n;
  return t.setUTCDate(t.getUTCDate() - l * 7), t;
}
var Ho = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 100), W(L(t), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "i", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "w":
          return Se(Ie.week, n);
        case "wo":
          return o.ordinalNumber(n, {
            unit: "week"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 1 && l <= 53;
    }
  }, {
    key: "set",
    value: function(n, l, o, u) {
      return Ut(Lo(n, o, u), u);
    }
  }]), a;
}(ke);
function Fo(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r), n = Vr(a) - t;
  return a.setUTCDate(a.getUTCDate() - n * 7), a;
}
var qo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 100), W(L(t), "incompatibleTokens", ["y", "Y", "u", "q", "Q", "M", "L", "w", "d", "D", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "I":
          return Se(Ie.week, n);
        case "Io":
          return o.ordinalNumber(n, {
            unit: "week"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 1 && l <= 53;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return Lt(Fo(n, o));
    }
  }]), a;
}(ke), Qo = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], zo = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], Go = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 90), W(L(t), "subPriority", 1), W(L(t), "incompatibleTokens", ["Y", "R", "q", "Q", "w", "I", "D", "i", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "d":
          return Se(Ie.date, n);
        case "do":
          return o.ordinalNumber(n, {
            unit: "date"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      var o = n.getUTCFullYear(), u = Xr(o), v = n.getUTCMonth();
      return u ? l >= 1 && l <= zo[v] : l >= 1 && l <= Qo[v];
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCDate(o), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), Ko = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 90), W(L(t), "subpriority", 1), W(L(t), "incompatibleTokens", ["Y", "R", "q", "Q", "M", "L", "w", "I", "d", "E", "i", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "D":
        case "DD":
          return Se(Ie.dayOfYear, n);
        case "Do":
          return o.ordinalNumber(n, {
            unit: "date"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      var o = n.getUTCFullYear(), u = Xr(o);
      return u ? l >= 1 && l <= 366 : l >= 1 && l <= 365;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCMonth(0, o), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke);
function lr(e, r, a) {
  var t, n, l, o, u, v, h, _;
  G(2, arguments);
  var m = Dt(), s = ae((t = (n = (l = (o = a == null ? void 0 : a.weekStartsOn) !== null && o !== void 0 ? o : a == null || (u = a.locale) === null || u === void 0 || (v = u.options) === null || v === void 0 ? void 0 : v.weekStartsOn) !== null && l !== void 0 ? l : m.weekStartsOn) !== null && n !== void 0 ? n : (h = m.locale) === null || h === void 0 || (_ = h.options) === null || _ === void 0 ? void 0 : _.weekStartsOn) !== null && t !== void 0 ? t : 0);
  if (!(s >= 0 && s <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  var w = oe(e), I = ae(r), R = w.getUTCDay(), K = I % 7, X = (K + 7) % 7, B = (X < s ? 7 : 0) + I - R;
  return w.setUTCDate(w.getUTCDate() + B), w;
}
var Zo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 90), W(L(t), "incompatibleTokens", ["D", "i", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        // Tue
        case "E":
        case "EE":
        case "EEE":
          return o.day(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          });
        // T
        case "EEEEE":
          return o.day(n, {
            width: "narrow",
            context: "formatting"
          });
        // Tu
        case "EEEEEE":
          return o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          });
        // Tuesday
        case "EEEE":
        default:
          return o.day(n, {
            width: "wide",
            context: "formatting"
          }) || o.day(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 6;
    }
  }, {
    key: "set",
    value: function(n, l, o, u) {
      return n = lr(n, o, u), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), jo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 90), W(L(t), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "E", "i", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o, u) {
      var v = function(_) {
        var m = Math.floor((_ - 1) / 7) * 7;
        return (_ + u.weekStartsOn + 6) % 7 + m;
      };
      switch (l) {
        // 3
        case "e":
        case "ee":
          return Ue(Ae(l.length, n), v);
        // 3rd
        case "eo":
          return Ue(o.ordinalNumber(n, {
            unit: "day"
          }), v);
        // Tue
        case "eee":
          return o.day(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          });
        // T
        case "eeeee":
          return o.day(n, {
            width: "narrow",
            context: "formatting"
          });
        // Tu
        case "eeeeee":
          return o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          });
        // Tuesday
        case "eeee":
        default:
          return o.day(n, {
            width: "wide",
            context: "formatting"
          }) || o.day(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 6;
    }
  }, {
    key: "set",
    value: function(n, l, o, u) {
      return n = lr(n, o, u), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), Xo = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 90), W(L(t), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "E", "i", "e", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o, u) {
      var v = function(_) {
        var m = Math.floor((_ - 1) / 7) * 7;
        return (_ + u.weekStartsOn + 6) % 7 + m;
      };
      switch (l) {
        // 3
        case "c":
        case "cc":
          return Ue(Ae(l.length, n), v);
        // 3rd
        case "co":
          return Ue(o.ordinalNumber(n, {
            unit: "day"
          }), v);
        // Tue
        case "ccc":
          return o.day(n, {
            width: "abbreviated",
            context: "standalone"
          }) || o.day(n, {
            width: "short",
            context: "standalone"
          }) || o.day(n, {
            width: "narrow",
            context: "standalone"
          });
        // T
        case "ccccc":
          return o.day(n, {
            width: "narrow",
            context: "standalone"
          });
        // Tu
        case "cccccc":
          return o.day(n, {
            width: "short",
            context: "standalone"
          }) || o.day(n, {
            width: "narrow",
            context: "standalone"
          });
        // Tuesday
        case "cccc":
        default:
          return o.day(n, {
            width: "wide",
            context: "standalone"
          }) || o.day(n, {
            width: "abbreviated",
            context: "standalone"
          }) || o.day(n, {
            width: "short",
            context: "standalone"
          }) || o.day(n, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 6;
    }
  }, {
    key: "set",
    value: function(n, l, o, u) {
      return n = lr(n, o, u), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke);
function Jo(e, r) {
  G(2, arguments);
  var a = ae(r);
  a % 7 === 0 && (a = a - 7);
  var t = 1, n = oe(e), l = n.getUTCDay(), o = a % 7, u = (o + 7) % 7, v = (u < t ? 7 : 0) + a - l;
  return n.setUTCDate(n.getUTCDate() + v), n;
}
var el = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 90), W(L(t), "incompatibleTokens", ["y", "Y", "u", "q", "Q", "M", "L", "w", "d", "D", "E", "e", "c", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      var u = function(h) {
        return h === 0 ? 7 : h;
      };
      switch (l) {
        // 2
        case "i":
        case "ii":
          return Ae(l.length, n);
        // 2nd
        case "io":
          return o.ordinalNumber(n, {
            unit: "day"
          });
        // Tue
        case "iii":
          return Ue(o.day(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          }), u);
        // T
        case "iiiii":
          return Ue(o.day(n, {
            width: "narrow",
            context: "formatting"
          }), u);
        // Tu
        case "iiiiii":
          return Ue(o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          }), u);
        // Tuesday
        case "iiii":
        default:
          return Ue(o.day(n, {
            width: "wide",
            context: "formatting"
          }) || o.day(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.day(n, {
            width: "short",
            context: "formatting"
          }) || o.day(n, {
            width: "narrow",
            context: "formatting"
          }), u);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 1 && l <= 7;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n = Jo(n, o), n.setUTCHours(0, 0, 0, 0), n;
    }
  }]), a;
}(ke), tl = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 80), W(L(t), "incompatibleTokens", ["b", "B", "H", "k", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "a":
        case "aa":
        case "aaa":
          return o.dayPeriod(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
        case "aaaaa":
          return o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
        case "aaaa":
        default:
          return o.dayPeriod(n, {
            width: "wide",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCHours(or(o), 0, 0, 0), n;
    }
  }]), a;
}(ke), al = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 80), W(L(t), "incompatibleTokens", ["a", "B", "H", "k", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "b":
        case "bb":
        case "bbb":
          return o.dayPeriod(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
        case "bbbbb":
          return o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
        case "bbbb":
        default:
          return o.dayPeriod(n, {
            width: "wide",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCHours(or(o), 0, 0, 0), n;
    }
  }]), a;
}(ke), rl = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 80), W(L(t), "incompatibleTokens", ["a", "b", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "B":
        case "BB":
        case "BBB":
          return o.dayPeriod(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
        case "BBBBB":
          return o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
        case "BBBB":
        default:
          return o.dayPeriod(n, {
            width: "wide",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "abbreviated",
            context: "formatting"
          }) || o.dayPeriod(n, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCHours(or(o), 0, 0, 0), n;
    }
  }]), a;
}(ke), nl = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 70), W(L(t), "incompatibleTokens", ["H", "K", "k", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "h":
          return Se(Ie.hour12h, n);
        case "ho":
          return o.ordinalNumber(n, {
            unit: "hour"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 1 && l <= 12;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      var u = n.getUTCHours() >= 12;
      return u && o < 12 ? n.setUTCHours(o + 12, 0, 0, 0) : !u && o === 12 ? n.setUTCHours(0, 0, 0, 0) : n.setUTCHours(o, 0, 0, 0), n;
    }
  }]), a;
}(ke), ol = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 70), W(L(t), "incompatibleTokens", ["a", "b", "h", "K", "k", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "H":
          return Se(Ie.hour23h, n);
        case "Ho":
          return o.ordinalNumber(n, {
            unit: "hour"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 23;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCHours(o, 0, 0, 0), n;
    }
  }]), a;
}(ke), ll = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 70), W(L(t), "incompatibleTokens", ["h", "H", "k", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "K":
          return Se(Ie.hour11h, n);
        case "Ko":
          return o.ordinalNumber(n, {
            unit: "hour"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 11;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      var u = n.getUTCHours() >= 12;
      return u && o < 12 ? n.setUTCHours(o + 12, 0, 0, 0) : n.setUTCHours(o, 0, 0, 0), n;
    }
  }]), a;
}(ke), il = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 70), W(L(t), "incompatibleTokens", ["a", "b", "h", "H", "K", "t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "k":
          return Se(Ie.hour24h, n);
        case "ko":
          return o.ordinalNumber(n, {
            unit: "hour"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 1 && l <= 24;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      var u = o <= 24 ? o % 24 : o;
      return n.setUTCHours(u, 0, 0, 0), n;
    }
  }]), a;
}(ke), ul = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 60), W(L(t), "incompatibleTokens", ["t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "m":
          return Se(Ie.minute, n);
        case "mo":
          return o.ordinalNumber(n, {
            unit: "minute"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 59;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCMinutes(o, 0, 0), n;
    }
  }]), a;
}(ke), sl = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 50), W(L(t), "incompatibleTokens", ["t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l, o) {
      switch (l) {
        case "s":
          return Se(Ie.second, n);
        case "so":
          return o.ordinalNumber(n, {
            unit: "second"
          });
        default:
          return Ae(l.length, n);
      }
    }
  }, {
    key: "validate",
    value: function(n, l) {
      return l >= 0 && l <= 59;
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCSeconds(o, 0), n;
    }
  }]), a;
}(ke), dl = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 30), W(L(t), "incompatibleTokens", ["t", "T"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l) {
      var o = function(v) {
        return Math.floor(v * Math.pow(10, -l.length + 3));
      };
      return Ue(Ae(l.length, n), o);
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return n.setUTCMilliseconds(o), n;
    }
  }]), a;
}(ke), cl = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 10), W(L(t), "incompatibleTokens", ["t", "T", "x"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l) {
      switch (l) {
        case "X":
          return ut(it.basicOptionalMinutes, n);
        case "XX":
          return ut(it.basic, n);
        case "XXXX":
          return ut(it.basicOptionalSeconds, n);
        case "XXXXX":
          return ut(it.extendedOptionalSeconds, n);
        case "XXX":
        default:
          return ut(it.extended, n);
      }
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return l.timestampIsSet ? n : new Date(n.getTime() - o);
    }
  }]), a;
}(ke), vl = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 10), W(L(t), "incompatibleTokens", ["t", "T", "X"]), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n, l) {
      switch (l) {
        case "x":
          return ut(it.basicOptionalMinutes, n);
        case "xx":
          return ut(it.basic, n);
        case "xxxx":
          return ut(it.basicOptionalSeconds, n);
        case "xxxxx":
          return ut(it.extendedOptionalSeconds, n);
        case "xxx":
        default:
          return ut(it.extended, n);
      }
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return l.timestampIsSet ? n : new Date(n.getTime() - o);
    }
  }]), a;
}(ke), ml = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 40), W(L(t), "incompatibleTokens", "*"), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n) {
      return Zr(n);
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return [new Date(o * 1e3), {
        timestampIsSet: !0
      }];
    }
  }]), a;
}(ke), fl = /* @__PURE__ */ function(e) {
  ge(a, e);
  var r = we(a);
  function a() {
    var t;
    pe(this, a);
    for (var n = arguments.length, l = new Array(n), o = 0; o < n; o++)
      l[o] = arguments[o];
    return t = r.call.apply(r, [this].concat(l)), W(L(t), "priority", 20), W(L(t), "incompatibleTokens", "*"), t;
  }
  return ye(a, [{
    key: "parse",
    value: function(n) {
      return Zr(n);
    }
  }, {
    key: "set",
    value: function(n, l, o) {
      return [new Date(o), {
        timestampIsSet: !0
      }];
    }
  }]), a;
}(ke), pl = {
  G: new Ao(),
  y: new Yo(),
  Y: new Io(),
  R: new Uo(),
  u: new Ro(),
  Q: new Eo(),
  q: new Wo(),
  M: new Vo(),
  L: new Bo(),
  w: new Ho(),
  I: new qo(),
  d: new Go(),
  D: new Ko(),
  E: new Zo(),
  e: new jo(),
  c: new Xo(),
  i: new el(),
  a: new tl(),
  b: new al(),
  B: new rl(),
  h: new nl(),
  H: new ol(),
  K: new ll(),
  k: new il(),
  m: new ul(),
  s: new sl(),
  S: new dl(),
  X: new cl(),
  x: new vl(),
  t: new ml(),
  T: new fl()
}, yl = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, hl = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, gl = /^'([^]*?)'?$/, wl = /''/g, bl = /\S/, _l = /[a-zA-Z]/;
function Ka(e, r, a, t) {
  var n, l, o, u, v, h, _, m, s, w, I, R, K, X;
  G(3, arguments);
  var B = String(e), A = String(r), H = Dt(), te = (n = (l = void 0) !== null && l !== void 0 ? l : H.locale) !== null && n !== void 0 ? n : qr;
  if (!te.match)
    throw new RangeError("locale must contain match property");
  var le = ae((o = (u = (v = (h = void 0) !== null && h !== void 0 ? h : void 0) !== null && v !== void 0 ? v : H.firstWeekContainsDate) !== null && u !== void 0 ? u : (_ = H.locale) === null || _ === void 0 || (m = _.options) === null || m === void 0 ? void 0 : m.firstWeekContainsDate) !== null && o !== void 0 ? o : 1);
  if (!(le >= 1 && le <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var g = ae((s = (w = (I = (R = void 0) !== null && R !== void 0 ? R : void 0) !== null && I !== void 0 ? I : H.weekStartsOn) !== null && w !== void 0 ? w : (K = H.locale) === null || K === void 0 || (X = K.options) === null || X === void 0 ? void 0 : X.weekStartsOn) !== null && s !== void 0 ? s : 0);
  if (!(g >= 0 && g <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  if (A === "")
    return B === "" ? oe(a) : /* @__PURE__ */ new Date(NaN);
  var C = {
    firstWeekContainsDate: le,
    weekStartsOn: g,
    locale: te
  }, E = [new So()], Z = A.match(hl).map(function(y) {
    var d = y[0];
    if (d in za) {
      var M = za[d];
      return M(y, te.formatLong);
    }
    return y;
  }).join("").match(yl), Y = [], F = gr(Z), ie;
  try {
    var ue = function() {
      var d = ie.value;
      !(t != null && t.useAdditionalWeekYearTokens) && Fr(d) && ya(d, A, e), !(t != null && t.useAdditionalDayOfYearTokens) && Hr(d) && ya(d, A, e);
      var M = d[0], Te = pl[M];
      if (Te) {
        var Le = Te.incompatibleTokens;
        if (Array.isArray(Le)) {
          var Ye = Y.find(function(rt) {
            return Le.includes(rt.token) || rt.token === M;
          });
          if (Ye)
            throw new RangeError("The format string mustn't contain `".concat(Ye.fullToken, "` and `").concat(d, "` at the same time"));
        } else if (Te.incompatibleTokens === "*" && Y.length > 0)
          throw new RangeError("The format string mustn't contain `".concat(d, "` and any other token at the same time"));
        Y.push({
          token: M,
          fullToken: d
        });
        var Ze = Te.run(B, d, te.match, C);
        if (!Ze)
          return {
            v: /* @__PURE__ */ new Date(NaN)
          };
        E.push(Ze.setter), B = Ze.rest;
      } else {
        if (M.match(_l))
          throw new RangeError("Format string contains an unescaped latin alphabet character `" + M + "`");
        if (d === "''" ? d = "'" : M === "'" && (d = kl(d)), B.indexOf(d) === 0)
          B = B.slice(d.length);
        else
          return {
            v: /* @__PURE__ */ new Date(NaN)
          };
      }
    };
    for (F.s(); !(ie = F.n()).done; ) {
      var x = ue();
      if (at(x) === "object") return x.v;
    }
  } catch (y) {
    F.e(y);
  } finally {
    F.f();
  }
  if (B.length > 0 && bl.test(B))
    return /* @__PURE__ */ new Date(NaN);
  var P = E.map(function(y) {
    return y.priority;
  }).sort(function(y, d) {
    return d - y;
  }).filter(function(y, d, M) {
    return M.indexOf(y) === d;
  }).map(function(y) {
    return E.filter(function(d) {
      return d.priority === y;
    }).sort(function(d, M) {
      return M.subPriority - d.subPriority;
    });
  }).map(function(y) {
    return y[0];
  }), i = oe(a);
  if (isNaN(i.getTime()))
    return /* @__PURE__ */ new Date(NaN);
  var p = Er(i, pa(i)), T = {}, b = gr(P), N;
  try {
    for (b.s(); !(N = b.n()).done; ) {
      var j = N.value;
      if (!j.validate(p, C))
        return /* @__PURE__ */ new Date(NaN);
      var J = j.set(p, T, C);
      Array.isArray(J) ? (p = J[0], _o(T, J[1])) : p = J;
    }
  } catch (y) {
    b.e(y);
  } finally {
    b.f();
  }
  return p;
}
function kl(e) {
  return e.match(gl)[1].replace(wl, "'");
}
function Dl(e, r) {
  G(2, arguments);
  var a = ae(r);
  return gt(e, -a);
}
function Tl(e, r) {
  var a;
  G(1, arguments);
  var t = ae((a = void 0) !== null && a !== void 0 ? a : 2);
  if (t !== 2 && t !== 1 && t !== 0)
    throw new RangeError("additionalDigits must be 0, 1 or 2");
  if (!(typeof e == "string" || Object.prototype.toString.call(e) === "[object String]"))
    return /* @__PURE__ */ new Date(NaN);
  var n = Pl(e), l;
  if (n.date) {
    var o = Ol(n.date, t);
    l = $l(o.restDateString, o.year);
  }
  if (!l || isNaN(l.getTime()))
    return /* @__PURE__ */ new Date(NaN);
  var u = l.getTime(), v = 0, h;
  if (n.time && (v = Nl(n.time), isNaN(v)))
    return /* @__PURE__ */ new Date(NaN);
  if (n.timezone) {
    if (h = Sl(n.timezone), isNaN(h))
      return /* @__PURE__ */ new Date(NaN);
  } else {
    var _ = new Date(u + v), m = /* @__PURE__ */ new Date(0);
    return m.setFullYear(_.getUTCFullYear(), _.getUTCMonth(), _.getUTCDate()), m.setHours(_.getUTCHours(), _.getUTCMinutes(), _.getUTCSeconds(), _.getUTCMilliseconds()), m;
  }
  return new Date(u + v + h);
}
var ia = {
  dateTimeDelimiter: /[T ]/,
  timeZoneDelimiter: /[Z ]/i,
  timezone: /([Z+-].*)$/
}, xl = /^-?(?:(\d{3})|(\d{2})(?:-?(\d{2}))?|W(\d{2})(?:-?(\d{1}))?|)$/, Ml = /^(\d{2}(?:[.,]\d*)?)(?::?(\d{2}(?:[.,]\d*)?))?(?::?(\d{2}(?:[.,]\d*)?))?$/, Cl = /^([+-])(\d{2})(?::?(\d{2}))?$/;
function Pl(e) {
  var r = {}, a = e.split(ia.dateTimeDelimiter), t;
  if (a.length > 2)
    return r;
  if (/:/.test(a[0]) ? t = a[0] : (r.date = a[0], t = a[1], ia.timeZoneDelimiter.test(r.date) && (r.date = e.split(ia.timeZoneDelimiter)[0], t = e.substr(r.date.length, e.length))), t) {
    var n = ia.timezone.exec(t);
    n ? (r.time = t.replace(n[1], ""), r.timezone = n[1]) : r.time = t;
  }
  return r;
}
function Ol(e, r) {
  var a = new RegExp("^(?:(\\d{4}|[+-]\\d{" + (4 + r) + "})|(\\d{2}|[+-]\\d{" + (2 + r) + "})$)"), t = e.match(a);
  if (!t) return {
    year: NaN,
    restDateString: ""
  };
  var n = t[1] ? parseInt(t[1]) : null, l = t[2] ? parseInt(t[2]) : null;
  return {
    year: l === null ? n : l * 100,
    restDateString: e.slice((t[1] || t[2]).length)
  };
}
function $l(e, r) {
  if (r === null) return /* @__PURE__ */ new Date(NaN);
  var a = e.match(xl);
  if (!a) return /* @__PURE__ */ new Date(NaN);
  var t = !!a[4], n = jt(a[1]), l = jt(a[2]) - 1, o = jt(a[3]), u = jt(a[4]), v = jt(a[5]) - 1;
  if (t)
    return Rl(r, u, v) ? Al(r, u, v) : /* @__PURE__ */ new Date(NaN);
  var h = /* @__PURE__ */ new Date(0);
  return !Il(r, l, o) || !Ul(r, n) ? /* @__PURE__ */ new Date(NaN) : (h.setUTCFullYear(r, l, Math.max(n, o)), h);
}
function jt(e) {
  return e ? parseInt(e) : 1;
}
function Nl(e) {
  var r = e.match(Ml);
  if (!r) return NaN;
  var a = Ua(r[1]), t = Ua(r[2]), n = Ua(r[3]);
  return El(a, t, n) ? a * tr + t * er + n * 1e3 : NaN;
}
function Ua(e) {
  return e && parseFloat(e.replace(",", ".")) || 0;
}
function Sl(e) {
  if (e === "Z") return 0;
  var r = e.match(Cl);
  if (!r) return 0;
  var a = r[1] === "+" ? -1 : 1, t = parseInt(r[2]), n = r[3] && parseInt(r[3]) || 0;
  return Wl(t, n) ? a * (t * tr + n * er) : NaN;
}
function Al(e, r, a) {
  var t = /* @__PURE__ */ new Date(0);
  t.setUTCFullYear(e, 0, 4);
  var n = t.getUTCDay() || 7, l = (r - 1) * 7 + a + 1 - n;
  return t.setUTCDate(t.getUTCDate() + l), t;
}
var Yl = [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
function Jr(e) {
  return e % 400 === 0 || e % 4 === 0 && e % 100 !== 0;
}
function Il(e, r, a) {
  return r >= 0 && r <= 11 && a >= 1 && a <= (Yl[r] || (Jr(e) ? 29 : 28));
}
function Ul(e, r) {
  return r >= 1 && r <= (Jr(e) ? 366 : 365);
}
function Rl(e, r, a) {
  return r >= 1 && r <= 53 && a >= 0 && a <= 6;
}
function El(e, r, a) {
  return e === 24 ? r === 0 && a === 0 : a >= 0 && a < 60 && r >= 0 && r < 60 && e >= 0 && e < 25;
}
function Wl(e, r) {
  return r >= 0 && r <= 59;
}
function ea(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r), n = a.getFullYear(), l = a.getDate(), o = /* @__PURE__ */ new Date(0);
  o.setFullYear(n, t, 15), o.setHours(0, 0, 0, 0);
  var u = Do(o);
  return a.setMonth(t, Math.min(l, u)), a;
}
function ze(e, r) {
  if (G(2, arguments), at(r) !== "object" || r === null)
    throw new RangeError("values parameter must be an object");
  var a = oe(e);
  return isNaN(a.getTime()) ? /* @__PURE__ */ new Date(NaN) : (r.year != null && a.setFullYear(r.year), r.month != null && (a = ea(a, r.month)), r.date != null && a.setDate(ae(r.date)), r.hours != null && a.setHours(ae(r.hours)), r.minutes != null && a.setMinutes(ae(r.minutes)), r.seconds != null && a.setSeconds(ae(r.seconds)), r.milliseconds != null && a.setMilliseconds(ae(r.milliseconds)), a);
}
function en(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r);
  return a.setHours(t), a;
}
function ir(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r);
  return a.setMilliseconds(t), a;
}
function tn(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r);
  return a.setMinutes(t), a;
}
function an(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r);
  return a.setSeconds(t), a;
}
function At(e, r) {
  G(2, arguments);
  var a = oe(e), t = ae(r);
  return isNaN(a.getTime()) ? /* @__PURE__ */ new Date(NaN) : (a.setFullYear(t), a);
}
function ct(e, r) {
  G(2, arguments);
  var a = ae(r);
  return st(e, -a);
}
function Vl(e, r) {
  if (G(2, arguments), !r || at(r) !== "object") return /* @__PURE__ */ new Date(NaN);
  var a = r.years ? ae(r.years) : 0, t = r.months ? ae(r.months) : 0, n = r.weeks ? ae(r.weeks) : 0, l = r.days ? ae(r.days) : 0, o = r.hours ? ae(r.hours) : 0, u = r.minutes ? ae(r.minutes) : 0, v = r.seconds ? ae(r.seconds) : 0, h = ct(e, t + a * 12), _ = Dl(h, l + n * 7), m = u + o * 60, s = v + m * 60, w = s * 1e3, I = new Date(_.getTime() - w);
  return I;
}
function wa(e, r) {
  G(2, arguments);
  var a = ae(r);
  return Tn(e, -a);
}
function Bl(e, r) {
  G(2, arguments);
  var a = ae(r);
  return Ur(e, -a);
}
const Rt = (e, r) => {
  const a = e.__vccOpts || e;
  for (const [t, n] of r)
    a[t] = n;
  return a;
}, Ll = {}, Hl = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, Fl = /* @__PURE__ */ se("path", { d: "M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z" }, null, -1), ql = /* @__PURE__ */ se("path", { d: "M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z" }, null, -1), Ql = /* @__PURE__ */ se("path", { d: "M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z" }, null, -1), zl = /* @__PURE__ */ se("path", { d: "M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z" }, null, -1), Gl = [
  Fl,
  ql,
  Ql,
  zl
];
function Kl(e, r) {
  return k(), U("svg", Hl, Gl);
}
const Ca = /* @__PURE__ */ Rt(Ll, [["render", Kl]]), Zl = {}, jl = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, Xl = /* @__PURE__ */ se("path", { d: "M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z" }, null, -1), Jl = /* @__PURE__ */ se("path", { d: "M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z" }, null, -1), ei = [
  Xl,
  Jl
];
function ti(e, r) {
  return k(), U("svg", jl, ei);
}
const ai = /* @__PURE__ */ Rt(Zl, [["render", ti]]), ri = {}, ni = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, oi = /* @__PURE__ */ se("path", { d: "M20.943 23.057l-7.057-7.057c0 0 7.057-7.057 7.057-7.057 0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-8 8c-0.521 0.521-0.521 1.365 0 1.885l8 8c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z" }, null, -1), li = [
  oi
];
function ii(e, r) {
  return k(), U("svg", ni, li);
}
const wr = /* @__PURE__ */ Rt(ri, [["render", ii]]), ui = {}, si = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, di = /* @__PURE__ */ se("path", { d: "M12.943 24.943l8-8c0.521-0.521 0.521-1.365 0-1.885l-8-8c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885l7.057 7.057c0 0-7.057 7.057-7.057 7.057-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0z" }, null, -1), ci = [
  di
];
function vi(e, r) {
  return k(), U("svg", si, ci);
}
const br = /* @__PURE__ */ Rt(ui, [["render", vi]]), mi = {}, fi = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, pi = /* @__PURE__ */ se("path", { d: "M16 1.333c-8.095 0-14.667 6.572-14.667 14.667s6.572 14.667 14.667 14.667c8.095 0 14.667-6.572 14.667-14.667s-6.572-14.667-14.667-14.667zM16 4c6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.623 0-12-5.377-12-12s5.377-12 12-12z" }, null, -1), yi = /* @__PURE__ */ se("path", { d: "M14.667 8v8c0 0.505 0.285 0.967 0.737 1.193l5.333 2.667c0.658 0.329 1.46 0.062 1.789-0.596s0.062-1.46-0.596-1.789l-4.596-2.298c0 0 0-7.176 0-7.176 0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z" }, null, -1), hi = [
  pi,
  yi
];
function gi(e, r) {
  return k(), U("svg", fi, hi);
}
const rn = /* @__PURE__ */ Rt(mi, [["render", gi]]), wi = {}, bi = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, _i = /* @__PURE__ */ se("path", { d: "M24.943 19.057l-8-8c-0.521-0.521-1.365-0.521-1.885 0l-8 8c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l7.057-7.057c0 0 7.057 7.057 7.057 7.057 0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z" }, null, -1), ki = [
  _i
];
function Di(e, r) {
  return k(), U("svg", bi, ki);
}
const nn = /* @__PURE__ */ Rt(wi, [["render", Di]]), Ti = {}, xi = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, Mi = /* @__PURE__ */ se("path", { d: "M7.057 12.943l8 8c0.521 0.521 1.365 0.521 1.885 0l8-8c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-7.057 7.057c0 0-7.057-7.057-7.057-7.057-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z" }, null, -1), Ci = [
  Mi
];
function Pi(e, r) {
  return k(), U("svg", xi, Ci);
}
const on = /* @__PURE__ */ Rt(Ti, [["render", Pi]]), _r = (e, r) => {
  const a = Ka(e, r.slice(0, e.length), /* @__PURE__ */ new Date());
  return Jt(a) && Rr(a) ? a : null;
}, Oi = (e, r) => {
  if (typeof r == "string")
    return _r(e, r);
  if (Array.isArray(r)) {
    let a = null;
    for (const t of r)
      if (a = _r(e, t), a)
        break;
    return a;
  }
  return typeof r == "function" ? r(e) : null;
}, $ = (e) => e ? new Date(e) : /* @__PURE__ */ new Date(), $i = (e, r) => {
  if (r) {
    const t = (e.getMonth() + 1).toString().padStart(2, "0"), n = e.getDate().toString().padStart(2, "0"), l = e.getHours().toString().padStart(2, "0"), o = e.getMinutes().toString().padStart(2, "0");
    return `${e.getFullYear()}-${t}-${n}T${l}:${o}:00.000Z`;
  }
  const a = Date.UTC(
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate(),
    e.getUTCHours(),
    e.getUTCMinutes(),
    e.getUTCSeconds()
  );
  return new Date(a).toISOString();
}, vt = (e) => {
  let r = $(JSON.parse(JSON.stringify(e)));
  return r = en(r, 0), r = tn(r, 0), r = an(r, 0), r = ir(r, 0), r;
}, tt = (e, r, a, t) => {
  let n = e ? $(e) : $();
  return (r || r === 0) && (n = en(n, +r)), (a || a === 0) && (n = tn(n, +a)), (t || t === 0) && (n = an(n, +t)), ir(n, 0);
}, Fe = (e, r) => !e || !r ? !1 : nr(vt(e), vt(r)), Ne = (e, r) => !e || !r ? !1 : Qr(vt(e), vt(r)), Ke = (e, r) => !e || !r ? !1 : rr(vt(e), vt(r)), ln = (e, r, a) => e && e[0] && e[1] ? Ke(a, e[0]) && Fe(a, e[1]) : e && e[0] && r ? Ke(a, e[0]) && Fe(a, r) || Fe(a, e[0]) && Ke(a, r) : !1, Xt = aa({
  menuFocused: !1,
  shiftKeyInMenu: !1
}), un = () => {
  const e = (a) => {
    Xt.menuFocused = a;
  }, r = (a) => {
    Xt.shiftKeyInMenu !== a && (Xt.shiftKeyInMenu = a);
  };
  return {
    control: V(() => ({ shiftKeyInMenu: Xt.shiftKeyInMenu, menuFocused: Xt.menuFocused })),
    setMenuFocused: e,
    setShiftKey: r
  };
};
function ur(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var ba = {}, Ni = {
  get exports() {
    return ba;
  },
  set exports(e) {
    ba = e;
  }
};
(function(e, r) {
  Object.defineProperty(r, "__esModule", {
    value: !0
  }), r.default = a;
  function a(t) {
    if (t === null || t === !0 || t === !1)
      return NaN;
    var n = Number(t);
    return isNaN(n) ? n : n < 0 ? Math.ceil(n) : Math.floor(n);
  }
  e.exports = r.default;
})(Ni, ba);
const Si = /* @__PURE__ */ ur(ba);
var _a = {}, Ai = {
  get exports() {
    return _a;
  },
  set exports(e) {
    _a = e;
  }
};
(function(e, r) {
  Object.defineProperty(r, "__esModule", {
    value: !0
  }), r.default = a;
  function a(t) {
    var n = new Date(Date.UTC(t.getFullYear(), t.getMonth(), t.getDate(), t.getHours(), t.getMinutes(), t.getSeconds(), t.getMilliseconds()));
    return n.setUTCFullYear(t.getFullYear()), t.getTime() - n.getTime();
  }
  e.exports = r.default;
})(Ai, _a);
const kr = /* @__PURE__ */ ur(_a);
function Yi(e, r) {
  var a = Ei(r);
  return a.formatToParts ? Ui(a, e) : Ri(a, e);
}
var Ii = {
  year: 0,
  month: 1,
  day: 2,
  hour: 3,
  minute: 4,
  second: 5
};
function Ui(e, r) {
  try {
    for (var a = e.formatToParts(r), t = [], n = 0; n < a.length; n++) {
      var l = Ii[a[n].type];
      l >= 0 && (t[l] = parseInt(a[n].value, 10));
    }
    return t;
  } catch (o) {
    if (o instanceof RangeError)
      return [NaN];
    throw o;
  }
}
function Ri(e, r) {
  var a = e.format(r).replace(/\u200E/g, ""), t = /(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)/.exec(a);
  return [t[3], t[1], t[2], t[4], t[5], t[6]];
}
var Ra = {};
function Ei(e) {
  if (!Ra[e]) {
    var r = new Intl.DateTimeFormat("en-US", {
      hour12: !1,
      timeZone: "America/New_York",
      year: "numeric",
      month: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    }).format(/* @__PURE__ */ new Date("2014-06-25T04:00:00.123Z")), a = r === "06/25/2014, 00:00:00" || r === "‎06‎/‎25‎/‎2014‎ ‎00‎:‎00‎:‎00";
    Ra[e] = a ? new Intl.DateTimeFormat("en-US", {
      hour12: !1,
      timeZone: e,
      year: "numeric",
      month: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    }) : new Intl.DateTimeFormat("en-US", {
      hourCycle: "h23",
      timeZone: e,
      year: "numeric",
      month: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
  }
  return Ra[e];
}
function sr(e, r, a, t, n, l, o) {
  var u = /* @__PURE__ */ new Date(0);
  return u.setUTCFullYear(e, r, a), u.setUTCHours(t, n, l, o), u;
}
var Dr = 36e5, Wi = 6e4, Ea = {
  timezone: /([Z+-].*)$/,
  timezoneZ: /^(Z)$/,
  timezoneHH: /^([+-]\d{2})$/,
  timezoneHHMM: /^([+-]\d{2}):?(\d{2})$/
};
function dr(e, r, a) {
  var t, n;
  if (!e || (t = Ea.timezoneZ.exec(e), t))
    return 0;
  var l;
  if (t = Ea.timezoneHH.exec(e), t)
    return l = parseInt(t[1], 10), Tr(l) ? -(l * Dr) : NaN;
  if (t = Ea.timezoneHHMM.exec(e), t) {
    l = parseInt(t[1], 10);
    var o = parseInt(t[2], 10);
    return Tr(l, o) ? (n = Math.abs(l) * Dr + o * Wi, l > 0 ? -n : n) : NaN;
  }
  if (Li(e)) {
    r = new Date(r || Date.now());
    var u = a ? r : Vi(r), v = Za(u, e), h = a ? v : Bi(r, v, e);
    return -h;
  }
  return NaN;
}
function Vi(e) {
  return sr(
    e.getFullYear(),
    e.getMonth(),
    e.getDate(),
    e.getHours(),
    e.getMinutes(),
    e.getSeconds(),
    e.getMilliseconds()
  );
}
function Za(e, r) {
  var a = Yi(e, r), t = sr(
    a[0],
    a[1] - 1,
    a[2],
    a[3] % 24,
    a[4],
    a[5],
    0
  ).getTime(), n = e.getTime(), l = n % 1e3;
  return n -= l >= 0 ? l : 1e3 + l, t - n;
}
function Bi(e, r, a) {
  var t = e.getTime(), n = t - r, l = Za(new Date(n), a);
  if (r === l)
    return r;
  n -= l - r;
  var o = Za(new Date(n), a);
  return l === o ? l : Math.max(l, o);
}
function Tr(e, r) {
  return -23 <= e && e <= 23 && (r == null || 0 <= r && r <= 59);
}
var xr = {};
function Li(e) {
  if (xr[e])
    return !0;
  try {
    return new Intl.DateTimeFormat(void 0, { timeZone: e }), xr[e] = !0, !0;
  } catch {
    return !1;
  }
}
var Hi = /(Z|[+-]\d{2}(?::?\d{2})?| UTC| [a-zA-Z]+\/[a-zA-Z_]+(?:\/[a-zA-Z_]+)?)$/;
const sn = Hi;
var Wa = 36e5, Mr = 6e4, Fi = 2, Ge = {
  dateTimePattern: /^([0-9W+-]+)(T| )(.*)/,
  datePattern: /^([0-9W+-]+)(.*)/,
  plainTime: /:/,
  // year tokens
  YY: /^(\d{2})$/,
  YYY: [
    /^([+-]\d{2})$/,
    // 0 additional digits
    /^([+-]\d{3})$/,
    // 1 additional digit
    /^([+-]\d{4})$/
    // 2 additional digits
  ],
  YYYY: /^(\d{4})/,
  YYYYY: [
    /^([+-]\d{4})/,
    // 0 additional digits
    /^([+-]\d{5})/,
    // 1 additional digit
    /^([+-]\d{6})/
    // 2 additional digits
  ],
  // date tokens
  MM: /^-(\d{2})$/,
  DDD: /^-?(\d{3})$/,
  MMDD: /^-?(\d{2})-?(\d{2})$/,
  Www: /^-?W(\d{2})$/,
  WwwD: /^-?W(\d{2})-?(\d{1})$/,
  HH: /^(\d{2}([.,]\d*)?)$/,
  HHMM: /^(\d{2}):?(\d{2}([.,]\d*)?)$/,
  HHMMSS: /^(\d{2}):?(\d{2}):?(\d{2}([.,]\d*)?)$/,
  // time zone tokens (to identify the presence of a tz)
  timeZone: sn
};
function ja(e, r) {
  if (arguments.length < 1)
    throw new TypeError("1 argument required, but only " + arguments.length + " present");
  if (e === null)
    return /* @__PURE__ */ new Date(NaN);
  var a = r || {}, t = a.additionalDigits == null ? Fi : Si(a.additionalDigits);
  if (t !== 2 && t !== 1 && t !== 0)
    throw new RangeError("additionalDigits must be 0, 1 or 2");
  if (e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]")
    return new Date(e.getTime());
  if (typeof e == "number" || Object.prototype.toString.call(e) === "[object Number]")
    return new Date(e);
  if (!(typeof e == "string" || Object.prototype.toString.call(e) === "[object String]"))
    return /* @__PURE__ */ new Date(NaN);
  var n = qi(e), l = Qi(n.date, t), o = l.year, u = l.restDateString, v = zi(u, o);
  if (isNaN(v))
    return /* @__PURE__ */ new Date(NaN);
  if (v) {
    var h = v.getTime(), _ = 0, m;
    if (n.time && (_ = Gi(n.time), isNaN(_)))
      return /* @__PURE__ */ new Date(NaN);
    if (n.timeZone || a.timeZone) {
      if (m = dr(n.timeZone || a.timeZone, new Date(h + _)), isNaN(m))
        return /* @__PURE__ */ new Date(NaN);
    } else
      m = kr(new Date(h + _)), m = kr(new Date(h + _ + m));
    return new Date(h + _ + m);
  } else
    return /* @__PURE__ */ new Date(NaN);
}
function qi(e) {
  var r = {}, a = Ge.dateTimePattern.exec(e), t;
  if (a ? (r.date = a[1], t = a[3]) : (a = Ge.datePattern.exec(e), a ? (r.date = a[1], t = a[2]) : (r.date = null, t = e)), t) {
    var n = Ge.timeZone.exec(t);
    n ? (r.time = t.replace(n[1], ""), r.timeZone = n[1].trim()) : r.time = t;
  }
  return r;
}
function Qi(e, r) {
  var a = Ge.YYY[r], t = Ge.YYYYY[r], n;
  if (n = Ge.YYYY.exec(e) || t.exec(e), n) {
    var l = n[1];
    return {
      year: parseInt(l, 10),
      restDateString: e.slice(l.length)
    };
  }
  if (n = Ge.YY.exec(e) || a.exec(e), n) {
    var o = n[1];
    return {
      year: parseInt(o, 10) * 100,
      restDateString: e.slice(o.length)
    };
  }
  return {
    year: null
  };
}
function zi(e, r) {
  if (r === null)
    return null;
  var a, t, n, l;
  if (e.length === 0)
    return t = /* @__PURE__ */ new Date(0), t.setUTCFullYear(r), t;
  if (a = Ge.MM.exec(e), a)
    return t = /* @__PURE__ */ new Date(0), n = parseInt(a[1], 10) - 1, Pr(r, n) ? (t.setUTCFullYear(r, n), t) : /* @__PURE__ */ new Date(NaN);
  if (a = Ge.DDD.exec(e), a) {
    t = /* @__PURE__ */ new Date(0);
    var o = parseInt(a[1], 10);
    return ji(r, o) ? (t.setUTCFullYear(r, 0, o), t) : /* @__PURE__ */ new Date(NaN);
  }
  if (a = Ge.MMDD.exec(e), a) {
    t = /* @__PURE__ */ new Date(0), n = parseInt(a[1], 10) - 1;
    var u = parseInt(a[2], 10);
    return Pr(r, n, u) ? (t.setUTCFullYear(r, n, u), t) : /* @__PURE__ */ new Date(NaN);
  }
  if (a = Ge.Www.exec(e), a)
    return l = parseInt(a[1], 10) - 1, Or(r, l) ? Cr(r, l) : /* @__PURE__ */ new Date(NaN);
  if (a = Ge.WwwD.exec(e), a) {
    l = parseInt(a[1], 10) - 1;
    var v = parseInt(a[2], 10) - 1;
    return Or(r, l, v) ? Cr(r, l, v) : /* @__PURE__ */ new Date(NaN);
  }
  return null;
}
function Gi(e) {
  var r, a, t;
  if (r = Ge.HH.exec(e), r)
    return a = parseFloat(r[1].replace(",", ".")), Va(a) ? a % 24 * Wa : NaN;
  if (r = Ge.HHMM.exec(e), r)
    return a = parseInt(r[1], 10), t = parseFloat(r[2].replace(",", ".")), Va(a, t) ? a % 24 * Wa + t * Mr : NaN;
  if (r = Ge.HHMMSS.exec(e), r) {
    a = parseInt(r[1], 10), t = parseInt(r[2], 10);
    var n = parseFloat(r[3].replace(",", "."));
    return Va(a, t, n) ? a % 24 * Wa + t * Mr + n * 1e3 : NaN;
  }
  return null;
}
function Cr(e, r, a) {
  r = r || 0, a = a || 0;
  var t = /* @__PURE__ */ new Date(0);
  t.setUTCFullYear(e, 0, 4);
  var n = t.getUTCDay() || 7, l = r * 7 + a + 1 - n;
  return t.setUTCDate(t.getUTCDate() + l), t;
}
var Ki = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], Zi = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
function dn(e) {
  return e % 400 === 0 || e % 4 === 0 && e % 100 !== 0;
}
function Pr(e, r, a) {
  if (r < 0 || r > 11)
    return !1;
  if (a != null) {
    if (a < 1)
      return !1;
    var t = dn(e);
    if (t && a > Zi[r] || !t && a > Ki[r])
      return !1;
  }
  return !0;
}
function ji(e, r) {
  if (r < 1)
    return !1;
  var a = dn(e);
  return !(a && r > 366 || !a && r > 365);
}
function Or(e, r, a) {
  return !(r < 0 || r > 52 || a != null && (a < 0 || a > 6));
}
function Va(e, r, a) {
  return !(e != null && (e < 0 || e >= 25) || r != null && (r < 0 || r >= 60) || a != null && (a < 0 || a >= 60));
}
var ka = {}, Xi = {
  get exports() {
    return ka;
  },
  set exports(e) {
    ka = e;
  }
}, Da = {}, Ji = {
  get exports() {
    return Da;
  },
  set exports(e) {
    Da = e;
  }
};
(function(e, r) {
  Object.defineProperty(r, "__esModule", {
    value: !0
  }), r.default = a;
  function a(t, n) {
    if (t == null)
      throw new TypeError("assign requires that input parameter not be null or undefined");
    for (var l in n)
      Object.prototype.hasOwnProperty.call(n, l) && (t[l] = n[l]);
    return t;
  }
  e.exports = r.default;
})(Ji, Da);
(function(e, r) {
  Object.defineProperty(r, "__esModule", {
    value: !0
  }), r.default = n;
  var a = t(Da);
  function t(l) {
    return l && l.__esModule ? l : { default: l };
  }
  function n(l) {
    return (0, a.default)({}, l);
  }
  e.exports = r.default;
})(Xi, ka);
const eu = /* @__PURE__ */ ur(ka);
function tu(e, r, a) {
  var t = ja(e, a), n = dr(r, t, !0), l = new Date(t.getTime() - n), o = /* @__PURE__ */ new Date(0);
  return o.setFullYear(l.getUTCFullYear(), l.getUTCMonth(), l.getUTCDate()), o.setHours(l.getUTCHours(), l.getUTCMinutes(), l.getUTCSeconds(), l.getUTCMilliseconds()), o;
}
function au(e, r, a) {
  if (typeof e == "string" && !e.match(sn)) {
    var t = eu(a);
    return t.timeZone = r, ja(e, t);
  }
  var n = ja(e, a), l = sr(
    n.getFullYear(),
    n.getMonth(),
    n.getDate(),
    n.getHours(),
    n.getMinutes(),
    n.getSeconds(),
    n.getMilliseconds()
  ).getTime(), o = dr(r, new Date(l));
  return new Date(l + o);
}
const ru = (e, r = 3) => {
  const a = [];
  for (let t = 0; t < e.length; t += r)
    a.push([e[t], e[t + 1], e[t + 2]]);
  return a;
}, nu = (e, r) => {
  const a = [1, 2, 3, 4, 5, 6, 7].map((l) => new Intl.DateTimeFormat(e, { weekday: "short", timeZone: "UTC" }).format(/* @__PURE__ */ new Date(`2017-01-0${l}T00:00:00+00:00`)).slice(0, 2)), t = a.slice(0, r), n = a.slice(r + 1, a.length);
  return [a[r]].concat(...n).concat(...t);
}, ou = (e) => {
  const r = [];
  for (let a = +e[0]; a <= +e[1]; a++)
    r.push({ value: +a, text: `${a}` });
  return r;
}, lu = (e, r) => {
  const a = new Intl.DateTimeFormat(e, { month: r, timeZone: "UTC" });
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((t) => {
    const n = t < 10 ? `0${t}` : t;
    return /* @__PURE__ */ new Date(`2017-${n}-01T00:00:00+00:00`);
  }).map((t, n) => ({
    text: a.format(t),
    value: n
  }));
}, iu = (e) => [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11][e], Ee = (e) => {
  const r = f(e);
  return r != null && r.$el ? r == null ? void 0 : r.$el : r;
}, uu = (e) => Object.assign({ type: "dot" }, e), cn = (e) => Array.isArray(e) ? !!e[0] && !!e[1] : !1, Ta = {
  prop: (e) => `"${e}" prop must be enabled!`,
  dateArr: (e) => `You need to use array as "model-value" binding in order to support "${e}"`
}, He = (e) => e, $r = (e) => e === 0 ? e : !e || isNaN(+e) ? null : +e, Nr = (e) => Object.assign(
  {
    menuAppear: "dp-menu-appear",
    open: "dp-slide-down",
    close: "dp-slide-up",
    next: "calendar-next",
    previous: "calendar-prev",
    vNext: "dp-slide-up",
    vPrevious: "dp-slide-down"
  },
  e
), su = (e) => Object.assign(
  {
    toggleOverlay: "Toggle overlay",
    menu: "Datepicker menu",
    input: "Datepicker input",
    calendarWrap: "Calendar wrapper",
    calendarDays: "Calendar days",
    openTimePicker: "Open time picker",
    closeTimePicker: "Close time Picker",
    incrementValue: (r) => `Increment ${r}`,
    decrementValue: (r) => `Decrement ${r}`,
    openTpOverlay: (r) => `Open ${r} overlay`,
    amPmButton: "Switch AM/PM mode",
    openYearsOverlay: "Open years overlay",
    openMonthsOverlay: "Open months overlay",
    nextMonth: "Next month",
    prevMonth: "Previous month",
    day: () => ""
  },
  e
), du = (e) => e === null ? 0 : typeof e == "boolean" ? e ? 2 : 0 : +e >= 2 ? +e : 2, cu = (e, r, a) => e || (typeof a == "string" ? a : r), vu = (e) => typeof e == "boolean" ? e ? Nr({}) : !1 : Nr(e), mu = () => ({
  enterSubmit: !0,
  tabSubmit: !0,
  openMenu: !0,
  rangeSeparator: " - "
}), fu = (e) => Object.assign({ months: [], years: [], times: { hours: [], minutes: [], seconds: [] } }, e), et = (e) => {
  const r = () => {
    if (e.partialRange)
      return null;
    throw new Error(Ta.prop("partial-range"));
  }, a = V(() => ({
    ariaLabels: su(e.ariaLabels),
    textInputOptions: Object.assign(mu(), e.textInputOptions),
    multiCalendars: du(e.multiCalendars),
    previewFormat: cu(e.previewFormat, e.format, l()),
    filters: fu(e.filters),
    transitions: vu(e.transitions),
    startTime: s()
  })), t = (i) => {
    if (e.range)
      return i();
    throw new Error(Ta.prop("range"));
  }, n = () => {
    const i = e.enableSeconds ? ":ss" : "";
    return e.is24 ? `HH:mm${i}` : `hh:mm${i} aa`;
  }, l = () => e.format ? e.format : e.monthPicker ? "MM/yyyy" : e.timePicker ? n() : e.weekPicker ? "MM/dd/yyyy" : e.yearPicker ? "yyyy" : e.enableTimePicker ? `MM/dd/yyyy, ${n()}` : "MM/dd/yyyy", o = (i, p) => {
    if (typeof e.format == "function")
      return e.format(i);
    const T = p || l(), b = e.formatLocale ? { locale: e.formatLocale } : void 0;
    return Array.isArray(i) ? `${_t(i[0], T, b)} ${e.modelAuto && !i[1] ? "" : a.value.textInputOptions.rangeSeparator || "-"} ${i[1] ? _t(i[1], T, b) : ""}` : _t(i, T, b);
  }, u = (i) => e.timezone ? tu(i, e.timezone) : i, v = (i) => e.timezone ? au(i, e.timezone) : i, h = V(() => (i) => {
    var p;
    return (p = e.hideNavigation) == null ? void 0 : p.includes(i);
  }), _ = (i) => {
    const p = e.maxDate ? Ke(u(i), u($(e.maxDate))) : !1, T = e.minDate ? Fe(u(i), u($(e.minDate))) : !1, b = X(i, e.disabledDates), N = a.value.filters.months.map((M) => +M).includes(Oe(i)), j = e.disabledWeekDays.length ? e.disabledWeekDays.some((M) => +M === ko(i)) : !1, J = e.allowedDates.length ? !e.allowedDates.some((M) => Ne(u($(M)), u(i))) : !1, y = $e(i), d = y < +e.yearRange[0] || y > +e.yearRange[1];
    return !(p || T || b || N || d || j || J);
  }, m = (i) => {
    const p = {
      hours: ft($()),
      minutes: pt($()),
      seconds: Ht($())
    };
    return Object.assign(p, i);
  }, s = () => e.range ? e.startTime && Array.isArray(e.startTime) ? [m(e.startTime[0]), m(e.startTime[1])] : null : e.startTime && !Array.isArray(e.startTime) ? m(e.startTime) : null, w = (i) => !_(i), I = (i) => Array.isArray(i) ? Jt(i[0]) && (i[1] ? Jt(i[1]) : !0) : i ? Jt(i) : !1, R = (i) => i instanceof Date ? i : Tl(i), K = (i) => {
    const p = It(u(i), { weekStartsOn: +e.weekStart }), T = ra(u(i), { weekStartsOn: +e.weekStart });
    return [p, T];
  }, X = (i, p) => Array.isArray(p) ? p.some((T) => Ne(u($(T)), u(i))) : p(i), B = (i, p, T) => {
    let b = i ? $(i) : $();
    return (p || p === 0) && (b = ea(b, p)), T && (b = At(b, T)), b;
  }, A = (i) => ze($(), { hours: ft(i), minutes: pt(i), seconds: Ht(i) }), H = (i) => ze($(), {
    hours: +i.hours || 0,
    minutes: +i.minutes || 0,
    seconds: +i.seconds || 0
  }), te = (i, p, T, b) => {
    if (!i)
      return !0;
    if (b) {
      const N = T === "max" ? nr(i, p) : rr(i, p), j = { seconds: 0, milliseconds: 0 };
      return N || Qr(ze(i, j), ze(p, j));
    }
    return T === "max" ? i.getTime() <= p.getTime() : i.getTime() >= p.getTime();
  }, le = () => !e.enableTimePicker || e.monthPicker || e.yearPicker || e.ignoreTimeValidation, g = (i) => Array.isArray(i) ? [i[0] ? A(i[0]) : null, i[1] ? A(i[1]) : null] : A(i), C = (i) => {
    const p = e.maxTime ? H(e.maxTime) : $(e.maxDate);
    return Array.isArray(i) ? te(i[0], p, "max", !!e.maxDate) && te(i[1], p, "max", !!e.maxDate) : te(i, p, "max", !!e.maxDate);
  }, E = (i, p) => {
    const T = e.minTime ? H(e.minTime) : $(e.minDate);
    return Array.isArray(i) ? te(i[0], T, "min", !!e.minDate) && te(i[1], T, "min", !!e.minDate) && p : te(i, T, "min", !!e.minDate) && p;
  }, Z = (i) => {
    let p = !0;
    if (!i || le())
      return !0;
    const T = !e.minDate && !e.maxDate ? g(i) : i;
    return (e.maxTime || e.maxDate) && (p = C(He(T))), (e.minTime || e.minDate) && (p = E(He(T), p)), p;
  }, Y = (i, p) => {
    const T = $(JSON.parse(JSON.stringify(i))), b = [];
    for (let N = 0; N < 7; N++) {
      const j = gt(T, N), J = Oe(j) !== p;
      b.push({
        text: e.hideOffsetDates && J ? "" : j.getDate(),
        value: j,
        current: !J,
        classData: {}
      });
    }
    return b;
  }, F = (i, p) => {
    const T = [], b = $(u(new Date(p, i))), N = $(u(new Date(p, i + 1, 0))), j = It(b, { weekStartsOn: e.weekStart }), J = (y) => {
      const d = Y(y, i);
      if (T.push({ days: d }), !T[T.length - 1].days.some(
        (M) => Ne(vt(M.value), vt(N))
      )) {
        const M = gt(y, 7);
        J(M);
      }
    };
    if (J(j), e.sixWeeks && T.length < 6) {
      const y = 6 - T.length;
      for (let d = 1; d <= y; d++) {
        const M = T[T.length - 1], Te = M.days[M.days.length - 1], Le = Y(gt(Te.value, 1), Oe(b));
        T.push({ days: Le });
      }
    }
    return T;
  }, ie = (i, p, T) => [ze($(i), { date: 1 }), ze($(), { month: p, year: T, date: 1 })], ue = (i, p) => Fe(...ie(e.minDate, i, p)) || Ne(...ie(e.minDate, i, p)), x = (i, p) => Ke(...ie(e.maxDate, i, p)) || Ne(...ie(e.maxDate, i, p)), P = (i, p, T) => {
    let b = !1;
    return e.maxDate && T && x(i, p) && (b = !0), e.minDate && !T && ue(i, p) && (b = !0), b;
  };
  return {
    checkPartialRangeValue: r,
    checkRangeEnabled: t,
    getZonedDate: u,
    getZonedToUtc: v,
    formatDate: o,
    getDefaultPattern: l,
    validateDate: _,
    getDefaultStartTime: s,
    isDisabled: w,
    isValidDate: I,
    sanitizeDate: R,
    getWeekFromDate: K,
    matchDate: X,
    setDateMonthOrYear: B,
    isValidTime: Z,
    getCalendarDays: F,
    validateMonthYearInRange: (i, p, T, b) => {
      let N = !1;
      return b ? e.minDate && e.maxDate ? N = P(i, p, T) : (e.minDate && ue(i, p) || e.maxDate && x(i, p)) && (N = !0) : N = !0, N;
    },
    validateMaxDate: x,
    validateMinDate: ue,
    defaults: a,
    hideNavigationButtons: h
  };
}, Pe = aa({
  monthYear: [],
  calendar: [],
  time: [],
  actionRow: [],
  selectionGrid: [],
  timePicker: {
    0: [],
    1: []
  },
  monthPicker: []
}), Ba = q(null), ua = q(!1), La = q(!1), Ha = q(!1), Fa = q(!1), Qe = q(0), We = q(0), Tt = () => {
  const e = V(() => ua.value ? [...Pe.selectionGrid, Pe.actionRow].filter((m) => m.length) : La.value ? [
    ...Pe.timePicker[0],
    ...Pe.timePicker[1],
    Fa.value ? [] : [Ba.value],
    Pe.actionRow
  ].filter((m) => m.length) : Ha.value ? [...Pe.monthPicker, Pe.actionRow] : [Pe.monthYear, ...Pe.calendar, Pe.time, Pe.actionRow].filter((m) => m.length)), r = (m) => {
    Qe.value = m ? Qe.value + 1 : Qe.value - 1;
    let s = null;
    e.value[We.value] && (s = e.value[We.value][Qe.value]), s || (Qe.value = m ? Qe.value - 1 : Qe.value + 1);
  }, a = (m) => {
    We.value === 0 && !m || We.value === e.value.length && m || (We.value = m ? We.value + 1 : We.value - 1, e.value[We.value] ? e.value[We.value] && !e.value[We.value][Qe.value] && Qe.value !== 0 && (Qe.value = e.value[We.value].length - 1) : We.value = m ? We.value - 1 : We.value + 1);
  }, t = (m) => {
    let s = null;
    e.value[We.value] && (s = e.value[We.value][Qe.value]), s ? s.focus({ preventScroll: !ua.value }) : Qe.value = m ? Qe.value - 1 : Qe.value + 1;
  }, n = () => {
    r(!0), t(!0);
  }, l = () => {
    r(!1), t(!1);
  }, o = () => {
    a(!1), t(!0);
  }, u = () => {
    a(!0), t(!0);
  }, v = (m, s) => {
    Pe[s] = m;
  }, h = (m, s) => {
    Pe[s] = m;
  }, _ = () => {
    Qe.value = 0, We.value = 0;
  };
  return {
    buildMatrix: v,
    buildMultiLevelMatrix: h,
    setTimePickerBackRef: (m) => {
      Ba.value = m;
    },
    setSelectionGrid: (m) => {
      ua.value = m, _(), m || (Pe.selectionGrid = []);
    },
    setTimePicker: (m, s = !1) => {
      La.value = m, Fa.value = s, _(), m || (Pe.timePicker[0] = [], Pe.timePicker[1] = []);
    },
    setTimePickerElements: (m, s = 0) => {
      Pe.timePicker[s] = m;
    },
    arrowRight: n,
    arrowLeft: l,
    arrowUp: o,
    arrowDown: u,
    clearArrowNav: () => {
      Pe.monthYear = [], Pe.calendar = [], Pe.time = [], Pe.actionRow = [], Pe.selectionGrid = [], Pe.timePicker[0] = [], Pe.timePicker[1] = [], ua.value = !1, La.value = !1, Fa.value = !1, Ha.value = !1, _(), Ba.value = null;
    },
    setMonthPicker: (m) => {
      Ha.value = m, _();
    },
    refSets: Pe
    // exposed for testing
  };
}, Sr = (e) => Array.isArray(e), Pt = (e) => Array.isArray(e), Ar = (e) => Array.isArray(e) && e.length === 2, pu = (e, r, a, t, n) => {
  const {
    getDefaultStartTime: l,
    isDisabled: o,
    sanitizeDate: u,
    getWeekFromDate: v,
    setDateMonthOrYear: h,
    validateMonthYearInRange: _,
    defaults: m
  } = et(e), s = V({
    get: () => e.internalModelValue,
    set: (c) => {
      !e.readonly && !e.disabled && r("update:internal-model-value", c);
    }
  }), w = q([]);
  Yt(s, () => {
    e.multiCalendars || te();
  });
  const I = q([{ month: Oe($()), year: $e($()) }]), R = aa({
    hours: e.range ? [ft($()), ft($())] : ft($()),
    minutes: e.range ? [pt($()), pt($())] : pt($()),
    seconds: e.range ? [0, 0] : 0
  }), K = V(
    () => (c) => I.value[c] ? I.value[c].month : 0
  ), X = V(
    () => (c) => I.value[c] ? I.value[c].year : 0
  ), B = V(() => e.flow && e.flow.length ? n.value === e.flow.length : !0), A = (c, O, ee) => {
    var re, Ce;
    I.value[c] || (I.value[c] = { month: 0, year: 0 }), I.value[c].month = O === null ? (re = I.value[c]) == null ? void 0 : re.month : O, I.value[c].year = ee === null ? (Ce = I.value[c]) == null ? void 0 : Ce.year : ee;
  }, H = (c, O) => {
    R[c] = O;
  };
  ot(() => {
    s.value || (e.startDate && (A(0, Oe($(e.startDate)), $e($(e.startDate))), m.value.multiCalendars && Qt(0)), m.value.startTime && x()), te(!0);
  });
  const te = (c = !1) => {
    if (s.value)
      return Array.isArray(s.value) ? (w.value = s.value, Z(c)) : g(s.value);
    if (e.timePicker)
      return Y();
    if (e.monthPicker && !e.range)
      return F();
    if (e.yearPicker && !e.range)
      return ie();
    if (m.value.multiCalendars && c && !e.startDate)
      return le($(), c);
  }, le = (c, O = !1) => {
    if ((!m.value.multiCalendars || !e.multiStatic || O) && A(0, Oe(c), $e(c)), m.value.multiCalendars)
      for (let ee = 1; ee < m.value.multiCalendars; ee++) {
        const re = ze($(), { month: K.value(ee - 1), year: X.value(ee - 1) }), Ce = Ir(re, { months: 1 });
        I.value[ee] = { month: Oe(Ce), year: $e(Ce) };
      }
  }, g = (c) => {
    le(c), H("hours", ft(c)), H("minutes", pt(c)), H("seconds", Ht(c));
  }, C = (c, O) => {
    le(c[0], O);
    const ee = (re, Ce) => [
      re(c[0]),
      c[1] ? re(c[1]) : R[Ce][1]
    ];
    H("hours", ee(ft, "hours")), H("minutes", ee(pt, "minutes")), H("seconds", ee(Ht, "seconds"));
  }, E = (c, O) => {
    if ((e.range || e.weekPicker) && !e.multiDates)
      return C(c, O);
    if (e.multiDates) {
      const ee = c[c.length - 1];
      return g(ee);
    }
  }, Z = (c) => {
    const O = s.value;
    E(O, c), m.value.multiCalendars && e.multiCalendarsSolo && i();
  }, Y = () => {
    if (x(), !e.range)
      s.value = tt($(), R.hours, R.minutes, ue());
    else {
      const c = R.hours, O = R.minutes;
      s.value = [
        tt($(), c[0], O[0], ue()),
        tt($(), c[1], O[1], ue(!1))
      ];
    }
  }, F = () => {
    s.value = h($(), K.value(0), X.value(0));
  }, ie = () => {
    s.value = $();
  }, ue = (c = !0) => e.enableSeconds ? Array.isArray(R.seconds) ? c ? R.seconds[0] : R.seconds[1] : R.seconds : 0, x = () => {
    const c = l();
    if (c) {
      const O = Array.isArray(c), ee = O ? [+c[0].hours, +c[1].hours] : +c.hours, re = O ? [+c[0].minutes, +c[1].minutes] : +c.minutes, Ce = O ? [+c[0].seconds, +c[1].seconds] : +c.seconds;
      H("hours", ee), H("minutes", re), e.enableSeconds && H("seconds", Ce);
    }
  }, P = () => Array.isArray(s.value) && s.value.length ? s.value[s.value.length - 1] : null, i = () => {
    if (Array.isArray(s.value) && s.value.length === 2) {
      const c = $(
        $(s.value[1] ? s.value[1] : st(s.value[0], 1))
      ), [O, ee] = [Oe(s.value[0]), $e(s.value[0])], [re, Ce] = [Oe(s.value[1]), $e(s.value[1])];
      (O !== re || O === re && ee !== Ce) && e.multiCalendarsSolo && A(1, Oe(c), $e(c));
    }
  }, p = (c) => {
    const O = st(c, 1);
    return { month: Oe(O), year: $e(O) };
  }, T = (c) => {
    const O = Oe($(c)), ee = $e($(c));
    if (A(0, O, ee), m.value.multiCalendars > 0)
      for (let re = 1; re < m.value.multiCalendars; re++) {
        const Ce = p(
          ze($(c), { year: K.value(re - 1), month: X.value(re - 1) })
        );
        A(re, Ce.month, Ce.year);
      }
  }, b = (c) => {
    if (s.value && Array.isArray(s.value))
      if (s.value.some((O) => Ne(c, O))) {
        const O = s.value.filter((ee) => !Ne(ee, c));
        s.value = O.length ? O : null;
      } else
        (e.multiDatesLimit && +e.multiDatesLimit > s.value.length || !e.multiDatesLimit) && s.value.push(c);
    else
      s.value = [c];
  }, N = (c, O) => {
    const ee = Ke(c, O) ? O : c, re = Ke(O, c) ? O : c;
    return mr({ start: ee, end: re });
  }, j = (c) => {
    if (Array.isArray(s.value) && s.value[0]) {
      const O = Dn(c, s.value[0]), ee = N(s.value[0], c), re = ee.length === 1 ? 0 : ee.filter((lt) => o(lt)).length, Ce = Math.abs(O) - re;
      if (e.minRange && e.maxRange)
        return Ce >= +e.minRange && Ce <= +e.maxRange;
      if (e.minRange)
        return Ce >= +e.minRange;
      if (e.maxRange)
        return Ce <= +e.maxRange;
    }
    return !0;
  }, J = (c) => Array.isArray(s.value) && s.value.length === 2 ? e.fixedStart && (Ke(c, s.value[0]) || Ne(c, s.value[0])) ? [s.value[0], c] : e.fixedEnd && (Fe(c, s.value[1]) || Ne(c, s.value[1])) ? [c, s.value[1]] : (r("invalid-fixed-range", c), s.value) : [], y = () => {
    e.autoApply && B.value && r("auto-apply");
  }, d = () => {
    e.autoApply && r("select-date");
  }, M = (c) => !mr({ start: c[0], end: c[1] }).some((O) => o(O)), Te = (c) => (s.value = v($(c.value)), y()), Le = (c) => {
    const O = tt($(c.value), R.hours, R.minutes, ue());
    e.multiDates ? b(O) : s.value = O, a(), y();
  }, Ye = () => {
    w.value = s.value ? s.value.slice() : [], w.value.length === 2 && !(e.fixedStart || e.fixedEnd) && (w.value = []);
  }, Ze = (c, O) => {
    const ee = [$(c.value), gt($(c.value), +e.autoRange)];
    M(ee) && (O && T(c.value), w.value = ee);
  }, rt = (c) => {
    de(c.value) || (w.value = J($(c.value)));
  }, de = (c) => e.noDisabledRange ? N(w.value[0], c).some((O) => o(O)) : !1, ce = (c, O) => {
    if (Ye(), e.autoRange)
      return Ze(c, O);
    if (e.fixedStart || e.fixedEnd)
      return rt(c);
    w.value[0] ? j($(c.value)) && !de(c.value) && (Fe($(c.value), $(w.value[0])) ? w.value.unshift($(c.value)) : w.value[1] = $(c.value)) : w.value[0] = $(c.value);
  }, xe = (c) => {
    w.value[c] = tt(
      w.value[c],
      R.hours[c],
      R.minutes[c],
      ue(c !== 1)
    );
  }, he = () => {
    w.value.length && (w.value[0] && !w.value[1] ? xe(0) : (xe(0), xe(1), a()), s.value = w.value.slice(), w.value[0] && w.value[1] && e.autoApply && r("auto-apply"), w.value[0] && !w.value[1] && e.modelAuto && e.autoApply && r("auto-apply"));
  }, qt = (c, O = !1) => {
    if (!(o(c.value) || !c.current && e.hideOffsetDates)) {
      if (e.weekPicker)
        return Te(c);
      if (!e.range)
        return Le(c);
      Pt(R.hours) && Pt(R.minutes) && !e.multiDates && (ce(c, O), he());
    }
  }, mt = (c) => {
    const O = c.find((ee) => ee.current);
    return O ? xo(O.value) : "";
  }, Qt = (c) => {
    for (let O = c - 1; O >= 0; O--) {
      const ee = ct(ze($(), { month: K.value(O + 1), year: X.value(O + 1) }), 1);
      A(O, Oe(ee), $e(ee));
    }
    for (let O = c + 1; O <= m.value.multiCalendars - 1; O++) {
      const ee = st(ze($(), { month: K.value(O - 1), year: X.value(O - 1) }), 1);
      A(O, Oe(ee), $e(ee));
    }
  }, Mt = (c) => h($(), K.value(c), X.value(c)), zt = (c) => tt(c, R.hours, R.minutes, ue()), Oa = (c, O) => {
    const ee = e.monthPicker ? K.value(c) !== O.month || !O.fromNav : X.value(c) !== O.year;
    if (A(c, O.month, O.year), m.value.multiCalendars && !e.multiCalendarsSolo && Qt(c), e.monthPicker || e.yearPicker)
      if (e.range) {
        if (ee) {
          let re = s.value ? s.value.slice() : [];
          re.length === 2 && re[1] !== null && (re = []), re.length ? Fe(Mt(c), re[0]) ? re.unshift(Mt(c)) : re[1] = Mt(c) : re = [Mt(c)], s.value = re;
        }
      } else
        s.value = Mt(c);
    r("update-month-year", { instance: c, month: O.month, year: O.year }), t(e.multiCalendarsSolo ? c : void 0);
  }, $a = async (c = !1) => {
    if (e.autoApply && (e.monthPicker || e.yearPicker)) {
      await Bt();
      const O = e.monthPicker ? c : !1;
      e.range ? r("auto-apply", O || !s.value || s.value.length === 1) : r("auto-apply", O);
    }
    a();
  }, na = (c, O) => {
    const ee = ze($(), { month: K.value(O), year: X.value(O) }), re = c < 0 ? st(ee, 1) : ct(ee, 1);
    _(Oe(re), $e(re), c < 0, e.preventMinMaxNavigation) && (A(O, Oe(re), $e(re)), m.value.multiCalendars && !e.multiCalendarsSolo && Qt(O), r("update-month-year", { instance: O, month: Oe(re), year: $e(re) }), t());
  }, Gt = (c) => {
    Sr(c) && Sr(s.value) && Pt(R.hours) && Pt(R.minutes) ? (c[0] && s.value[0] && (s.value[0] = tt(c[0], R.hours[0], R.minutes[0], ue())), c[1] && s.value[1] && (s.value[1] = tt(c[1], R.hours[1], R.minutes[1], ue(!1)))) : e.multiDates && Array.isArray(s.value) ? s.value[s.value.length - 1] = zt(c) : !e.range && !Ar(c) && (s.value = zt(c)), r("time-update");
  }, Na = (c, O = !0, ee = !1) => {
    const re = O ? c : R.hours, Ce = !O && !ee ? c : R.minutes, lt = ee ? c : R.seconds;
    if (e.range && Ar(s.value) && Pt(re) && Pt(Ce) && Pt(lt) && !e.disableTimeRangeValidation) {
      const Ct = (Q) => tt(s.value[Q], re[Q], Ce[Q], lt[Q]), D = (Q) => ir(s.value[Q], 0);
      if (Ne(s.value[0], s.value[1]) && (rr(Ct(0), D(1)) || nr(Ct(1), D(0))))
        return;
    }
    if (H("hours", re), H("minutes", Ce), H("seconds", lt), s.value)
      if (e.multiDates) {
        const Ct = P();
        Ct && Gt(Ct);
      } else
        Gt(s.value);
    else
      e.timePicker && Gt(e.range ? [$(), $()] : $());
    a();
  }, Sa = (c, O) => {
    e.monthChangeOnScroll && na(e.monthChangeOnScroll !== "inverse" ? -c.deltaY : c.deltaY, O);
  }, Aa = (c, O, ee = !1) => {
    e.monthChangeOnArrows && e.vertical === ee && oa(c, O);
  }, oa = (c, O) => {
    na(c === "right" ? -1 : 1, O);
  };
  return {
    time: R,
    month: K,
    year: X,
    modelValue: s,
    calendars: I,
    monthYearSelect: $a,
    isDisabled: o,
    updateTime: Na,
    getWeekNum: mt,
    selectDate: qt,
    updateMonthYear: Oa,
    handleScroll: Sa,
    getMarker: (c) => e.markers.find((O) => Ne(u(c.value), u(O.date))),
    handleArrow: Aa,
    handleSwipe: oa,
    selectCurrentDate: () => {
      e.range ? s.value && Array.isArray(s.value) && s.value[0] ? s.value = Fe($(), s.value[0]) ? [$(), s.value[0]] : [s.value[0], $()] : s.value = [$()] : s.value = $(), d();
    },
    presetDateRange: (c, O) => {
      O || c.length && c.length <= 2 && e.range && (s.value = c.map((ee) => $(ee)), d(), e.multiCalendars && Bt().then(() => te(!0)));
    }
  };
}, yu = (e, r, a) => {
  const t = q(), {
    getZonedToUtc: n,
    getZonedDate: l,
    formatDate: o,
    getDefaultPattern: u,
    checkRangeEnabled: v,
    checkPartialRangeValue: h,
    isValidDate: _,
    setDateMonthOrYear: m,
    defaults: s
  } = et(r), w = q(""), I = ma(r, "format");
  Yt(t, () => {
    e("internal-model-change", t.value);
  }), Yt(I, () => {
    p();
  });
  const R = (y) => {
    const d = y || $();
    return r.modelType ? b(d) : {
      hours: ft(d),
      minutes: pt(d),
      seconds: r.enableSeconds ? Ht(d) : 0
    };
  }, K = (y) => r.modelType ? b(y) : { month: Oe(y), year: $e(y) }, X = (y) => Array.isArray(y) ? v(() => [
    At($(), y[0]),
    y[1] ? At($(), y[1]) : h()
  ]) : At($(), +y), B = (y, d) => (typeof y == "string" || typeof y == "number") && r.modelType ? T(y) : d, A = (y) => Array.isArray(y) ? [
    B(
      y[0],
      tt(null, +y[0].hours, +y[0].minutes, y[0].seconds)
    ),
    B(
      y[1],
      tt(null, +y[1].hours, +y[1].minutes, y[1].seconds)
    )
  ] : B(y, tt(null, y.hours, y.minutes, y.seconds)), H = (y) => Array.isArray(y) ? v(() => [
    B(y[0], m(null, +y[0].month, +y[0].year)),
    B(
      y[1],
      y[1] ? m(null, +y[1].month, +y[1].year) : h()
    )
  ]) : B(y, m(null, +y.month, +y.year)), te = (y) => {
    if (Array.isArray(y))
      return y.map((d) => T(d));
    throw new Error(Ta.dateArr("multi-dates"));
  }, le = (y) => {
    if (Array.isArray(y))
      return [$(y[0]), $(y[1])];
    throw new Error(Ta.dateArr("week-picker"));
  }, g = (y) => r.modelAuto ? Array.isArray(y) ? [T(y[0]), T(y[1])] : r.autoApply ? [T(y)] : [T(y), null] : Array.isArray(y) ? v(() => [
    T(y[0]),
    y[1] ? T(y[1]) : h()
  ]) : T(y), C = () => {
    Array.isArray(t.value) && r.range && t.value.length === 1 && t.value.push(h());
  }, E = () => {
    const y = t.value;
    return [
      b(y[0]),
      y[1] ? b(y[1]) : h()
    ];
  }, Z = () => t.value[1] ? E() : b(He(t.value[0])), Y = () => (t.value || []).map((y) => b(y)), F = () => (C(), r.modelAuto ? Z() : r.multiDates ? Y() : Array.isArray(t.value) ? v(() => E()) : b(He(t.value))), ie = (y) => y ? r.timePicker ? A(He(y)) : r.monthPicker ? H(He(y)) : r.yearPicker ? X(He(y)) : r.multiDates ? te(He(y)) : r.weekPicker ? le(He(y)) : g(He(y)) : null, ue = (y) => {
    const d = ie(y);
    _(He(d)) ? (t.value = He(d), p()) : (t.value = null, w.value = "");
  }, x = () => {
    var y;
    const d = (M) => {
      var Te;
      return _t(M, (Te = s.value.textInputOptions) == null ? void 0 : Te.format);
    };
    return `${d(t.value[0])} ${(y = s.value.textInputOptions) == null ? void 0 : y.rangeSeparator} ${t.value[1] ? d(t.value[1]) : ""}`;
  }, P = () => {
    var y;
    return a.value && t.value ? Array.isArray(t.value) ? x() : _t(t.value, (y = s.value.textInputOptions) == null ? void 0 : y.format) : o(t.value);
  }, i = () => {
    var y;
    return t.value ? r.multiDates ? t.value.map((d) => o(d)).join("; ") : r.textInput && typeof ((y = s.value.textInputOptions) == null ? void 0 : y.format) == "string" ? P() : o(t.value) : "";
  }, p = () => {
    !r.format || typeof r.format == "string" ? w.value = i() : w.value = r.format(t.value);
  }, T = (y) => {
    if (r.utc) {
      const d = new Date(y);
      return r.utc === "preserve" ? new Date(d.getTime() + d.getTimezoneOffset() * 6e4) : d;
    }
    return r.modelType ? r.modelType === "date" || r.modelType === "timestamp" ? l(new Date(y)) : r.modelType === "format" && (typeof r.format == "string" || !r.format) ? Ka(y, u(), /* @__PURE__ */ new Date()) : l(Ka(y, r.modelType, /* @__PURE__ */ new Date())) : l(new Date(y));
  }, b = (y) => r.utc ? $i(y, r.utc === "preserve") : r.modelType ? r.modelType === "timestamp" ? +n(y) : r.modelType === "format" && (typeof r.format == "string" || !r.format) ? o(n(y)) : o(n(y), r.modelType) : n(y), N = (y) => {
    e("update:model-value", y);
  }, j = (y) => Array.isArray(t.value) ? [
    y(t.value[0]),
    t.value[1] ? y(t.value[1]) : h()
  ] : y(He(t.value)), J = (y) => N(He(j(y)));
  return {
    inputValue: w,
    internalModelValue: t,
    checkBeforeEmit: () => t.value ? r.range ? r.partialRange ? t.value.length >= 1 : t.value.length === 2 : !!t.value : !1,
    parseExternalModelValue: ue,
    formatInputValue: p,
    emitModelValue: () => (p(), r.monthPicker ? J(K) : r.timePicker ? J(R) : r.yearPicker ? J($e) : r.weekPicker ? N(t.value) : N(F()))
  };
}, hu = (e, r) => {
  const { validateMonthYearInRange: a, validateMaxDate: t, validateMinDate: n, defaults: l } = et(e), o = (m, s) => {
    let w = m;
    return l.value.filters.months.includes(Oe(w)) ? (w = s ? st(m, 1) : ct(m, 1), o(w, s)) : w;
  }, u = (m, s) => {
    let w = m;
    return l.value.filters.years.includes($e(w)) ? (w = s ? Ur(m, 1) : Bl(m, 1), u(w, s)) : w;
  }, v = (m) => {
    const s = ze(/* @__PURE__ */ new Date(), { month: e.month, year: e.year });
    let w = m ? st(s, 1) : ct(s, 1), I = Oe(w), R = $e(w);
    l.value.filters.months.includes(I) && (w = o(w, m), I = Oe(w), R = $e(w)), l.value.filters.years.includes(R) && (w = u(w, m), R = $e(w)), a(I, R, m, e.preventMinMaxNavigation) && h(I, R);
  }, h = (m, s) => {
    r("update-month-year", { month: m, year: s });
  }, _ = V(() => (m) => {
    if (!e.preventMinMaxNavigation || m && !e.maxDate || !m && !e.minDate)
      return !1;
    const s = ze(/* @__PURE__ */ new Date(), { month: e.month, year: e.year }), w = m ? st(s, 1) : ct(s, 1), I = [Oe(w), $e(w)];
    return m ? !t(...I) : !n(...I);
  });
  return { handleMonthYearChange: v, isDisabled: _, updateMonthYear: h };
};
var va = /* @__PURE__ */ ((e) => (e.center = "center", e.left = "left", e.right = "right", e))(va || {});
const gu = (e, r, a, t) => {
  const n = q({
    top: "0",
    left: "0",
    transform: "none"
  }), l = q(!1), o = ma(t, "teleportCenter");
  Yt(o, () => {
    K();
  });
  const u = (g) => {
    const C = g.getBoundingClientRect();
    return {
      left: C.left + window.scrollX,
      top: C.top + window.scrollY
    };
  }, v = (g) => {
    const C = g.getBoundingClientRect();
    let E = 0, Z = 0;
    for (; g && !isNaN(g.offsetLeft) && !isNaN(g.offsetTop); )
      E += g.offsetLeft - g.scrollLeft, Z = C.top + g.scrollTop, g = g.offsetParent;
    return { top: Z, left: E };
  }, h = (g, C) => {
    n.value.left = `${g + C}px`, n.value.transform = "translateX(-100%)";
  }, _ = (g) => {
    n.value.left = `${g}px`, n.value.transform = "translateX(0)";
  }, m = (g, C, E = !1) => {
    t.position === va.left && _(g), t.position === va.right && h(g, C), t.position === va.center && (n.value.left = `${g + C / 2}px`, n.value.transform = E ? "translate(-50%, -50%)" : "translateX(-50%)");
  }, s = (g) => {
    const { width: C, height: E } = g.getBoundingClientRect(), { top: Z, left: Y } = t.altPosition ? v(g) : u(g);
    return { top: Z, left: Y, width: C, height: E };
  }, w = () => {
    const g = Ee(r);
    if (g) {
      const { top: C, left: E, width: Z, height: Y } = s(g);
      n.value.top = `${C + Y / 2}px`, n.value.transform = "translateY(-50%)", m(E, Z, !0);
    }
  }, I = () => {
    n.value.left = "50%", n.value.top = "50%", n.value.transform = "translate(-50%, -50%)", n.value.position = "fixed";
  }, R = () => {
    const g = Ee(r);
    n.value = t.altPosition(g);
  }, K = (g = !0) => {
    if (!t.inline)
      return o.value ? I() : t.altPosition && typeof t.altPosition != "boolean" ? R() : (g && a("recalculate-position"), le());
  }, X = ({
    inputEl: g,
    menuEl: C,
    left: E,
    width: Z
  }) => {
    window.screen.width > 768 && m(E, Z), H(g, C);
  }, B = (g, C) => {
    const { top: E, left: Z, height: Y, width: F } = s(g);
    n.value.top = `${Y + E + +t.offset}px`, X({ inputEl: g, menuEl: C, left: Z, width: F }), l.value = !1;
  }, A = (g, C) => {
    const { top: E, left: Z, width: Y } = s(g), { height: F } = C.getBoundingClientRect();
    n.value.top = `${E - F - +t.offset}px`, X({ inputEl: g, menuEl: C, left: Z, width: Y }), l.value = !0;
  }, H = (g, C) => {
    if (t.autoPosition) {
      const { left: E, width: Z } = s(g), { left: Y, right: F } = C.getBoundingClientRect();
      if (Y < 0)
        return _(E);
      if (F > document.documentElement.clientWidth)
        return h(E, Z);
    }
  }, te = (g, C) => {
    const { height: E } = C.getBoundingClientRect(), { top: Z, height: Y } = g.getBoundingClientRect(), F = window.innerHeight - Z - Y, ie = Z;
    return E <= F ? B(g, C) : E > F && E <= ie ? A(g, C) : F >= ie ? B(g, C) : A(g, C);
  }, le = () => {
    const g = Ee(r), C = Ee(e);
    if (g && C)
      return t.autoPosition ? te(g, C) : B(g, C);
  };
  return { openOnTop: l, menuPosition: n, setMenuPosition: K, setInitialPosition: w };
}, Vt = [
  { name: "clock-icon", use: ["time", "calendar"] },
  { name: "arrow-left", use: ["month-year", "calendar"] },
  { name: "arrow-right", use: ["month-year", "calendar"] },
  { name: "arrow-up", use: ["time", "calendar"] },
  { name: "arrow-down", use: ["time", "calendar"] },
  { name: "calendar-icon", use: ["month-year", "time", "calendar"] },
  { name: "day", use: ["calendar"] },
  { name: "month-overlay-value", use: ["calendar", "month-year"] },
  { name: "year-overlay-value", use: ["calendar", "month-year"] },
  { name: "year-overlay", use: ["month-year"] },
  { name: "month-overlay", use: ["month-year"] },
  { name: "month-overlay-header", use: ["month-year"] },
  { name: "year-overlay-header", use: ["month-year"] },
  { name: "hours-overlay-value", use: ["calendar", "time"] },
  { name: "minutes-overlay-value", use: ["calendar", "time"] },
  { name: "seconds-overlay-value", use: ["calendar", "time"] },
  { name: "hours", use: ["calendar", "time"] },
  { name: "minutes", use: ["calendar", "time"] },
  { name: "month", use: ["calendar", "month-year"] },
  { name: "year", use: ["calendar", "month-year"] },
  { name: "action-select", use: ["action"] },
  { name: "action-preview", use: ["action"] },
  { name: "calendar-header", use: ["calendar"] },
  { name: "marker-tooltip", use: ["calendar"] },
  { name: "now-button", use: [] },
  { name: "time-picker-overlay", use: ["calendar", "time"] },
  { name: "am-pm-button", use: ["calendar", "time"] },
  { name: "left-sidebar", use: ["menu"] },
  { name: "right-sidebar", use: ["menu"] },
  { name: "month-year", use: ["month-year"] },
  { name: "time-picker", use: ["menu"] },
  { name: "action-row", use: ["action"] }
], wu = [{ name: "trigger" }, { name: "input-icon" }, { name: "clear-icon" }, { name: "dp-input" }], bu = {
  all: () => Vt,
  monthYear: () => Vt.filter((e) => e.use.includes("month-year")),
  input: () => wu,
  timePicker: () => Vt.filter((e) => e.use.includes("time")),
  action: () => Vt.filter((e) => e.use.includes("action")),
  calendar: () => Vt.filter((e) => e.use.includes("calendar")),
  menu: () => Vt.filter((e) => e.use.includes("menu"))
}, St = (e, r, a) => {
  const t = [];
  return bu[r]().forEach((n) => {
    e[n.name] && t.push(n.name);
  }), a && a.length && a.forEach((n) => {
    n.slot && t.push(n.slot);
  }), t;
}, Pa = (e) => ({ transitionName: V(() => (r) => e && typeof e != "boolean" ? r ? e.open : e.close : ""), showTransition: !!e }), xt = {
  multiCalendars: { type: [Boolean, Number, String], default: null },
  modelValue: { type: [String, Date, Array, Object, Number], default: null },
  modelType: { type: String, default: null },
  position: { type: String, default: "center" },
  dark: { type: Boolean, default: !1 },
  format: {
    type: [String, Function],
    default: () => null
  },
  closeOnScroll: { type: Boolean, default: !1 },
  autoPosition: { type: Boolean, default: !0 },
  closeOnAutoApply: { type: Boolean, default: !0 },
  teleport: { type: [String, Object], default: "body" },
  altPosition: { type: [Boolean, Function], default: !1 },
  transitions: { type: [Boolean, Object], default: !0 },
  formatLocale: { type: Object, default: null },
  utc: { type: [Boolean, String], default: !1 },
  ariaLabels: { type: Object, default: () => ({}) },
  offset: { type: [Number, String], default: 10 },
  hideNavigation: { type: Array, default: () => [] },
  timezone: { type: String, default: null },
  vertical: { type: Boolean, default: !1 },
  disableMonthYearSelect: { type: Boolean, default: !1 },
  menuClassName: { type: String, default: null },
  dayClass: { type: Function, default: null },
  yearRange: { type: Array, default: () => [1900, 2100] },
  multiCalendarsSolo: { type: Boolean, default: !1 },
  calendarCellClassName: { type: String, default: null },
  enableTimePicker: { type: Boolean, default: !0 },
  autoApply: { type: Boolean, default: !1 },
  disabledDates: { type: [Array, Function], default: () => [] },
  monthNameFormat: { type: String, default: "short" },
  startDate: { type: [Date, String], default: null },
  startTime: { type: [Object, Array], default: null },
  monthYearComponent: { type: Object, default: null },
  timePickerComponent: { type: Object, default: null },
  actionRowComponent: { type: Object, default: null },
  hideOffsetDates: { type: Boolean, default: !1 },
  autoRange: { type: [Number, String], default: null },
  noToday: { type: Boolean, default: !1 },
  disabledWeekDays: { type: Array, default: () => [] },
  allowedDates: { type: Array, default: () => [] },
  showNowButton: { type: Boolean, default: !1 },
  nowButtonLabel: { type: String, default: "Now" },
  markers: { type: Array, default: () => [] },
  modeHeight: { type: [Number, String], default: 255 },
  escClose: { type: Boolean, default: !0 },
  spaceConfirm: { type: Boolean, default: !0 },
  monthChangeOnArrows: { type: Boolean, default: !0 },
  presetRanges: { type: Array, default: () => [] },
  flow: { type: Array, default: () => [] },
  preventMinMaxNavigation: { type: Boolean, default: !1 },
  minRange: { type: [Number, String], default: null },
  maxRange: { type: [Number, String], default: null },
  multiDatesLimit: { type: [Number, String], default: null },
  reverseYears: { type: Boolean, default: !1 },
  keepActionRow: { type: Boolean, default: !1 },
  weekPicker: { type: Boolean, default: !1 },
  filters: { type: Object, default: () => ({}) },
  arrowNavigation: { type: Boolean, default: !1 },
  multiStatic: { type: Boolean, default: !0 },
  disableTimeRangeValidation: { type: Boolean, default: !1 },
  highlight: {
    type: [Array, Function],
    default: null
  },
  highlightWeekDays: {
    type: Array,
    default: null
  },
  highlightDisabledDays: { type: Boolean, default: !1 },
  teleportCenter: { type: Boolean, default: !1 },
  locale: { type: String, default: "en-Us" },
  weekNumName: { type: String, default: "W" },
  weekStart: { type: [Number, String], default: 1 },
  weekNumbers: { type: Boolean, default: !1 },
  calendarClassName: { type: String, default: null },
  noSwipe: { type: Boolean, default: !1 },
  monthChangeOnScroll: { type: [Boolean, String], default: !0 },
  dayNames: {
    type: [Function, Array],
    default: null
  },
  monthPicker: { type: Boolean, default: !1 },
  customProps: { type: Object, default: null },
  yearPicker: { type: Boolean, default: !1 },
  modelAuto: { type: Boolean, default: !1 },
  selectText: { type: String, default: "Select" },
  cancelText: { type: String, default: "Cancel" },
  previewFormat: {
    type: [String, Function],
    default: () => ""
  },
  multiDates: { type: Boolean, default: !1 },
  partialRange: { type: Boolean, default: !0 },
  ignoreTimeValidation: { type: Boolean, default: !1 },
  minDate: { type: [Date, String], default: null },
  maxDate: { type: [Date, String], default: null },
  minTime: { type: Object, default: null },
  maxTime: { type: Object, default: null },
  name: { type: String, default: null },
  placeholder: { type: String, default: "" },
  hideInputIcon: { type: Boolean, default: !1 },
  clearable: { type: Boolean, default: !0 },
  state: { type: Boolean, default: null },
  required: { type: Boolean, default: !1 },
  autocomplete: { type: String, default: "off" },
  inputClassName: { type: String, default: null },
  inlineWithInput: { type: Boolean, default: !1 },
  textInputOptions: { type: Object, default: () => null },
  fixedStart: { type: Boolean, default: !1 },
  fixedEnd: { type: Boolean, default: !1 },
  timePicker: { type: Boolean, default: !1 },
  enableSeconds: { type: Boolean, default: !1 },
  is24: { type: Boolean, default: !0 },
  noHoursOverlay: { type: Boolean, default: !1 },
  noMinutesOverlay: { type: Boolean, default: !1 },
  noSecondsOverlay: { type: Boolean, default: !1 },
  hoursGridIncrement: { type: [String, Number], default: 1 },
  minutesGridIncrement: { type: [String, Number], default: 5 },
  secondsGridIncrement: { type: [String, Number], default: 5 },
  hoursIncrement: { type: [Number, String], default: 1 },
  minutesIncrement: { type: [Number, String], default: 1 },
  secondsIncrement: { type: [Number, String], default: 1 },
  range: { type: Boolean, default: !1 },
  uid: { type: String, default: null },
  disabled: { type: Boolean, default: !1 },
  readonly: { type: Boolean, default: !1 },
  inline: { type: Boolean, default: !1 },
  textInput: { type: Boolean, default: !1 },
  onClickOutside: { type: Function, default: null },
  noDisabledRange: { type: Boolean, default: !1 },
  sixWeeks: { type: Boolean, default: !1 }
}, _u = ["aria-label", "aria-disabled", "aria-readonly"], ku = {
  key: 1,
  class: "dp__input_wrap"
}, Du = ["id", "name", "inputmode", "placeholder", "disabled", "readonly", "required", "value", "autocomplete", "onKeydown"], Tu = {
  key: 2,
  class: "dp__input_icon"
}, xu = {
  key: 4,
  class: "dp__clear_icon"
}, Mu = /* @__PURE__ */ nt({
  __name: "DatepickerInput",
  props: {
    isMenuOpen: { type: Boolean, default: !1 },
    inputValue: { type: String, default: "" },
    ...xt
  },
  emits: [
    "clear",
    "open",
    "update:input-value",
    "set-input-date",
    "close",
    "select-date",
    "set-empty-date",
    "toggle",
    "focus-prev",
    "focus",
    "blur"
  ],
  setup(e, { expose: r, emit: a }) {
    const t = e, { getDefaultPattern: n, isValidDate: l, defaults: o } = et(t), u = q(), v = q(null), h = q(!1), _ = V(
      () => ({
        dp__pointer: !t.disabled && !t.readonly && !t.textInput,
        dp__disabled: t.disabled,
        dp__input_readonly: !t.textInput,
        dp__input: !0,
        dp__input_icon_pad: !t.hideInputIcon,
        dp__input_valid: t.state,
        dp__input_invalid: t.state === !1,
        dp__input_focus: h.value || t.isMenuOpen,
        dp__input_reg: !t.textInput,
        [t.inputClassName]: !!t.inputClassName
      })
    ), m = () => {
      a("set-input-date", null), t.autoApply && (a("set-empty-date"), u.value = null);
    }, s = (g) => {
      var C;
      return Oi(g, ((C = o.value.textInputOptions) == null ? void 0 : C.format) || n());
    }, w = (g) => {
      const { rangeSeparator: C } = o.value.textInputOptions, [E, Z] = g.split(`${C}`);
      if (E) {
        const Y = s(E.trim()), F = Z ? s(Z.trim()) : null, ie = Y && F ? [Y, F] : [Y];
        u.value = Y ? ie : null;
      }
    }, I = (g) => {
      if (t.range)
        w(g);
      else if (t.multiDates) {
        const C = g.split(";");
        u.value = C.map((E) => s(E.trim())).filter((E) => E);
      } else
        u.value = s(g);
    }, R = (g) => {
      var C;
      const { value: E } = g.target;
      E !== "" ? ((C = o.value.textInputOptions) != null && C.openMenu && !t.isMenuOpen && a("open"), I(E), a("set-input-date", u.value)) : m(), a("update:input-value", E);
    }, K = () => {
      var g, C;
      (g = o.value.textInputOptions) != null && g.enterSubmit && l(u.value) && t.inputValue !== "" ? (a("set-input-date", u.value, !0), u.value = null) : (C = o.value.textInputOptions) != null && C.enterSubmit && t.inputValue === "" && (u.value = null, a("clear"));
    }, X = () => {
      var g, C;
      (g = o.value.textInputOptions) != null && g.tabSubmit && l(u.value) && t.inputValue !== "" ? (a("set-input-date", u.value, !0), u.value = null) : (C = o.value.textInputOptions) != null && C.tabSubmit && t.inputValue === "" && (u.value = null, a("clear"));
    }, B = () => {
      h.value = !0, a("focus");
    }, A = (g) => {
      var C;
      g.preventDefault(), g.stopImmediatePropagation(), g.stopPropagation(), t.textInput && (C = o.value.textInputOptions) != null && C.openMenu && !t.inlineWithInput ? t.isMenuOpen ? o.value.textInputOptions.enterSubmit && a("select-date") : a("open") : t.textInput || a("toggle");
    }, H = () => {
      h.value = !1, t.isMenuOpen || a("blur"), t.autoApply && t.textInput && u.value && (a("set-input-date", u.value), a("select-date"), u.value = null);
    }, te = () => {
      a("clear");
    }, le = (g) => {
      t.textInput || g.preventDefault();
    };
    return r({
      focusInput: () => {
        v.value && v.value.focus({ preventScroll: !0 });
      }
    }), (g, C) => {
      var E;
      return k(), U("div", {
        onClick: A,
        "aria-label": (E = f(o).ariaLabels) == null ? void 0 : E.input,
        role: "textbox",
        "aria-multiline": "false",
        "aria-disabled": g.disabled,
        "aria-readonly": g.readonly
      }, [
        g.$slots.trigger && !g.$slots["dp-input"] && !g.inline ? z(g.$slots, "trigger", { key: 0 }) : S("", !0),
        !g.$slots.trigger && (!g.inline || g.inlineWithInput) ? (k(), U("div", ku, [
          g.$slots["dp-input"] && !g.$slots.trigger && !g.inline ? z(g.$slots, "dp-input", {
            key: 0,
            value: e.inputValue,
            onInput: R,
            onEnter: K,
            onTab: X,
            onClear: te
          }) : S("", !0),
          g.$slots["dp-input"] ? S("", !0) : (k(), U("input", {
            key: 1,
            ref_key: "inputRef",
            ref: v,
            id: g.uid ? `dp-input-${g.uid}` : void 0,
            name: g.name,
            class: Me(f(_)),
            inputmode: g.textInput ? "text" : "none",
            placeholder: g.placeholder,
            disabled: g.disabled,
            readonly: g.readonly,
            required: g.required,
            value: e.inputValue,
            autocomplete: g.autocomplete,
            onInput: R,
            onKeydown: [
              fe(A, ["enter"]),
              fe(X, ["tab"])
            ],
            onBlur: H,
            onFocus: B,
            onKeypress: le
          }, null, 42, Du)),
          g.$slots["input-icon"] && !g.hideInputIcon ? (k(), U("span", Tu, [
            z(g.$slots, "input-icon")
          ])) : S("", !0),
          !g.$slots["input-icon"] && !g.hideInputIcon && !g.$slots["dp-input"] ? (k(), be(f(Ca), {
            key: 3,
            class: "dp__input_icon dp__input_icons"
          })) : S("", !0),
          g.$slots["clear-icon"] && e.inputValue && g.clearable && !g.disabled && !g.readonly ? (k(), U("span", xu, [
            z(g.$slots, "clear-icon", { clear: te })
          ])) : S("", !0),
          g.clearable && !g.$slots["clear-icon"] && e.inputValue && !g.disabled && !g.readonly ? (k(), be(f(ai), {
            key: 5,
            class: "dp__clear_icon dp__input_icons",
            "data-test": "clear-icon",
            onClick: ht(te, ["stop", "prevent"])
          }, null, 8, ["onClick"])) : S("", !0)
        ])) : S("", !0)
      ], 8, _u);
    };
  }
}), Cu = { class: "dp__selection_preview" }, Pu = { class: "dp__action_buttons" }, Ou = ["onKeydown"], $u = /* @__PURE__ */ nt({
  __name: "ActionRow",
  props: {
    calendarWidth: { type: Number, default: 0 },
    menuMount: { type: Boolean, default: !1 },
    internalModelValue: { type: [Date, Array], default: null },
    ...xt
  },
  emits: ["close-picker", "select-date", "invalid-select"],
  setup(e, { emit: r }) {
    const a = e, { formatDate: t, isValidTime: n, defaults: l } = et(a), { buildMatrix: o } = Tt(), u = q(null), v = q(null);
    ot(() => {
      a.arrowNavigation && o([Ee(u), Ee(v)], "actionRow");
    });
    const h = V(() => a.range && !a.partialRange && a.internalModelValue ? a.internalModelValue.length === 2 : !0), _ = V(() => !s.value || !w.value || !h.value), m = V(() => ({
      dp__action: !0,
      dp__select: !0,
      dp__action_disabled: _.value
    })), s = V(() => !a.enableTimePicker || a.ignoreTimeValidation ? !0 : n(a.internalModelValue)), w = V(() => a.monthPicker ? X(a.internalModelValue) : !0), I = () => {
      const A = l.value.previewFormat;
      return a.timePicker || a.monthPicker, A(He(a.internalModelValue));
    }, R = () => {
      const A = a.internalModelValue;
      return l.value.multiCalendars > 0 ? `${t(A[0])} - ${t(A[1])}` : [t(A[0]), t(A[1])];
    }, K = V(() => !a.internalModelValue || !a.menuMount ? "" : typeof l.value.previewFormat == "string" ? Array.isArray(a.internalModelValue) ? a.internalModelValue.length === 2 && a.internalModelValue[1] ? R() : a.multiDates ? a.internalModelValue.map((A) => `${t(A)}`) : a.modelAuto ? `${t(a.internalModelValue[0])}` : `${t(a.internalModelValue[0])} -` : t(a.internalModelValue) : I()), X = (A) => {
      if (!a.monthPicker)
        return !0;
      let H = !0;
      return a.minDate && a.maxDate ? Ke($(A), $(a.minDate)) && Fe($(A), $(a.maxDate)) : (a.minDate && (H = Ke($(A), $(a.minDate))), a.maxDate && (H = Fe($(A), $(a.maxDate))), H);
    }, B = () => {
      s.value && w.value && h.value ? r("select-date") : r("invalid-select");
    };
    return (A, H) => (k(), U("div", {
      class: "dp__action_row",
      style: Nt(e.calendarWidth ? { width: `${e.calendarWidth}px` } : {})
    }, [
      A.$slots["action-row"] ? z(A.$slots, "action-row", Je(Ve({ key: 0 }, {
        internalModelValue: e.internalModelValue,
        disabled: f(_),
        selectDate: () => A.$emit("select-date"),
        closePicker: () => A.$emit("close-picker")
      }))) : (k(), U(_e, { key: 1 }, [
        se("div", Cu, [
          A.$slots["action-preview"] ? z(A.$slots, "action-preview", {
            key: 0,
            value: e.internalModelValue
          }) : S("", !0),
          A.$slots["action-preview"] ? S("", !0) : (k(), U(_e, { key: 1 }, [
            Array.isArray(f(K)) ? S("", !0) : (k(), U(_e, { key: 0 }, [
              dt(Be(f(K)), 1)
            ], 64)),
            Array.isArray(f(K)) ? (k(!0), U(_e, { key: 1 }, Re(f(K), (te, le) => (k(), U("div", { key: le }, Be(te), 1))), 128)) : S("", !0)
          ], 64))
        ]),
        se("div", Pu, [
          A.$slots["action-select"] ? z(A.$slots, "action-select", {
            key: 0,
            value: e.internalModelValue
          }) : S("", !0),
          A.$slots["action-select"] ? S("", !0) : (k(), U(_e, { key: 1 }, [
            A.inline ? S("", !0) : (k(), U("span", {
              key: 0,
              class: "dp__action dp__cancel",
              ref_key: "cancelButtonRef",
              ref: u,
              tabindex: "0",
              onClick: H[0] || (H[0] = (te) => A.$emit("close-picker")),
              onKeydown: [
                H[1] || (H[1] = fe((te) => A.$emit("close-picker"), ["enter"])),
                H[2] || (H[2] = fe((te) => A.$emit("close-picker"), ["space"]))
              ]
            }, Be(A.cancelText), 545)),
            se("span", {
              class: Me(f(m)),
              tabindex: "0",
              onKeydown: [
                fe(B, ["enter"]),
                fe(B, ["space"])
              ],
              onClick: B,
              "data-test": "select-button",
              ref_key: "selectButtonRef",
              ref: v
            }, Be(A.selectText), 43, Ou)
          ], 64))
        ])
      ], 64))
    ], 4));
  }
}), Nu = ["aria-label"], Su = {
  class: "dp__calendar_header",
  role: "row"
}, Au = {
  key: 0,
  class: "dp__calendar_header_item",
  role: "gridcell"
}, Yu = /* @__PURE__ */ se("div", { class: "dp__calendar_header_separator" }, null, -1), Iu = ["aria-label"], Uu = {
  key: 0,
  role: "gridcell",
  class: "dp__calendar_item dp__week_num"
}, Ru = { class: "dp__cell_inner" }, Eu = ["aria-selected", "aria-disabled", "aria-label", "data-test", "onClick", "onKeydown", "onMouseenter", "onMouseleave"], Wu = /* @__PURE__ */ se("div", { class: "dp__arrow_bottom_tp" }, null, -1), Vu = /* @__PURE__ */ nt({
  __name: "Calendar",
  props: {
    mappedDates: { type: Array, default: () => [] },
    getWeekNum: {
      type: Function,
      default: () => ""
    },
    specificMode: { type: Boolean, default: !1 },
    instance: { type: Number, default: 0 },
    month: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    ...xt
  },
  emits: [
    "select-date",
    "set-hover-date",
    "handle-scroll",
    "mount",
    "handle-swipe",
    "handle-space",
    "tooltip-open",
    "tooltip-close"
  ],
  setup(e, { expose: r, emit: a }) {
    const t = e, { buildMultiLevelMatrix: n } = Tt(), { setDateMonthOrYear: l, defaults: o } = et(t), u = q(null), v = q({ bottom: "", left: "", transform: "" }), h = q([]), _ = q(null), m = q(!0), s = q(""), w = q({ startX: 0, endX: 0, startY: 0, endY: 0 }), I = V(() => t.dayNames ? Array.isArray(t.dayNames) ? t.dayNames : t.dayNames(t.locale, +t.weekStart) : nu(t.locale, +t.weekStart));
    ot(() => {
      a("mount", { cmp: "calendar", refs: h }), t.noSwipe || _.value && (_.value.addEventListener("touchstart", E, { passive: !1 }), _.value.addEventListener("touchend", Z, { passive: !1 }), _.value.addEventListener("touchmove", Y, { passive: !1 })), t.monthChangeOnScroll && _.value && _.value.addEventListener("wheel", ue, { passive: !1 });
    });
    const R = (x) => x ? t.vertical ? "vNext" : "next" : t.vertical ? "vPrevious" : "previous", K = (x, P) => {
      if (t.transitions) {
        const i = vt(l($(), t.month, t.year));
        s.value = Ke(vt(l($(), x, P)), i) ? o.value.transitions[R(!0)] : o.value.transitions[R(!1)], m.value = !1, Bt(() => {
          m.value = !0;
        });
      }
    }, X = V(
      () => ({
        dp__calendar_wrap: !0,
        [t.calendarClassName]: !!t.calendarClassName
      })
    ), B = V(() => (x) => {
      const P = uu(x);
      return {
        dp__marker_dot: P.type === "dot",
        dp__marker_line: P.type === "line"
      };
    }), A = V(() => (x) => Ne(x, u.value)), H = V(() => ({
      dp__calendar: !0,
      dp__calendar_next: o.value.multiCalendars > 0 && t.instance !== 0
    })), te = V(() => (x) => t.hideOffsetDates ? x.current : !0), le = V(() => t.specificMode ? { height: `${t.modeHeight}px` } : void 0), g = (x, P, i) => {
      var p, T;
      if (a("set-hover-date", x), (T = (p = x.marker) == null ? void 0 : p.tooltip) != null && T.length) {
        const b = Ee(h.value[P][i]);
        if (b) {
          const { width: N, height: j } = b.getBoundingClientRect();
          v.value = {
            bottom: `${j}px`,
            left: `${N / 2}px`,
            transform: "translateX(-50%)"
          }, u.value = x.value, a("tooltip-open", x.value);
        }
      }
    }, C = (x) => {
      u.value && (u.value = null, a("tooltip-close", x.value));
    }, E = (x) => {
      w.value.startX = x.changedTouches[0].screenX, w.value.startY = x.changedTouches[0].screenY;
    }, Z = (x) => {
      w.value.endX = x.changedTouches[0].screenX, w.value.endY = x.changedTouches[0].screenY, F();
    }, Y = (x) => {
      t.vertical && !t.inline && x.preventDefault();
    }, F = () => {
      const x = t.vertical ? "Y" : "X";
      Math.abs(w.value[`start${x}`] - w.value[`end${x}`]) > 10 && a("handle-swipe", w.value[`start${x}`] > w.value[`end${x}`] ? "right" : "left");
    }, ie = (x, P, i) => {
      x && (Array.isArray(h.value[P]) ? h.value[P][i] = x : h.value[P] = [x]), t.arrowNavigation && n(h.value, "calendar");
    }, ue = (x) => {
      t.monthChangeOnScroll && (x.preventDefault(), a("handle-scroll", x));
    };
    return r({ triggerTransition: K }), (x, P) => {
      var i;
      return k(), U("div", {
        class: Me(f(H))
      }, [
        se("div", {
          style: Nt(f(le))
        }, [
          e.specificMode ? S("", !0) : (k(), U("div", {
            key: 0,
            ref_key: "calendarWrapRef",
            ref: _,
            class: Me(f(X)),
            role: "grid",
            "aria-label": (i = f(o).ariaLabels) == null ? void 0 : i.calendarWrap
          }, [
            se("div", Su, [
              x.weekNumbers ? (k(), U("div", Au, Be(x.weekNumName), 1)) : S("", !0),
              (k(!0), U(_e, null, Re(f(I), (p, T) => (k(), U("div", {
                class: "dp__calendar_header_item",
                role: "gridcell",
                key: T,
                "data-test": "calendar-header"
              }, [
                x.$slots["calendar-header"] ? z(x.$slots, "calendar-header", {
                  key: 0,
                  day: p,
                  index: T
                }) : S("", !0),
                x.$slots["calendar-header"] ? S("", !0) : (k(), U(_e, { key: 1 }, [
                  dt(Be(p), 1)
                ], 64))
              ]))), 128))
            ]),
            Yu,
            bt(Ft, {
              name: s.value,
              css: !!x.transitions
            }, {
              default: ve(() => {
                var p;
                return [
                  m.value ? (k(), U("div", {
                    key: 0,
                    class: "dp__calendar",
                    role: "grid",
                    "aria-label": (p = f(o).ariaLabels) == null ? void 0 : p.calendarDays
                  }, [
                    (k(!0), U(_e, null, Re(e.mappedDates, (T, b) => (k(), U("div", {
                      class: "dp__calendar_row",
                      role: "row",
                      key: b
                    }, [
                      x.weekNumbers ? (k(), U("div", Uu, [
                        se("div", Ru, Be(e.getWeekNum(T.days)), 1)
                      ])) : S("", !0),
                      (k(!0), U(_e, null, Re(T.days, (N, j) => {
                        var J, y, d;
                        return k(), U("div", {
                          role: "gridcell",
                          class: "dp__calendar_item",
                          ref_for: !0,
                          ref: (M) => ie(M, b, j),
                          key: j + b,
                          "aria-selected": N.classData.dp__active_date || N.classData.dp__range_start || N.classData.dp__range_start,
                          "aria-disabled": N.classData.dp__cell_disabled,
                          "aria-label": (y = (J = f(o).ariaLabels) == null ? void 0 : J.day) == null ? void 0 : y.call(J, N),
                          tabindex: "0",
                          "data-test": N.value,
                          onClick: ht((M) => x.$emit("select-date", N), ["stop", "prevent"]),
                          onKeydown: [
                            fe((M) => x.$emit("select-date", N), ["enter"]),
                            fe((M) => x.$emit("handle-space", N), ["space"])
                          ],
                          onMouseenter: (M) => g(N, b, j),
                          onMouseleave: (M) => C(N)
                        }, [
                          se("div", {
                            class: Me(["dp__cell_inner", N.classData])
                          }, [
                            x.$slots.day && f(te)(N) ? z(x.$slots, "day", {
                              key: 0,
                              day: +N.text,
                              date: N.value
                            }) : S("", !0),
                            x.$slots.day ? S("", !0) : (k(), U(_e, { key: 1 }, [
                              dt(Be(N.text), 1)
                            ], 64)),
                            N.marker && f(te)(N) ? (k(), U("div", {
                              key: 2,
                              class: Me(f(B)(N.marker)),
                              style: Nt(N.marker.color ? { backgroundColor: N.marker.color } : {})
                            }, null, 6)) : S("", !0),
                            f(A)(N.value) ? (k(), U("div", {
                              key: 3,
                              class: "dp__marker_tooltip",
                              style: Nt(v.value)
                            }, [
                              (d = N.marker) != null && d.tooltip ? (k(), U("div", {
                                key: 0,
                                class: "dp__tooltip_content",
                                onClick: P[0] || (P[0] = ht(() => {
                                }, ["stop"]))
                              }, [
                                (k(!0), U(_e, null, Re(N.marker.tooltip, (M, Te) => (k(), U("div", {
                                  key: Te,
                                  class: "dp__tooltip_text"
                                }, [
                                  x.$slots["marker-tooltip"] ? z(x.$slots, "marker-tooltip", {
                                    key: 0,
                                    tooltip: M,
                                    day: N.value
                                  }) : S("", !0),
                                  x.$slots["marker-tooltip"] ? S("", !0) : (k(), U(_e, { key: 1 }, [
                                    se("div", {
                                      class: "dp__tooltip_mark",
                                      style: Nt(M.color ? { backgroundColor: M.color } : {})
                                    }, null, 4),
                                    se("div", null, Be(M.text), 1)
                                  ], 64))
                                ]))), 128)),
                                Wu
                              ])) : S("", !0)
                            ], 4)) : S("", !0)
                          ], 2)
                        ], 40, Eu);
                      }), 128))
                    ]))), 128))
                  ], 8, Iu)) : S("", !0)
                ];
              }),
              _: 3
            }, 8, ["name", "css"])
          ], 10, Nu))
        ], 4)
      ], 2);
    };
  }
}), Bu = ["aria-label", "aria-disabled"], qa = /* @__PURE__ */ nt({
  __name: "ActionIcon",
  props: {
    ariaLabel: { type: String, default: "" },
    disabled: { type: Boolean, default: !1 }
  },
  emits: ["activate", "set-ref"],
  setup(e, { emit: r }) {
    const a = q(null);
    return ot(() => r("set-ref", a)), (t, n) => (k(), U("div", {
      class: "dp__month_year_col_nav",
      onClick: n[0] || (n[0] = (l) => t.$emit("activate")),
      onKeydown: [
        n[1] || (n[1] = fe((l) => t.$emit("activate"), ["enter"])),
        n[2] || (n[2] = fe((l) => t.$emit("activate"), ["space"]))
      ],
      tabindex: "0",
      role: "button",
      "aria-label": e.ariaLabel,
      "aria-disabled": e.disabled,
      ref_key: "elRef",
      ref: a
    }, [
      se("div", {
        class: Me(["dp__inner_nav", { dp__inner_nav_disabled: e.disabled }])
      }, [
        z(t.$slots, "default")
      ], 2)
    ], 40, Bu));
  }
}), Lu = ["onKeydown"], Hu = { class: "dp__selection_grid_header" }, Fu = ["aria-selected", "aria-disabled", "data-test", "onClick", "onKeydown", "onMouseover"], qu = ["aria-label", "onKeydown"], ta = /* @__PURE__ */ nt({
  __name: "SelectionGrid",
  props: {
    items: { type: Array, default: () => [] },
    modelValue: { type: [String, Number], default: null },
    multiModelValue: { type: Array, default: () => [] },
    disabledValues: { type: Array, default: () => [] },
    minValue: { type: [Number, String], default: null },
    maxValue: { type: [Number, String], default: null },
    year: { type: Number, default: 0 },
    skipActive: { type: Boolean, default: !1 },
    headerRefs: { type: Array, default: () => [] },
    skipButtonRef: { type: Boolean, default: !1 },
    monthPicker: { type: Boolean, default: !1 },
    yearPicker: { type: Boolean, default: !1 },
    escClose: { type: Boolean, default: !0 },
    type: { type: String, default: null },
    arrowNavigation: { type: Boolean, default: !1 },
    autoApply: { type: Boolean, default: !1 },
    textInput: { type: Boolean, default: !1 },
    ariaLabels: { type: Object, default: () => ({}) },
    hideNavigation: { type: Array, default: () => [] }
  },
  emits: ["update:model-value", "selected", "toggle", "reset-flow"],
  setup(e, { expose: r, emit: a }) {
    const t = e, { setSelectionGrid: n, buildMultiLevelMatrix: l, setMonthPicker: o } = Tt(), { hideNavigationButtons: u } = et(t), v = q(!1), h = q(null), _ = q(null), m = q([]), s = q(), w = q(null), I = q(0), R = q(null);
    fn(() => {
      h.value = null;
    }), ot(() => {
      Bt().then(() => E()), X(), K(!0);
    }), Ja(() => K(!1));
    const K = (P) => {
      var i;
      t.arrowNavigation && ((i = t.headerRefs) != null && i.length ? o(P) : n(P));
    }, X = () => {
      const P = Ee(_);
      P && (t.textInput || P.focus({ preventScroll: !0 }), v.value = P.clientHeight < P.scrollHeight);
    }, B = V(
      () => ({
        dp__overlay: !0
      })
    ), A = V(() => ({
      dp__overlay_col: !0
    })), H = (P) => t.skipActive ? !1 : P.value === t.modelValue, te = V(() => t.items.map((P) => P.filter((i) => i).map((i) => {
      var p, T, b;
      const N = t.disabledValues.some((J) => J === i.value) || C(i.value), j = (p = t.multiModelValue) != null && p.length ? (T = t.multiModelValue) == null ? void 0 : T.some(
        (J) => Ne(
          J,
          At(
            t.monthPicker ? ea(/* @__PURE__ */ new Date(), i.value) : /* @__PURE__ */ new Date(),
            t.monthPicker ? t.year : i.value
          )
        )
      ) : H(i);
      return {
        ...i,
        className: {
          dp__overlay_cell_active: j,
          dp__overlay_cell: !j,
          dp__overlay_cell_disabled: N,
          dp__overlay_cell_active_disabled: N && j,
          dp__overlay_cell_pad: !0,
          dp__cell_in_between: (b = t.multiModelValue) != null && b.length ? Y(i.value) : !1
        }
      };
    }))), le = V(
      () => ({
        dp__button: !0,
        dp__overlay_action: !0,
        dp__over_action_scroll: v.value,
        dp__button_bottom: t.autoApply
      })
    ), g = V(() => {
      var P, i;
      return {
        dp__overlay_container: !0,
        dp__container_flex: ((P = t.items) == null ? void 0 : P.length) <= 6,
        dp__container_block: ((i = t.items) == null ? void 0 : i.length) > 6
      };
    }), C = (P) => {
      const i = t.maxValue || t.maxValue === 0, p = t.minValue || t.minValue === 0;
      return !i && !p ? !1 : i && p ? +P > +t.maxValue || +P < +t.minValue : i ? +P > +t.maxValue : p ? +P < +t.minValue : !1;
    }, E = () => {
      const P = Ee(h), i = Ee(_), p = Ee(w), T = Ee(R), b = p ? p.getBoundingClientRect().height : 0;
      i && (I.value = i.getBoundingClientRect().height - b), P && T && (T.scrollTop = P.offsetTop - T.offsetTop - (I.value / 2 - P.getBoundingClientRect().height) - b);
    }, Z = (P) => {
      !t.disabledValues.some((i) => i === P) && !C(P) && (a("update:model-value", P), a("selected"));
    }, Y = (P) => {
      const i = t.monthPicker ? t.year : P;
      return ln(
        t.multiModelValue,
        At(
          t.monthPicker ? ea(/* @__PURE__ */ new Date(), s.value || 0) : /* @__PURE__ */ new Date(),
          t.monthPicker ? i : s.value || i
        ),
        At(t.monthPicker ? ea(/* @__PURE__ */ new Date(), P) : /* @__PURE__ */ new Date(), i)
      );
    }, F = () => {
      a("toggle"), a("reset-flow");
    }, ie = () => {
      t.escClose && F();
    }, ue = (P, i, p, T) => {
      P && (i.value === +t.modelValue && !t.disabledValues.includes(i.value) && (h.value = P), t.arrowNavigation && (Array.isArray(m.value[p]) ? m.value[p][T] = P : m.value[p] = [P], x()));
    }, x = () => {
      var P, i;
      const p = (P = t.headerRefs) != null && P.length ? [t.headerRefs].concat(m.value) : m.value.concat([t.skipButtonRef ? [] : [w.value]]);
      l(He(p), (i = t.headerRefs) != null && i.length ? "monthPicker" : "selectionGrid");
    };
    return r({ focusGrid: X }), (P, i) => {
      var p;
      return k(), U("div", {
        ref_key: "gridWrapRef",
        ref: _,
        class: Me(f(B)),
        role: "dialog",
        tabindex: "0",
        onKeydown: fe(ie, ["esc"])
      }, [
        se("div", {
          class: Me(f(g)),
          ref_key: "containerRef",
          ref: R,
          role: "grid",
          style: Nt({ height: `${I.value}px` })
        }, [
          se("div", Hu, [
            z(P.$slots, "header")
          ]),
          P.$slots.overlay ? z(P.$slots, "overlay", { key: 0 }) : (k(!0), U(_e, { key: 1 }, Re(f(te), (T, b) => (k(), U("div", {
            class: "dp__overlay_row",
            key: b,
            role: "row"
          }, [
            (k(!0), U(_e, null, Re(T, (N, j) => (k(), U("div", {
              role: "gridcell",
              class: Me(f(A)),
              key: N.value,
              "aria-selected": N.value === e.modelValue && !e.disabledValues.includes(N.value),
              "aria-disabled": N.className.dp__overlay_cell_disabled,
              ref_for: !0,
              ref: (J) => ue(J, N, b, j),
              tabindex: "0",
              "data-test": N.text,
              onClick: (J) => Z(N.value),
              onKeydown: [
                fe((J) => Z(N.value), ["enter"]),
                fe((J) => Z(N.value), ["space"])
              ],
              onMouseover: (J) => s.value = N.value
            }, [
              se("div", {
                class: Me(N.className)
              }, [
                P.$slots.item ? z(P.$slots, "item", {
                  key: 0,
                  item: N
                }) : S("", !0),
                P.$slots.item ? S("", !0) : (k(), U(_e, { key: 1 }, [
                  dt(Be(N.text), 1)
                ], 64))
              ], 2)
            ], 42, Fu))), 128))
          ]))), 128))
        ], 6),
        P.$slots["button-icon"] ? da((k(), U("div", {
          key: 0,
          role: "button",
          "aria-label": (p = e.ariaLabels) == null ? void 0 : p.toggleOverlay,
          class: Me(f(le)),
          tabindex: "0",
          ref_key: "toggleButton",
          ref: w,
          onClick: F,
          onKeydown: fe(F, ["enter"])
        }, [
          z(P.$slots, "button-icon")
        ], 42, qu)), [
          [ca, !f(u)(e.type)]
        ]) : S("", !0)
      ], 42, Lu);
    };
  }
}), Qu = ["aria-label"], Yr = /* @__PURE__ */ nt({
  __name: "RegularPicker",
  props: {
    ariaLabel: { type: String, default: "" },
    showSelectionGrid: { type: Boolean, default: !1 },
    modelValue: { type: Number, default: null },
    items: { type: Array, default: () => [] },
    disabledValues: { type: Array, default: () => [] },
    minValue: { type: Number, default: null },
    maxValue: { type: Number, default: null },
    slotName: { type: String, default: "" },
    overlaySlot: { type: String, default: "" },
    headerRefs: { type: Array, default: () => [] },
    escClose: { type: Boolean, default: !0 },
    type: { type: String, default: null },
    transitions: { type: [Object, Boolean], default: !1 },
    arrowNavigation: { type: Boolean, default: !1 },
    autoApply: { type: Boolean, default: !1 },
    textInput: { type: Boolean, default: !1 },
    ariaLabels: { type: Object, default: () => ({}) },
    hideNavigation: { type: Array, default: () => [] }
  },
  emits: ["update:model-value", "toggle", "set-ref"],
  setup(e, { emit: r }) {
    const a = e, { transitionName: t, showTransition: n } = Pa(a.transitions), l = q(null);
    return ot(() => r("set-ref", l)), (o, u) => (k(), U(_e, null, [
      se("div", {
        class: "dp__month_year_select",
        onClick: u[0] || (u[0] = (v) => o.$emit("toggle")),
        onKeydown: [
          u[1] || (u[1] = fe((v) => o.$emit("toggle"), ["enter"])),
          u[2] || (u[2] = fe((v) => o.$emit("toggle"), ["space"]))
        ],
        role: "button",
        "aria-label": e.ariaLabel,
        tabindex: "0",
        ref_key: "elRef",
        ref: l
      }, [
        z(o.$slots, "default")
      ], 40, Qu),
      bt(Ft, {
        name: f(t)(e.showSelectionGrid),
        css: f(n)
      }, {
        default: ve(() => [
          e.showSelectionGrid ? (k(), be(ta, Ve({ key: 0 }, {
            modelValue: e.modelValue,
            items: e.items,
            disabledValues: e.disabledValues,
            minValue: e.minValue,
            maxValue: e.maxValue,
            escClose: e.escClose,
            type: e.type,
            arrowNavigation: e.arrowNavigation,
            textInput: e.textInput,
            autoApply: e.autoApply,
            ariaLabels: e.ariaLabels,
            hideNavigation: e.hideNavigation
          }, {
            "header-refs": [],
            "onUpdate:modelValue": u[3] || (u[3] = (v) => o.$emit("update:model-value", v)),
            onToggle: u[4] || (u[4] = (v) => o.$emit("toggle"))
          }), Xe({
            "button-icon": ve(() => [
              o.$slots["calendar-icon"] ? z(o.$slots, "calendar-icon", { key: 0 }) : S("", !0),
              o.$slots["calendar-icon"] ? S("", !0) : (k(), be(f(Ca), { key: 1 }))
            ]),
            _: 2
          }, [
            o.$slots[e.slotName] ? {
              name: "item",
              fn: ve(({ item: v }) => [
                z(o.$slots, e.slotName, { item: v })
              ]),
              key: "0"
            } : void 0,
            o.$slots[e.overlaySlot] ? {
              name: "overlay",
              fn: ve(() => [
                z(o.$slots, e.overlaySlot)
              ]),
              key: "1"
            } : void 0,
            o.$slots[`${e.overlaySlot}-header`] ? {
              name: "header",
              fn: ve(() => [
                z(o.$slots, `${e.overlaySlot}-header`)
              ]),
              key: "2"
            } : void 0
          ]), 1040)) : S("", !0)
        ]),
        _: 3
      }, 8, ["name", "css"])
    ], 64));
  }
}), zu = { class: "dp__month_year_row" }, Gu = { class: "dp__month_year_wrap" }, Ku = { class: "dp__month_picker_header" }, Zu = ["aria-label"], ju = ["aria-label"], Xu = ["aria-label"], Ju = /* @__PURE__ */ nt({
  __name: "MonthYearPicker",
  props: {
    month: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    instance: { type: Number, default: 0 },
    years: { type: Array, default: () => [] },
    months: { type: Array, default: () => [] },
    internalModelValue: { type: [Date, Array], default: null },
    ...xt
  },
  emits: ["update-month-year", "month-year-select", "mount", "reset-flow", "overlay-closed"],
  setup(e, { expose: r, emit: a }) {
    const t = e, { defaults: n } = et(t), { transitionName: l, showTransition: o } = Pa(n.value.transitions), { buildMatrix: u } = Tt(), { handleMonthYearChange: v, isDisabled: h, updateMonthYear: _ } = hu(t, a), m = q(!1), s = q(!1), w = q([null, null, null, null]), I = q(null), R = q(null), K = q(null);
    ot(() => {
      a("mount");
    });
    const X = (d) => ({
      get: () => t[d],
      set: (M) => {
        const Te = d === "month" ? "year" : "month";
        a("update-month-year", { [d]: M, [Te]: t[Te] }), a("month-year-select", d === "year"), d === "month" ? b(!0) : N(!0);
      }
    }), B = V(X("month")), A = V(X("year")), H = (d) => {
      const M = $e($(d));
      return t.year === M;
    }, te = V(() => t.monthPicker ? Array.isArray(t.disabledDates) ? t.disabledDates.map((d) => $(d)).filter((d) => H(d)).map((d) => Oe(d)) : [] : []), le = V(() => (d) => {
      const M = d === "month";
      return {
        showSelectionGrid: (M ? m : s).value,
        items: (M ? P : i).value,
        disabledValues: n.value.filters[M ? "months" : "years"].concat(te.value),
        minValue: (M ? Z : C).value,
        maxValue: (M ? Y : E).value,
        headerRefs: M && t.monthPicker ? [I.value, R.value, K.value] : [],
        escClose: t.escClose,
        transitions: n.value.transitions,
        ariaLabels: n.value.ariaLabels,
        textInput: t.textInput,
        autoApply: t.autoApply,
        arrowNavigation: t.arrowNavigation,
        hideNavigation: t.hideNavigation
      };
    }), g = V(() => (d) => ({
      month: t.month,
      year: t.year,
      items: d === "month" ? t.months : t.years,
      instance: t.instance,
      updateMonthYear: _,
      toggle: d === "month" ? b : N
    })), C = V(() => t.minDate ? $e($(t.minDate)) : null), E = V(() => t.maxDate ? $e($(t.maxDate)) : null), Z = V(() => {
      if (t.minDate && C.value) {
        if (C.value > t.year)
          return 12;
        if (C.value === t.year)
          return Oe($(t.minDate));
      }
      return null;
    }), Y = V(() => t.maxDate && E.value ? E.value < t.year ? -1 : E.value === t.year ? Oe($(t.maxDate)) : null : null), F = V(() => t.range && t.internalModelValue && (t.monthPicker || t.yearPicker) ? t.internalModelValue : []), ie = (d) => d.reverse(), ue = (d, M = !1) => {
      const Te = [], Le = (Ye) => M ? ie(Ye) : Ye;
      for (let Ye = 0; Ye < d.length; Ye += 3) {
        const Ze = [d[Ye], d[Ye + 1], d[Ye + 2]];
        Te.push(Le(Ze));
      }
      return M ? Te.reverse() : Te;
    }, x = V(() => t.months.find((M) => M.value === t.month) || { text: "", value: 0 }), P = V(() => ue(t.months)), i = V(() => ue(t.years, t.reverseYears)), p = V(() => n.value.multiCalendars ? t.multiCalendarsSolo ? !0 : t.instance === 0 : !0), T = V(() => n.value.multiCalendars ? t.multiCalendarsSolo ? !0 : t.instance === n.value.multiCalendars - 1 : !0), b = (d = !1) => {
      j(d), m.value = !m.value, m.value || a("overlay-closed");
    }, N = (d = !1) => {
      j(d), s.value = !s.value, s.value || a("overlay-closed");
    }, j = (d) => {
      d || a("reset-flow");
    }, J = (d = !1) => {
      h.value(d) || a("update-month-year", {
        year: d ? t.year + 1 : t.year - 1,
        month: t.month,
        fromNav: !0
      });
    }, y = (d, M) => {
      t.arrowNavigation && (w.value[M] = Ee(d), u(w.value, "monthYear"));
    };
    return r({
      toggleMonthPicker: b,
      toggleYearPicker: N
    }), (d, M) => {
      var Te, Le, Ye, Ze, rt;
      return k(), U("div", zu, [
        d.$slots["month-year"] ? z(d.$slots, "month-year", Je(Ve({ key: 0 }, { month: e.month, year: e.year, months: e.months, years: e.years, updateMonthYear: f(_), handleMonthYearChange: f(v), instance: e.instance }))) : (k(), U(_e, { key: 1 }, [
          !d.monthPicker && !d.yearPicker ? (k(), U(_e, { key: 0 }, [
            f(p) && !d.vertical ? (k(), be(qa, {
              key: 0,
              "aria-label": (Te = f(n).ariaLabels) == null ? void 0 : Te.prevMonth,
              disabled: f(h)(!1),
              onActivate: M[0] || (M[0] = (de) => f(v)(!1)),
              onSetRef: M[1] || (M[1] = (de) => y(de, 0))
            }, {
              default: ve(() => [
                d.$slots["arrow-left"] ? z(d.$slots, "arrow-left", { key: 0 }) : S("", !0),
                d.$slots["arrow-left"] ? S("", !0) : (k(), be(f(wr), { key: 1 }))
              ]),
              _: 3
            }, 8, ["aria-label", "disabled"])) : S("", !0),
            se("div", Gu, [
              bt(Yr, Ve({
                type: "month",
                "slot-name": "month-overlay-val",
                "overlay-slot": "overlay-month",
                "aria-label": (Le = f(n).ariaLabels) == null ? void 0 : Le.openMonthsOverlay,
                modelValue: f(B),
                "onUpdate:modelValue": M[2] || (M[2] = (de) => $t(B) ? B.value = de : null)
              }, f(le)("month"), {
                onToggle: b,
                onSetRef: M[3] || (M[3] = (de) => y(de, 1))
              }), Xe({
                default: ve(() => [
                  d.$slots.month ? z(d.$slots, "month", Je(Ve({ key: 0 }, f(x)))) : S("", !0),
                  d.$slots.month ? S("", !0) : (k(), U(_e, { key: 1 }, [
                    dt(Be(f(x).text), 1)
                  ], 64))
                ]),
                _: 2
              }, [
                d.$slots["calendar-icon"] ? {
                  name: "calendar-icon",
                  fn: ve(() => [
                    z(d.$slots, "calendar-icon")
                  ]),
                  key: "0"
                } : void 0,
                d.$slots["month-overlay-value"] ? {
                  name: "month-overlay-val",
                  fn: ve(({ item: de }) => [
                    z(d.$slots, "month-overlay-value", {
                      text: de.text,
                      value: de.value
                    })
                  ]),
                  key: "1"
                } : void 0,
                d.$slots["month-overlay"] ? {
                  name: "overlay-month",
                  fn: ve(() => [
                    z(d.$slots, "month-overlay", Je(yt(f(g)("month"))))
                  ]),
                  key: "2"
                } : void 0,
                d.$slots["month-overlay-header"] ? {
                  name: "overlay-month-header",
                  fn: ve(() => [
                    z(d.$slots, "month-overlay-header", { toggle: b })
                  ]),
                  key: "3"
                } : void 0
              ]), 1040, ["aria-label", "modelValue"]),
              bt(Yr, Ve({
                type: "year",
                "slot-name": "year-overlay-val",
                "overlay-slot": "overlay-year",
                "aria-label": (Ye = f(n).ariaLabels) == null ? void 0 : Ye.openYearsOverlay,
                modelValue: f(A),
                "onUpdate:modelValue": M[4] || (M[4] = (de) => $t(A) ? A.value = de : null)
              }, f(le)("year"), {
                onToggle: N,
                onSetRef: M[5] || (M[5] = (de) => y(de, 2))
              }), Xe({
                default: ve(() => [
                  d.$slots.year ? z(d.$slots, "year", {
                    key: 0,
                    year: e.year
                  }) : S("", !0),
                  d.$slots.year ? S("", !0) : (k(), U(_e, { key: 1 }, [
                    dt(Be(e.year), 1)
                  ], 64))
                ]),
                _: 2
              }, [
                d.$slots["calendar-icon"] ? {
                  name: "calendar-icon",
                  fn: ve(() => [
                    z(d.$slots, "calendar-icon")
                  ]),
                  key: "0"
                } : void 0,
                d.$slots["year-overlay-value"] ? {
                  name: "year-overlay-val",
                  fn: ve(({ item: de }) => [
                    z(d.$slots, "year-overlay-value", {
                      text: de.text,
                      value: de.value
                    })
                  ]),
                  key: "1"
                } : void 0,
                d.$slots["year-overlay"] ? {
                  name: "overlay-year",
                  fn: ve(() => [
                    z(d.$slots, "year-overlay", Je(yt(f(g)("year"))))
                  ]),
                  key: "2"
                } : void 0,
                d.$slots["year-overlay-header"] ? {
                  name: "overlay-year-header",
                  fn: ve(() => [
                    z(d.$slots, "year-overlay-header", { toggle: N })
                  ]),
                  key: "3"
                } : void 0
              ]), 1040, ["aria-label", "modelValue"])
            ]),
            f(p) && d.vertical ? (k(), be(qa, {
              key: 1,
              "aria-label": (Ze = f(n).ariaLabels) == null ? void 0 : Ze.prevMonth,
              disabled: f(h)(!1),
              onActivate: M[6] || (M[6] = (de) => f(v)(!1))
            }, {
              default: ve(() => [
                d.$slots["arrow-up"] ? z(d.$slots, "arrow-up", { key: 0 }) : S("", !0),
                d.$slots["arrow-up"] ? S("", !0) : (k(), be(f(nn), { key: 1 }))
              ]),
              _: 3
            }, 8, ["aria-label", "disabled"])) : S("", !0),
            f(T) ? (k(), be(qa, {
              key: 2,
              ref: "rightIcon",
              disabled: f(h)(!0),
              "aria-label": (rt = f(n).ariaLabels) == null ? void 0 : rt.nextMonth,
              onActivate: M[7] || (M[7] = (de) => f(v)(!0)),
              onSetRef: M[8] || (M[8] = (de) => y(de, 3))
            }, {
              default: ve(() => [
                d.$slots[d.vertical ? "arrow-down" : "arrow-right"] ? z(d.$slots, d.vertical ? "arrow-down" : "arrow-right", { key: 0 }) : S("", !0),
                d.$slots[d.vertical ? "arrow-down" : "arrow-right"] ? S("", !0) : (k(), be(sa(d.vertical ? f(on) : f(br)), { key: 1 }))
              ]),
              _: 3
            }, 8, ["disabled", "aria-label"])) : S("", !0)
          ], 64)) : S("", !0),
          d.monthPicker ? (k(), be(ta, Ve({ key: 1 }, f(le)("month"), {
            "skip-active": d.range,
            year: e.year,
            "multi-model-value": f(F),
            "month-picker": "",
            modelValue: f(B),
            "onUpdate:modelValue": M[17] || (M[17] = (de) => $t(B) ? B.value = de : null),
            onToggle: b,
            onSelected: M[18] || (M[18] = (de) => d.$emit("overlay-closed"))
          }), Xe({
            header: ve(() => {
              var de, ce, xe;
              return [
                se("div", Ku, [
                  se("div", {
                    class: "dp__month_year_col_nav",
                    tabindex: "0",
                    ref_key: "mpPrevIconRef",
                    ref: I,
                    onClick: M[9] || (M[9] = (he) => J(!1)),
                    onKeydown: M[10] || (M[10] = fe((he) => J(!1), ["enter"]))
                  }, [
                    se("div", {
                      class: Me(["dp__inner_nav", { dp__inner_nav_disabled: f(h)(!1) }]),
                      role: "button",
                      "aria-label": (de = f(n).ariaLabels) == null ? void 0 : de.prevMonth
                    }, [
                      d.$slots["arrow-left"] ? z(d.$slots, "arrow-left", { key: 0 }) : S("", !0),
                      d.$slots["arrow-left"] ? S("", !0) : (k(), be(f(wr), { key: 1 }))
                    ], 10, Zu)
                  ], 544),
                  se("div", {
                    class: "dp__pointer",
                    role: "button",
                    ref_key: "mpYearButtonRef",
                    ref: R,
                    "aria-label": (ce = f(n).ariaLabels) == null ? void 0 : ce.openYearsOverlay,
                    tabindex: "0",
                    onClick: M[11] || (M[11] = () => N(!1)),
                    onKeydown: M[12] || (M[12] = fe(() => N(!1), ["enter"]))
                  }, [
                    d.$slots.year ? z(d.$slots, "year", {
                      key: 0,
                      year: e.year
                    }) : S("", !0),
                    d.$slots.year ? S("", !0) : (k(), U(_e, { key: 1 }, [
                      dt(Be(e.year), 1)
                    ], 64))
                  ], 40, ju),
                  se("div", {
                    class: "dp__month_year_col_nav",
                    tabindex: "0",
                    ref_key: "mpNextIconRef",
                    ref: K,
                    onClick: M[13] || (M[13] = (he) => J(!0)),
                    onKeydown: M[14] || (M[14] = fe((he) => J(!0), ["enter"]))
                  }, [
                    se("div", {
                      class: Me(["dp__inner_nav", { dp__inner_nav_disabled: f(h)(!0) }]),
                      role: "button",
                      "aria-label": (xe = f(n).ariaLabels) == null ? void 0 : xe.nextMonth
                    }, [
                      d.$slots["arrow-right"] ? z(d.$slots, "arrow-right", { key: 0 }) : S("", !0),
                      d.$slots["arrow-right"] ? S("", !0) : (k(), be(f(br), { key: 1 }))
                    ], 10, Xu)
                  ], 544)
                ]),
                bt(Ft, {
                  name: f(l)(s.value),
                  css: f(o)
                }, {
                  default: ve(() => [
                    s.value ? (k(), be(ta, Ve({ key: 0 }, f(le)("year"), {
                      modelValue: f(A),
                      "onUpdate:modelValue": M[15] || (M[15] = (he) => $t(A) ? A.value = he : null),
                      onToggle: N,
                      onSelected: M[16] || (M[16] = (he) => d.$emit("overlay-closed"))
                    }), Xe({
                      "button-icon": ve(() => [
                        d.$slots["calendar-icon"] ? z(d.$slots, "calendar-icon", { key: 0 }) : S("", !0),
                        d.$slots["calendar-icon"] ? S("", !0) : (k(), be(f(Ca), { key: 1 }))
                      ]),
                      _: 2
                    }, [
                      d.$slots["year-overlay-value"] ? {
                        name: "item",
                        fn: ve(({ item: he }) => [
                          z(d.$slots, "year-overlay-value", {
                            text: he.text,
                            value: he.value
                          })
                        ]),
                        key: "0"
                      } : void 0
                    ]), 1040, ["modelValue"])) : S("", !0)
                  ]),
                  _: 3
                }, 8, ["name", "css"])
              ];
            }),
            _: 2
          }, [
            d.$slots["month-overlay-value"] ? {
              name: "item",
              fn: ve(({ item: de }) => [
                z(d.$slots, "month-overlay-value", {
                  text: de.text,
                  value: de.value
                })
              ]),
              key: "0"
            } : void 0
          ]), 1040, ["skip-active", "year", "multi-model-value", "modelValue"])) : S("", !0),
          d.yearPicker ? (k(), be(ta, Ve({ key: 2 }, f(le)("year"), {
            modelValue: f(A),
            "onUpdate:modelValue": M[19] || (M[19] = (de) => $t(A) ? A.value = de : null),
            "multi-model-value": f(F),
            "skip-active": d.range,
            "skip-button-ref": "",
            "year-picker": "",
            onToggle: N,
            onSelected: M[20] || (M[20] = (de) => d.$emit("overlay-closed"))
          }), Xe({ _: 2 }, [
            d.$slots["year-overlay-value"] ? {
              name: "item",
              fn: ve(({ item: de }) => [
                z(d.$slots, "year-overlay-value", {
                  text: de.text,
                  value: de.value
                })
              ]),
              key: "0"
            } : void 0
          ]), 1040, ["modelValue", "multi-model-value", "skip-active"])) : S("", !0)
        ], 64))
      ]);
    };
  }
}), es = {
  key: 0,
  class: "dp__time_input"
}, ts = ["aria-label", "onKeydown", "onClick"], as = ["aria-label", "data-test", "onKeydown", "onClick"], rs = ["aria-label", "onKeydown", "onClick"], ns = { key: 0 }, os = ["aria-label", "onKeydown"], ls = /* @__PURE__ */ nt({
  __name: "TimeInput",
  props: {
    hours: { type: Number, default: 0 },
    minutes: { type: Number, default: 0 },
    seconds: { type: Number, default: 0 },
    closeTimePickerBtn: { type: Object, default: null },
    order: { type: Number, default: 0 },
    ...xt
  },
  emits: [
    "set-hours",
    "set-minutes",
    "update:hours",
    "update:minutes",
    "update:seconds",
    "reset-flow",
    "mounted",
    "overlay-closed"
  ],
  setup(e, { expose: r, emit: a }) {
    const t = e, { setTimePickerElements: n, setTimePickerBackRef: l } = Tt(), { defaults: o } = et(t), { transitionName: u, showTransition: v } = Pa(o.value.transitions), h = aa({
      hours: !1,
      minutes: !1,
      seconds: !1
    }), _ = q("AM"), m = q(null), s = q([]);
    ot(() => {
      a("mounted");
    });
    const w = V(() => (i) => !!(t.maxTime && t.maxTime[i] && t.maxTime[i] < t[i] + +t[`${i}Increment`])), I = V(() => (i) => !!(t.minTime && t.minTime[i] && t.minTime[i] > t[i] - +t[`${i}Increment`])), R = (i, p) => Ir(ze($(), i), p), K = (i, p) => Vl(ze($(), i), p), X = V(
      () => ({
        dp__time_col: !0,
        dp__time_col_reg: !t.enableSeconds && t.is24,
        dp__time_col_reg_with_button: !t.enableSeconds && !t.is24,
        dp__time_col_sec: t.enableSeconds && t.is24,
        dp__time_col_sec_with_button: t.enableSeconds && !t.is24
      })
    ), B = V(() => {
      const i = [{ type: "hours" }, { type: "", separator: !0 }, { type: "minutes" }];
      return t.enableSeconds ? i.concat([{ type: "", separator: !0 }, { type: "seconds" }]) : i;
    }), A = V(() => B.value.filter((i) => !i.separator)), H = V(() => (i) => {
      if (i === "hours") {
        const p = F(t.hours);
        return { text: p < 10 ? `0${p}` : `${p}`, value: p };
      }
      return { text: t[i] < 10 ? `0${t[i]}` : `${t[i]}`, value: t[i] };
    }), te = (i) => {
      const p = t.is24 ? 24 : 12, T = i === "hours" ? p : 60, b = +t[`${i}GridIncrement`], N = i === "hours" && !t.is24 ? b : 0, j = [];
      for (let J = N; J < T; J += b)
        j.push({ value: J, text: J < 10 ? `0${J}` : `${J}` });
      return i === "hours" && !t.is24 && j.push({ value: 0, text: "12" }), ru(j);
    }, le = (i, p) => {
      const T = t.minTime && t.minTime[p], b = t.maxTime && t.maxTime[p];
      return T && b ? i < T || i > b : T ? i < T : b ? i > b : !1;
    }, g = V(() => (i) => te(i).flat().map((p) => p.value).filter((p) => le(p, i))), C = (i) => t[`no${i[0].toUpperCase() + i.slice(1)}Overlay`], E = (i) => {
      C(i) || (h[i] = !h[i], h[i] || a("overlay-closed"));
    }, Z = (i) => i === "hours" ? ft : i === "minutes" ? pt : Ht, Y = (i, p = !0) => {
      const T = p ? R : K;
      (p ? w.value(i) : I.value(i)) || a(
        `update:${i}`,
        Z(i)(T({ [i]: +t[i] }, { [i]: +t[`${i}Increment`] }))
      );
    }, F = (i) => t.is24 ? i : (i >= 12 ? _.value = "PM" : _.value = "AM", iu(i)), ie = () => {
      _.value === "PM" ? (_.value = "AM", a("update:hours", t.hours - 12)) : (_.value = "PM", a("update:hours", t.hours + 12));
    }, ue = (i) => {
      h[i] = !0;
    }, x = (i, p, T) => {
      if (i && t.arrowNavigation) {
        Array.isArray(s.value[p]) ? s.value[p][T] = i : s.value[p] = [i];
        const b = s.value.reduce(
          (N, j) => j.map((J, y) => [...N[y] || [], j[y]]),
          []
        );
        l(t.closeTimePickerBtn), m.value && (b[1] = b[1].concat(m.value)), n(b, t.order);
      }
    }, P = (i, p) => i === "hours" && !t.is24 ? a(`update:${i}`, _.value === "PM" ? p + 12 : p) : a(`update:${i}`, p);
    return r({ openChildCmp: ue }), (i, p) => {
      var T;
      return i.disabled ? S("", !0) : (k(), U("div", es, [
        (k(!0), U(_e, null, Re(f(B), (b, N) => {
          var j, J, y;
          return k(), U("div", {
            key: N,
            class: Me(f(X))
          }, [
            b.separator ? (k(), U(_e, { key: 0 }, [
              dt(" : ")
            ], 64)) : (k(), U(_e, { key: 1 }, [
              se("div", {
                class: Me({
                  dp__inc_dec_button: !0,
                  dp__inc_dec_button_disabled: f(w)(b.type)
                }),
                role: "button",
                "data-test": "time-inc-btn",
                "aria-label": (j = f(o).ariaLabels) == null ? void 0 : j.incrementValue(b.type),
                tabindex: "0",
                onKeydown: [
                  fe((d) => Y(b.type), ["enter"]),
                  fe((d) => Y(b.type), ["space"])
                ],
                onClick: (d) => Y(b.type),
                ref_for: !0,
                ref: (d) => x(d, N, 0)
              }, [
                i.$slots["arrow-up"] ? z(i.$slots, "arrow-up", { key: 0 }) : S("", !0),
                i.$slots["arrow-up"] ? S("", !0) : (k(), be(f(nn), { key: 1 }))
              ], 42, ts),
              se("div", {
                role: "button",
                "aria-label": (J = f(o).ariaLabels) == null ? void 0 : J.openTpOverlay(b.type),
                class: Me(C(b.type) ? "" : "dp__time_display"),
                tabindex: "0",
                "data-test": `${b.type}-toggle-overlay-btn`,
                onKeydown: [
                  fe((d) => E(b.type), ["enter"]),
                  fe((d) => E(b.type), ["space"])
                ],
                onClick: (d) => E(b.type),
                ref_for: !0,
                ref: (d) => x(d, N, 1)
              }, [
                i.$slots[b.type] ? z(i.$slots, b.type, {
                  key: 0,
                  text: f(H)(b.type).text,
                  value: f(H)(b.type).value
                }) : S("", !0),
                i.$slots[b.type] ? S("", !0) : (k(), U(_e, { key: 1 }, [
                  dt(Be(f(H)(b.type).text), 1)
                ], 64))
              ], 42, as),
              se("div", {
                class: Me({
                  dp__inc_dec_button: !0,
                  dp__inc_dec_button_disabled: f(I)(b.type)
                }),
                role: "button",
                "data-test": "time-dec-btn",
                "aria-label": (y = f(o).ariaLabels) == null ? void 0 : y.decrementValue(b.type),
                tabindex: "0",
                onKeydown: [
                  fe((d) => Y(b.type, !1), ["enter"]),
                  fe((d) => Y(b.type, !1), ["space"])
                ],
                onClick: (d) => Y(b.type, !1),
                ref_for: !0,
                ref: (d) => x(d, N, 2)
              }, [
                i.$slots["arrow-down"] ? z(i.$slots, "arrow-down", { key: 0 }) : S("", !0),
                i.$slots["arrow-down"] ? S("", !0) : (k(), be(f(on), { key: 1 }))
              ], 42, rs)
            ], 64))
          ], 2);
        }), 128)),
        i.is24 ? S("", !0) : (k(), U("div", ns, [
          i.$slots["am-pm-button"] ? z(i.$slots, "am-pm-button", {
            key: 0,
            toggle: ie,
            value: _.value
          }) : S("", !0),
          i.$slots["am-pm-button"] ? S("", !0) : (k(), U("button", {
            key: 1,
            ref_key: "amPmButton",
            ref: m,
            type: "button",
            class: "dp__pm_am_button",
            role: "button",
            "aria-label": (T = f(o).ariaLabels) == null ? void 0 : T.amPmButton,
            tabindex: "0",
            onClick: ie,
            onKeydown: [
              fe(ht(ie, ["prevent"]), ["enter"]),
              fe(ht(ie, ["prevent"]), ["space"])
            ]
          }, Be(_.value), 41, os))
        ])),
        (k(!0), U(_e, null, Re(f(A), (b, N) => (k(), be(Ft, {
          key: N,
          name: f(u)(h[b.type]),
          css: f(v)
        }, {
          default: ve(() => [
            h[b.type] ? (k(), be(ta, {
              key: 0,
              items: te(b.type),
              "disabled-values": f(o).filters.times[b.type].concat(f(g)(b.type)),
              "esc-close": i.escClose,
              "aria-labels": f(o).ariaLabels,
              "hide-navigation": i.hideNavigation,
              "onUpdate:modelValue": (j) => P(b.type, j),
              onSelected: (j) => E(b.type),
              onToggle: (j) => E(b.type),
              onResetFlow: p[0] || (p[0] = (j) => i.$emit("reset-flow")),
              type: b.type
            }, Xe({
              "button-icon": ve(() => [
                i.$slots["clock-icon"] ? z(i.$slots, "clock-icon", { key: 0 }) : S("", !0),
                i.$slots["clock-icon"] ? S("", !0) : (k(), be(f(rn), { key: 1 }))
              ]),
              _: 2
            }, [
              i.$slots[`${b.type}-overlay-value`] ? {
                name: "item",
                fn: ve(({ item: j }) => [
                  z(i.$slots, `${b.type}-overlay-value`, {
                    text: j.text,
                    value: j.value
                  })
                ]),
                key: "0"
              } : void 0
            ]), 1032, ["items", "disabled-values", "esc-close", "aria-labels", "hide-navigation", "onUpdate:modelValue", "onSelected", "onToggle", "type"])) : S("", !0)
          ]),
          _: 2
        }, 1032, ["name", "css"]))), 128))
      ]));
    };
  }
}), is = ["aria-label"], us = { class: "dp__overlay_container dp__container_flex dp__time_picker_overlay_container" }, ss = {
  key: 1,
  class: "dp__overlay_row"
}, ds = ["aria-label"], cs = /* @__PURE__ */ nt({
  __name: "TimePicker",
  props: {
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    seconds: { type: [Number, Array], default: 0 },
    internalModelValue: { type: [Date, Array], default: null },
    ...xt
  },
  emits: [
    "update:hours",
    "update:minutes",
    "update:seconds",
    "mount",
    "reset-flow",
    "overlay-closed"
  ],
  setup(e, { expose: r, emit: a }) {
    const t = e, { buildMatrix: n, setTimePicker: l } = Tt(), o = Xa(), { hideNavigationButtons: u, defaults: v } = et(t), { transitionName: h, showTransition: _ } = Pa(v.value.transitions), m = q(null), s = q(null), w = q([]), I = q(null);
    ot(() => {
      a("mount"), !t.timePicker && t.arrowNavigation ? n([Ee(m.value)], "time") : l(!0, t.timePicker);
    });
    const R = V(() => t.range && t.modelAuto ? cn(t.internalModelValue) : !0), K = q(!1), X = (Y) => ({
      hours: Array.isArray(t.hours) ? t.hours[Y] : t.hours,
      minutes: Array.isArray(t.minutes) ? t.minutes[Y] : t.minutes,
      seconds: Array.isArray(t.seconds) ? t.seconds[Y] : t.seconds
    }), B = V(() => {
      const Y = [];
      if (t.range)
        for (let F = 0; F < 2; F++)
          Y.push(X(F));
      else
        Y.push(X(0));
      return Y;
    }), A = (Y, F = !1, ie = "") => {
      F || a("reset-flow"), K.value = Y, t.arrowNavigation && (l(Y), Y || a("overlay-closed")), Bt(() => {
        ie !== "" && w.value[0] && w.value[0].openChildCmp(ie);
      });
    }, H = V(() => ({
      dp__button: !0,
      dp__button_bottom: t.autoApply
    })), te = St(o, "timePicker"), le = (Y, F, ie) => t.range ? F === 0 ? [Y, B.value[1][ie]] : [B.value[0][ie], Y] : Y, g = (Y) => {
      a("update:hours", Y);
    }, C = (Y) => {
      a("update:minutes", Y);
    }, E = (Y) => {
      a("update:seconds", Y);
    }, Z = () => {
      I.value && t.arrowNavigation && I.value.focus({ preventScroll: !0 });
    };
    return r({ toggleTimePicker: A }), (Y, F) => {
      var ie;
      return k(), U("div", null, [
        Y.timePicker ? S("", !0) : da((k(), U("div", {
          key: 0,
          class: Me(f(H)),
          role: "button",
          "aria-label": (ie = f(v).ariaLabels) == null ? void 0 : ie.openTimePicker,
          tabindex: "0",
          "data-test": "open-time-picker-btn",
          ref_key: "openTimePickerBtn",
          ref: m,
          onKeydown: [
            F[0] || (F[0] = fe((ue) => A(!0), ["enter"])),
            F[1] || (F[1] = fe((ue) => A(!0), ["space"]))
          ],
          onClick: F[2] || (F[2] = (ue) => A(!0))
        }, [
          Y.$slots["clock-icon"] ? z(Y.$slots, "clock-icon", { key: 0 }) : S("", !0),
          Y.$slots["clock-icon"] ? S("", !0) : (k(), be(f(rn), { key: 1 }))
        ], 42, is)), [
          [ca, !f(u)("time")]
        ]),
        bt(Ft, {
          name: f(h)(K.value),
          css: f(_)
        }, {
          default: ve(() => {
            var ue;
            return [
              K.value || Y.timePicker ? (k(), U("div", {
                key: 0,
                class: "dp__overlay",
                ref_key: "overlayRef",
                ref: I,
                tabindex: "0"
              }, [
                se("div", us, [
                  Y.$slots["time-picker-overlay"] ? z(Y.$slots, "time-picker-overlay", {
                    key: 0,
                    hours: e.hours,
                    minutes: e.minutes,
                    seconds: e.seconds,
                    setHours: g,
                    setMinutes: C,
                    setSeconds: E
                  }) : S("", !0),
                  Y.$slots["time-picker-overlay"] ? S("", !0) : (k(), U("div", ss, [
                    (k(!0), U(_e, null, Re(f(B), (x, P) => da((k(), be(ls, Ve({ key: P }, {
                      ...Y.$props,
                      order: P,
                      hours: x.hours,
                      minutes: x.minutes,
                      seconds: x.seconds,
                      closeTimePickerBtn: s.value,
                      disabled: P === 0 ? Y.fixedStart : Y.fixedEnd
                    }, {
                      ref_for: !0,
                      ref_key: "timeInputRefs",
                      ref: w,
                      "onUpdate:hours": (i) => g(le(i, P, "hours")),
                      "onUpdate:minutes": (i) => C(le(i, P, "minutes")),
                      "onUpdate:seconds": (i) => E(le(i, P, "seconds")),
                      onMounted: Z,
                      onOverlayClosed: Z
                    }), Xe({ _: 2 }, [
                      Re(f(te), (i, p) => ({
                        name: i,
                        fn: ve((T) => [
                          z(Y.$slots, i, Je(yt(T)))
                        ])
                      }))
                    ]), 1040, ["onUpdate:hours", "onUpdate:minutes", "onUpdate:seconds"])), [
                      [ca, P === 0 ? !0 : f(R)]
                    ])), 128))
                  ])),
                  Y.timePicker ? S("", !0) : da((k(), U("div", {
                    key: 2,
                    ref_key: "closeTimePickerBtn",
                    ref: s,
                    class: Me(f(H)),
                    role: "button",
                    "aria-label": (ue = f(v).ariaLabels) == null ? void 0 : ue.closeTimePicker,
                    tabindex: "0",
                    onKeydown: [
                      F[3] || (F[3] = fe((x) => A(!1), ["enter"])),
                      F[4] || (F[4] = fe((x) => A(!1), ["space"]))
                    ],
                    onClick: F[5] || (F[5] = (x) => A(!1))
                  }, [
                    Y.$slots["calendar-icon"] ? z(Y.$slots, "calendar-icon", { key: 0 }) : S("", !0),
                    Y.$slots["calendar-icon"] ? S("", !0) : (k(), be(f(Ca), { key: 1 }))
                  ], 42, ds)), [
                    [ca, !f(u)("time")]
                  ])
                ])
              ], 512)) : S("", !0)
            ];
          }),
          _: 3
        }, 8, ["name", "css"])
      ]);
    };
  }
}), vs = (e, r) => {
  const { isDisabled: a, matchDate: t, getWeekFromDate: n } = et(r), l = q(null), o = q($()), u = (i) => {
    !i.current && r.hideOffsetDates || (l.value = i.value);
  }, v = () => {
    l.value = null;
  }, h = (i) => Array.isArray(e.value) && r.range && e.value[0] && l.value ? i ? Ke(l.value, e.value[0]) : Fe(l.value, e.value[0]) : !0, _ = (i, p) => {
    const T = () => e.value ? p ? e.value[0] || null : e.value[1] : null, b = e.value && Array.isArray(e.value) ? T() : null;
    return Ne($(i.value), b);
  }, m = (i) => {
    const p = Array.isArray(e.value) ? e.value[0] : null;
    return i ? !Fe(l.value || null, p) : !0;
  }, s = (i, p = !0) => (r.range || r.weekPicker) && Array.isArray(e.value) ? r.hideOffsetDates && !i.current ? !1 : Ne($(i.value), e.value[p ? 0 : 1]) : r.range ? _(i, p) && m(p) || Ne(i.value, Array.isArray(e.value) ? e.value[0] : null) && h(p) : !1, w = (i, p, T) => Array.isArray(e.value) && e.value[0] && e.value.length === 1 ? i ? !1 : T ? Ke(e.value[0], p.value) : Fe(e.value[0], p.value) : !1, I = (i) => !e.value || r.hideOffsetDates && !i.current ? !1 : r.range ? r.modelAuto && Array.isArray(e.value) ? Ne(i.value, e.value[0] ? e.value[0] : o.value) : !1 : r.multiDates && Array.isArray(e.value) ? e.value.some((p) => Ne(p, i.value)) : Ne(i.value, e.value ? e.value : o.value), R = (i) => {
    if (r.autoRange || r.weekPicker) {
      if (l.value) {
        if (r.hideOffsetDates && !i.current)
          return !1;
        const p = gt(l.value, +r.autoRange), T = n($(l.value));
        return r.weekPicker ? Ne(T[1], $(i.value)) : Ne(p, $(i.value));
      }
      return !1;
    }
    return !1;
  }, K = (i) => {
    if (r.autoRange || r.weekPicker) {
      if (l.value) {
        const p = gt(l.value, +r.autoRange);
        if (r.hideOffsetDates && !i.current)
          return !1;
        const T = n($(l.value));
        return r.weekPicker ? Ke(i.value, T[0]) && Fe(i.value, T[1]) : Ke(i.value, l.value) && Fe(i.value, p);
      }
      return !1;
    }
    return !1;
  }, X = (i) => {
    if (r.autoRange || r.weekPicker) {
      if (l.value) {
        if (r.hideOffsetDates && !i.current)
          return !1;
        const p = n($(l.value));
        return r.weekPicker ? Ne(p[0], i.value) : Ne(l.value, i.value);
      }
      return !1;
    }
    return !1;
  }, B = (i) => ln(e.value, l.value, i.value), A = () => r.modelAuto && Array.isArray(r.internalModelValue) ? !!r.internalModelValue[0] : !1, H = () => r.modelAuto ? cn(r.internalModelValue) : !0, te = (i) => {
    if (Array.isArray(e.value) && e.value.length || r.weekPicker)
      return !1;
    const p = r.range ? !s(i) && !s(i, !1) : !0;
    return !a(i.value) && !I(i) && !(!i.current && r.hideOffsetDates) && p;
  }, le = (i) => r.range ? r.modelAuto ? A() && I(i) : !1 : I(i), g = (i) => r.highlight ? t(i.value, r.highlight) : !1, C = (i) => a(i.value) && r.highlightDisabledDays === !1, E = (i) => r.highlightWeekDays && r.highlightWeekDays.includes(i.value.getDay()), Z = (i) => (r.range || r.weekPicker) && (!(r.multiCalendars > 0) || i.current) && H() && !(!i.current && r.hideOffsetDates) && !I(i) ? B(i) : !1, Y = (i) => ({
    dp__cell_offset: !i.current,
    dp__pointer: !r.disabled && !(!i.current && r.hideOffsetDates) && !a(i.value),
    dp__cell_disabled: a(i.value),
    dp__cell_highlight: !C(i) && (g(i) || E(i)) && !le(i),
    dp__cell_highlight_active: !C(i) && (g(i) || E(i)) && le(i),
    dp__today: !r.noToday && Ne(i.value, o.value) && i.current
  }), F = (i) => ({
    dp__active_date: le(i),
    dp__date_hover: te(i)
  }), ie = (i) => ({
    ...ue(i),
    ...x(i),
    dp__range_between_week: Z(i) && r.weekPicker
  }), ue = (i) => ({
    dp__range_start: r.multiCalendars > 0 ? i.current && s(i) && H() : s(i) && H(),
    dp__range_end: r.multiCalendars > 0 ? i.current && s(i, !1) && H() : s(i, !1) && H(),
    dp__range_between: Z(i) && !r.weekPicker,
    dp__date_hover_start: w(te(i), i, !0),
    dp__date_hover_end: w(te(i), i, !1)
  }), x = (i) => ({
    ...ue(i),
    dp__cell_auto_range: K(i),
    dp__cell_auto_range_start: X(i),
    dp__cell_auto_range_end: R(i)
  }), P = (i) => r.range ? r.autoRange ? x(i) : r.modelAuto ? { ...F(i), ...ue(i) } : ue(i) : r.weekPicker ? ie(i) : F(i);
  return {
    setHoverDate: u,
    clearHoverDate: v,
    getDayClassData: (i) => ({
      ...Y(i),
      ...P(i),
      [r.dayClass ? r.dayClass(i.value) : ""]: !0,
      [r.calendarCellClassName]: !!r.calendarCellClassName
    })
  };
}, ms = ["id", "onKeydown"], fs = {
  key: 0,
  class: "dp__sidebar_left"
}, ps = {
  key: 1,
  class: "dp__preset_ranges"
}, ys = ["onClick"], hs = {
  key: 2,
  class: "dp__sidebar_right"
}, gs = {
  key: 3,
  class: "dp__now_wrap"
}, ws = /* @__PURE__ */ nt({
  __name: "DatepickerMenu",
  props: {
    openOnTop: { type: Boolean, default: !1 },
    internalModelValue: { type: [Date, Array], default: null },
    ...xt
  },
  emits: [
    "close-picker",
    "select-date",
    "auto-apply",
    "time-update",
    "flow-step",
    "update-month-year",
    "invalid-select",
    "update:internal-model-value",
    "recalculate-position",
    "invalid-fixed-range",
    "tooltip-open",
    "tooltip-close"
  ],
  setup(e, { expose: r, emit: a }) {
    const t = e, { setMenuFocused: n, setShiftKey: l, control: o } = un(), { getCalendarDays: u, defaults: v } = et(t), h = Xa(), _ = q(null), m = aa({
      timePicker: !!(!t.enableTimePicker || t.timePicker || t.monthPicker),
      monthYearInput: !!t.timePicker,
      calendar: !1
    }), s = q([]), w = q([]), I = q(null), R = q(null), K = q(0), X = q(!1), B = q(0);
    ot(() => {
      var D;
      X.value = !0, !((D = t.presetRanges) != null && D.length) && !h["left-sidebar"] && !h["right-sidebar"] && mt();
      const Q = Ee(R);
      if (Q && !t.textInput && !t.inline && (n(!0), C()), Q) {
        const qe = (je) => {
          !t.monthYearComponent && !t.timePickerComponent && !Object.keys(h).length && je.preventDefault(), je.stopImmediatePropagation(), je.stopPropagation();
        };
        Q.addEventListener("pointerdown", qe), Q.addEventListener("mousedown", qe);
      }
      window.addEventListener("resize", mt);
    }), Ja(() => {
      window.removeEventListener("resize", mt);
    });
    const { arrowRight: A, arrowLeft: H, arrowDown: te, arrowUp: le } = Tt(), g = (D) => {
      D || D === 0 ? w.value[D].triggerTransition(
        ie.value(D),
        ue.value(D)
      ) : w.value.forEach(
        (Q, qe) => Q.triggerTransition(ie.value(qe), ue.value(qe))
      );
    }, C = () => {
      const D = Ee(R);
      D && D.focus({ preventScroll: !0 });
    }, E = () => {
      var D;
      (D = t.flow) != null && D.length && B.value !== -1 && (B.value += 1, a("flow-step", B.value), Ce());
    }, Z = () => {
      B.value = -1;
    }, {
      calendars: Y,
      modelValue: F,
      month: ie,
      year: ue,
      time: x,
      updateTime: P,
      updateMonthYear: i,
      selectDate: p,
      getWeekNum: T,
      monthYearSelect: b,
      handleScroll: N,
      handleArrow: j,
      handleSwipe: J,
      getMarker: y,
      selectCurrentDate: d,
      presetDateRange: M
    } = pu(t, a, E, g, B), { setHoverDate: Te, clearHoverDate: Le, getDayClassData: Ye } = vs(F, t);
    Yt(
      Y,
      () => {
        t.openOnTop && setTimeout(() => {
          a("recalculate-position");
        }, 0);
      },
      { deep: !0 }
    );
    const Ze = St(h, "calendar"), rt = St(h, "action"), de = St(h, "timePicker"), ce = St(h, "monthYear"), xe = V(() => t.openOnTop ? "dp__arrow_bottom" : "dp__arrow_top"), he = V(() => ou(t.yearRange)), qt = V(() => lu(t.locale, t.monthNameFormat)), mt = () => {
      const D = Ee(_);
      D && (K.value = D.getBoundingClientRect().width);
    }, Qt = V(() => (D) => u(ie.value(D), ue.value(D))), Mt = V(
      () => v.value.multiCalendars > 0 && t.range ? [...Array(v.value.multiCalendars).keys()] : [0]
    ), zt = V(
      () => (D) => D === 1
    ), Oa = V(() => t.monthPicker || t.timePicker || t.yearPicker), $a = V(
      () => ({
        dp__flex_display: v.value.multiCalendars > 0
      })
    ), na = V(() => ({
      dp__instance_calendar: v.value.multiCalendars > 0
    })), Gt = V(() => ({
      dp__menu_disabled: t.disabled,
      dp__menu_readonly: t.readonly
    })), Na = V(
      () => (D) => Aa(Qt, D)
    ), Sa = V(
      () => ({
        dp__menu: !0,
        dp__menu_index: !t.inline,
        dp__relative: t.inline,
        [t.menuClassName]: !!t.menuClassName
      })
    ), Aa = (D, Q) => D.value(Q).map((qe) => ({
      ...qe,
      days: qe.days.map((je) => (je.marker = y(je), je.classData = Ye(je), je))
    })), oa = (D) => {
      D.stopPropagation(), D.stopImmediatePropagation();
    }, c = () => {
      t.escClose && a("close-picker");
    }, O = (D, Q = !1) => {
      p(D, Q), t.spaceConfirm && a("select-date");
    }, ee = (D) => {
      var Q;
      (Q = t.flow) != null && Q.length && (m[D] = !0, Object.keys(m).filter((qe) => !m[qe]).length || Ce());
    }, re = (D, Q, qe, je, ...la) => {
      if (t.flow[B.value] === D) {
        const ne = je ? Q.value[0] : Q.value;
        ne && ne[qe](...la);
      }
    }, Ce = () => {
      re("month", s, "toggleMonthPicker", !0, !0), re("year", s, "toggleYearPicker", !0, !0), re("calendar", I, "toggleTimePicker", !1, !1, !0), re("time", I, "toggleTimePicker", !1, !0, !0);
      const D = t.flow[B.value];
      (D === "hours" || D === "minutes" || D === "seconds") && re(D, I, "toggleTimePicker", !1, !0, !0, D);
    }, lt = (D) => {
      if (t.arrowNavigation) {
        if (D === "up")
          return le();
        if (D === "down")
          return te();
        if (D === "left")
          return H();
        if (D === "right")
          return A();
      } else
        D === "left" || D === "up" ? j("left", 0, D === "up") : j("right", 0, D === "down");
    }, Ct = (D) => {
      l(D.shiftKey), !t.disableMonthYearSelect && D.code === "Tab" && D.target.classList.contains("dp__menu") && o.value.shiftKeyInMenu && (D.preventDefault(), D.stopImmediatePropagation(), a("close-picker"));
    };
    return r({
      updateMonthYear: i
    }), (D, Q) => {
      var qe;
      return k(), be(Ft, {
        appear: "",
        name: (qe = f(v).transitions) == null ? void 0 : qe.menuAppear,
        mode: "out-in",
        css: !!D.transitions
      }, {
        default: ve(() => {
          var je, la;
          return [
            se("div", {
              id: D.uid ? `dp-menu-${D.uid}` : void 0,
              tabindex: "0",
              ref_key: "dpMenuRef",
              ref: R,
              role: "dialog",
              class: Me(f(Sa)),
              onMouseleave: Q[14] || (Q[14] = //@ts-ignore
              (...ne) => f(Le) && f(Le)(...ne)),
              onClick: oa,
              onKeydown: [
                fe(c, ["esc"]),
                Q[15] || (Q[15] = fe(ht((ne) => lt("left"), ["prevent"]), ["left"])),
                Q[16] || (Q[16] = fe(ht((ne) => lt("up"), ["prevent"]), ["up"])),
                Q[17] || (Q[17] = fe(ht((ne) => lt("down"), ["prevent"]), ["down"])),
                Q[18] || (Q[18] = fe(ht((ne) => lt("right"), ["prevent"]), ["right"])),
                Ct
              ]
            }, [
              (D.disabled || D.readonly) && D.inline ? (k(), U("div", {
                key: 0,
                class: Me(f(Gt))
              }, null, 2)) : S("", !0),
              !D.inline && !D.teleportCenter ? (k(), U("div", {
                key: 1,
                class: Me(f(xe))
              }, null, 2)) : S("", !0),
              se("div", {
                class: Me({
                  dp__menu_content_wrapper: ((je = D.presetRanges) == null ? void 0 : je.length) || !!D.$slots["left-sidebar"] || !!D.$slots["right-sidebar"]
                })
              }, [
                D.$slots["left-sidebar"] ? (k(), U("div", fs, [
                  z(D.$slots, "left-sidebar")
                ])) : S("", !0),
                (la = D.presetRanges) != null && la.length ? (k(), U("div", ps, [
                  (k(!0), U(_e, null, Re(D.presetRanges, (ne, Et) => (k(), U("div", {
                    key: Et,
                    style: Nt(ne.style || {}),
                    class: "dp__preset_range",
                    onClick: (me) => f(M)(ne.range, !!ne.slot)
                  }, [
                    ne.slot ? z(D.$slots, ne.slot, {
                      key: 0,
                      presetDateRange: f(M),
                      label: ne.label,
                      range: ne.range
                    }) : (k(), U(_e, { key: 1 }, [
                      dt(Be(ne.label), 1)
                    ], 64))
                  ], 12, ys))), 128))
                ])) : S("", !0),
                se("div", {
                  class: "dp__instance_calendar",
                  ref_key: "calendarWrapperRef",
                  ref: _,
                  role: "document"
                }, [
                  se("div", {
                    class: Me(f($a))
                  }, [
                    (k(!0), U(_e, null, Re(f(Mt), (ne, Et) => (k(), U("div", {
                      key: ne,
                      class: Me(f(na))
                    }, [
                      !D.disableMonthYearSelect && !D.timePicker ? (k(), be(sa(D.monthYearComponent ? D.monthYearComponent : Ju), Ve({
                        key: 0,
                        ref_for: !0,
                        ref: (me) => {
                          me && (s.value[Et] = me);
                        },
                        months: f(qt),
                        years: f(he),
                        month: f(ie)(ne),
                        year: f(ue)(ne),
                        instance: ne,
                        "internal-model-value": e.internalModelValue
                      }, D.$props, {
                        onMount: Q[0] || (Q[0] = (me) => ee("monthYearInput")),
                        onResetFlow: Z,
                        onUpdateMonthYear: (me) => f(i)(ne, me),
                        onMonthYearSelect: f(b),
                        onOverlayClosed: C
                      }), Xe({ _: 2 }, [
                        Re(f(ce), (me, vn) => ({
                          name: me,
                          fn: ve((Ya) => [
                            z(D.$slots, me, Je(yt(Ya)))
                          ])
                        }))
                      ]), 1040, ["months", "years", "month", "year", "instance", "internal-model-value", "onUpdateMonthYear", "onMonthYearSelect"])) : S("", !0),
                      bt(Vu, Ve({
                        ref_for: !0,
                        ref: (me) => {
                          me && (w.value[Et] = me);
                        },
                        "specific-mode": f(Oa),
                        "get-week-num": f(T),
                        instance: ne,
                        "mapped-dates": f(Na)(ne),
                        month: f(ie)(ne),
                        year: f(ue)(ne)
                      }, D.$props, {
                        "flow-step": B.value,
                        "onUpdate:flowStep": Q[1] || (Q[1] = (me) => B.value = me),
                        onSelectDate: (me) => f(p)(me, !f(zt)(ne)),
                        onHandleSpace: (me) => O(me, !f(zt)(ne)),
                        onSetHoverDate: Q[2] || (Q[2] = (me) => f(Te)(me)),
                        onHandleScroll: (me) => f(N)(me, ne),
                        onHandleSwipe: (me) => f(J)(me, ne),
                        onMount: Q[3] || (Q[3] = (me) => ee("calendar")),
                        onResetFlow: Z,
                        onTooltipOpen: Q[4] || (Q[4] = (me) => D.$emit("tooltip-open", me)),
                        onTooltipClose: Q[5] || (Q[5] = (me) => D.$emit("tooltip-close", me))
                      }), Xe({ _: 2 }, [
                        Re(f(Ze), (me, vn) => ({
                          name: me,
                          fn: ve((Ya) => [
                            z(D.$slots, me, Je(yt({ ...Ya })))
                          ])
                        }))
                      ]), 1040, ["specific-mode", "get-week-num", "instance", "mapped-dates", "month", "year", "flow-step", "onSelectDate", "onHandleSpace", "onHandleScroll", "onHandleSwipe"])
                    ], 2))), 128))
                  ], 2),
                  se("div", null, [
                    D.$slots["time-picker"] ? z(D.$slots, "time-picker", Je(Ve({ key: 0 }, { time: f(x), updateTime: f(P) }))) : (k(), U(_e, { key: 1 }, [
                      D.enableTimePicker && !D.monthPicker && !D.weekPicker ? (k(), be(sa(D.timePickerComponent ? D.timePickerComponent : cs), Ve({
                        key: 0,
                        ref_key: "timePickerRef",
                        ref: I,
                        hours: f(x).hours,
                        minutes: f(x).minutes,
                        seconds: f(x).seconds,
                        "internal-model-value": e.internalModelValue
                      }, D.$props, {
                        onMount: Q[6] || (Q[6] = (ne) => ee("timePicker")),
                        "onUpdate:hours": Q[7] || (Q[7] = (ne) => f(P)(ne)),
                        "onUpdate:minutes": Q[8] || (Q[8] = (ne) => f(P)(ne, !1)),
                        "onUpdate:seconds": Q[9] || (Q[9] = (ne) => f(P)(ne, !1, !0)),
                        onResetFlow: Z,
                        onOverlayClosed: C
                      }), Xe({ _: 2 }, [
                        Re(f(de), (ne, Et) => ({
                          name: ne,
                          fn: ve((me) => [
                            z(D.$slots, ne, Je(yt(me)))
                          ])
                        }))
                      ]), 1040, ["hours", "minutes", "seconds", "internal-model-value"])) : S("", !0)
                    ], 64))
                  ])
                ], 512),
                D.$slots["right-sidebar"] ? (k(), U("div", hs, [
                  z(D.$slots, "right-sidebar")
                ])) : S("", !0),
                D.showNowButton ? (k(), U("div", gs, [
                  D.$slots["now-button"] ? z(D.$slots, "now-button", {
                    key: 0,
                    selectCurrentDate: f(d)
                  }) : S("", !0),
                  D.$slots["now-button"] ? S("", !0) : (k(), U("button", {
                    key: 1,
                    type: "button",
                    role: "button",
                    class: "dp__now_button",
                    onClick: Q[10] || (Q[10] = //@ts-ignore
                    (...ne) => f(d) && f(d)(...ne))
                  }, Be(D.nowButtonLabel), 1))
                ])) : S("", !0)
              ], 2),
              !D.autoApply || D.keepActionRow ? (k(), be(sa(D.actionRowComponent ? D.actionRowComponent : $u), Ve({
                key: 2,
                "menu-mount": X.value,
                "calendar-width": K.value,
                "internal-model-value": e.internalModelValue
              }, D.$props, {
                onClosePicker: Q[11] || (Q[11] = (ne) => D.$emit("close-picker")),
                onSelectDate: Q[12] || (Q[12] = (ne) => D.$emit("select-date")),
                onInvalidSelect: Q[13] || (Q[13] = (ne) => D.$emit("invalid-select"))
              }), Xe({ _: 2 }, [
                Re(f(rt), (ne, Et) => ({
                  name: ne,
                  fn: ve((me) => [
                    z(D.$slots, ne, Je(yt({ ...me })))
                  ])
                }))
              ]), 1040, ["menu-mount", "calendar-width", "internal-model-value"])) : S("", !0)
            ], 42, ms)
          ];
        }),
        _: 3
      }, 8, ["name", "css"]);
    };
  }
}), bs = typeof window < "u" ? window : void 0, Qa = () => {
}, _s = (e) => pn() ? (yn(e), !0) : !1, ks = (e, r, a, t) => {
  if (!e)
    return Qa;
  let n = Qa;
  const l = Yt(
    () => f(e),
    (u) => {
      n(), u && (u.addEventListener(r, a, t), n = () => {
        u.removeEventListener(r, a, t), n = Qa;
      });
    },
    { immediate: !0, flush: "post" }
  ), o = () => {
    l(), n();
  };
  return _s(o), o;
}, Ds = (e, r, a, t = {}) => {
  const { window: n = bs, event: l = "pointerdown" } = t;
  return n ? ks(n, l, (o) => {
    const u = Ee(e), v = Ee(r);
    !u || !v || u === o.target || o.composedPath().includes(u) || o.composedPath().includes(v) || a(o);
  }, { passive: !0 }) : void 0;
}, Ts = /* @__PURE__ */ nt({
  __name: "VueDatePicker",
  props: {
    ...xt
  },
  emits: [
    "update:model-value",
    "text-submit",
    "closed",
    "cleared",
    "open",
    "focus",
    "blur",
    "internal-model-change",
    "recalculate-position",
    "flow-step",
    "update-month-year",
    "invalid-select",
    "invalid-fixed-range",
    "tooltip-open",
    "tooltip-close"
  ],
  setup(e, { expose: r, emit: a }) {
    const t = e, n = Xa(), l = q(!1), o = ma(t, "modelValue"), u = ma(t, "timezone"), v = q(null), h = q(null), _ = q(!1), { setMenuFocused: m, setShiftKey: s } = un(), { clearArrowNav: w } = Tt(), { validateDate: I, isValidTime: R, defaults: K } = et(t);
    ot(() => {
      E(t.modelValue), t.inline || (window.addEventListener("scroll", x), window.addEventListener("resize", P)), t.inline && (l.value = !0);
    }), Ja(() => {
      t.inline || (window.removeEventListener("scroll", x), window.removeEventListener("resize", P));
    });
    const X = St(n, "all", t.presetRanges), B = St(n, "input");
    Yt(
      [o, u],
      () => {
        E(o.value);
      },
      { deep: !0 }
    );
    const { openOnTop: A, menuPosition: H, setMenuPosition: te, setInitialPosition: le } = gu(
      v,
      h,
      a,
      t
    ), {
      inputValue: g,
      internalModelValue: C,
      parseExternalModelValue: E,
      emitModelValue: Z,
      formatInputValue: Y,
      checkBeforeEmit: F
    } = yu(a, t, _), ie = V(
      () => ({
        dp__main: !0,
        dp__theme_dark: t.dark,
        dp__theme_light: !t.dark,
        dp__flex_display: t.inline,
        dp__flex_display_with_input: t.inlineWithInput
      })
    ), ue = V(() => t.dark ? "dp__theme_dark" : "dp__theme_light"), x = () => {
      l.value && (t.closeOnScroll ? y() : t.autoPosition && te());
    }, P = () => {
      l.value && te();
    }, i = () => {
      !t.disabled && !t.readonly && (le(), l.value = !0, Bt().then(() => {
        te(), l.value && a("open");
      }), l.value || J(), E(t.modelValue));
    }, p = () => {
      g.value = "", J(), a("update:model-value", null), a("cleared"), y();
    }, T = () => {
      const ce = C.value;
      return !ce || !Array.isArray(ce) && I(ce) ? !0 : Array.isArray(ce) ? ce.length === 2 && I(ce[0]) && I(ce[1]) ? !0 : I(ce[0]) : !1;
    }, b = () => {
      F() && T() ? (Z(), y()) : a("invalid-select", C.value);
    }, N = (ce) => {
      Z(), t.closeOnAutoApply && !ce && y();
    }, j = (ce = !1) => {
      t.autoApply && R(C.value) && T() && (t.range && Array.isArray(C.value) ? (t.partialRange || C.value.length === 2) && N(ce) : N(ce));
    }, J = () => {
      t.textInput || (C.value = null);
    }, y = () => {
      t.inline || (l.value && (l.value = !1, m(!1), s(!1), w(), a("closed"), le(), g.value && E(o.value)), J(), h.value && h.value.focusInput());
    }, d = (ce, xe) => {
      if (!ce) {
        C.value = null;
        return;
      }
      C.value = ce, xe && (b(), a("text-submit"));
    }, M = () => {
      t.autoApply && R(C.value) && Z();
    }, Te = () => l.value ? y() : i(), Le = (ce) => {
      C.value = ce;
    }, Ye = V(() => t.textInput && K.value.textInputOptions.format), Ze = () => {
      Ye.value && (_.value = !0, Y()), a("focus");
    }, rt = () => {
      Ye.value && (_.value = !1, Y()), a("blur");
    }, de = (ce) => {
      v.value && v.value.updateMonthYear(0, {
        month: $r(ce.month),
        year: $r(ce.year)
      });
    };
    return Ds(
      v,
      h,
      t.onClickOutside ? () => t.onClickOutside(T) : y
    ), r({
      closeMenu: y,
      selectDate: b,
      clearValue: p,
      openMenu: i,
      onScroll: x,
      formatInputValue: Y,
      // exposed for testing purposes
      updateInternalModelValue: Le,
      // modify internal modelValue
      setMonthYear: de
    }), (ce, xe) => (k(), U("div", {
      class: Me(f(ie))
    }, [
      bt(Mu, Ve({
        ref_key: "inputRef",
        ref: h,
        "is-menu-open": l.value,
        "input-value": f(g),
        "onUpdate:inputValue": xe[0] || (xe[0] = (he) => $t(g) ? g.value = he : null)
      }, ce.$props, {
        onClear: p,
        onOpen: i,
        onSetInputDate: d,
        onSetEmptyDate: f(Z),
        onSelectDate: b,
        onToggle: Te,
        onClose: y,
        onFocus: Ze,
        onBlur: rt
      }), Xe({ _: 2 }, [
        Re(f(B), (he, qt) => ({
          name: he,
          fn: ve((mt) => [
            z(ce.$slots, he, Je(yt(mt)))
          ])
        }))
      ]), 1040, ["is-menu-open", "input-value", "onSetEmptyDate"]),
      l.value ? (k(), be(mn, {
        key: 0,
        to: ce.teleport,
        disabled: ce.inline
      }, [
        l.value ? (k(), be(ws, Ve({
          key: 0,
          ref_key: "dpMenuRef",
          ref: v,
          class: f(ue),
          style: f(H),
          "open-on-top": f(A)
        }, ce.$props, {
          "internal-model-value": f(C),
          "onUpdate:internalModelValue": xe[1] || (xe[1] = (he) => $t(C) ? C.value = he : null),
          onClosePicker: y,
          onSelectDate: b,
          onAutoApply: j,
          onTimeUpdate: M,
          onFlowStep: xe[2] || (xe[2] = (he) => ce.$emit("flow-step", he)),
          onUpdateMonthYear: xe[3] || (xe[3] = (he) => ce.$emit("update-month-year", he)),
          onInvalidSelect: xe[4] || (xe[4] = (he) => ce.$emit("invalid-select", f(C))),
          onInvalidFixedRange: xe[5] || (xe[5] = (he) => ce.$emit("invalid-fixed-range", he)),
          onRecalculatePosition: f(te),
          onTooltipOpen: xe[6] || (xe[6] = (he) => ce.$emit("tooltip-open", he)),
          onTooltipClose: xe[7] || (xe[7] = (he) => ce.$emit("tooltip-close", he))
        }), Xe({ _: 2 }, [
          Re(f(X), (he, qt) => ({
            name: he,
            fn: ve((mt) => [
              z(ce.$slots, he, Je(yt({ ...mt })))
            ])
          }))
        ]), 1040, ["class", "style", "open-on-top", "internal-model-value", "onRecalculatePosition"])) : S("", !0)
      ], 8, ["to", "disabled"])) : S("", !0)
    ], 2));
  }
}), cr = /* @__PURE__ */ (() => {
  const e = Ts;
  return e.install = (r) => {
    r.component("Vue3DatePicker", e);
  }, e;
})(), xs = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: cr
}, Symbol.toStringTag, { value: "Module" }));
Object.entries(xs).forEach(([e, r]) => {
  e !== "default" && (cr[e] = r);
});
function kt(e, r) {
  return e.getFullYear() === r.getFullYear() && e.getMonth() === r.getMonth() && e.getDate() === r.getDate();
}
function Ms(e, r) {
  const a = /* @__PURE__ */ new Date();
  return kt(e, Ma(a)) && kt(r, xa(a));
}
function Cs(e, r) {
  const a = /* @__PURE__ */ new Date(), t = Ma(ct(a, 1)), n = xa(ct(a, 1));
  return kt(e, t) && kt(r, n);
}
function Ps(e, r) {
  const a = /* @__PURE__ */ new Date();
  return kt(e, It(a, { weekStartsOn: 1 })) && kt(r, ra(a, { weekStartsOn: 1 }));
}
function Os(e, r) {
  const a = /* @__PURE__ */ new Date(), t = wa(It(a, { weekStartsOn: 1 }), 1), n = wa(ra(a, { weekStartsOn: 1 }), 1);
  return kt(e, t) && kt(r, n);
}
function $s() {
  const e = /* @__PURE__ */ new Date();
  return [Ma(e), xa(e)];
}
function Ns() {
  return [It(/* @__PURE__ */ new Date(), { weekStartsOn: 1 }), ra(/* @__PURE__ */ new Date(), { weekStartsOn: 1 })];
}
function Ss() {
  return [Ma(ct(/* @__PURE__ */ new Date(), 1)), xa(ct(/* @__PURE__ */ new Date(), 1))];
}
function As() {
  return [
    wa(It(/* @__PURE__ */ new Date(), { weekStartsOn: 1 }), 1),
    wa(ra(/* @__PURE__ */ new Date(), { weekStartsOn: 1 }), 1)
  ];
}
const Ys = (e, r) => {
  const a = e.__vccOpts || e;
  for (const [t, n] of r)
    a[t] = n;
  return a;
}, Is = {
  components: { Datepicker: cr },
  props: {
    placeholder: {
      type: String
    },
    modelValue: {
      type: Array,
      required: !0
    },
    useQueryParams: {
      type: Boolean,
      default: !1
      // Store values in query params (start_date, end_date), useful for navigation persistence/link sharing
    },
    ranges: {
      type: Array,
      default: () => []
    }
  },
  beforeMount() {
    this.useQueryParams && this.loadQueryParams();
  },
  data: () => ({
    defaultRanges: [
      {
        id: 1,
        label: "Mois en cours",
        range: $s()
      },
      {
        id: 2,
        label: "Semaine en cours",
        range: Ns()
      },
      {
        id: 3,
        label: "Mois précédent",
        range: Ss()
      },
      {
        id: 4,
        label: "Semaine précédente",
        range: As()
      }
    ]
  }),
  computed: {
    presetRanges() {
      const e = this.ranges.map((r, a) => ({
        id: a + this.defaultRanges.length + 1,
        label: r.label,
        range: r.range
      }));
      return [...this.defaultRanges, ...e];
    },
    internalValue: {
      get() {
        var e;
        return (e = this.modelValue) != null && e.every((r) => r == null) ? null : this.modelValue;
      },
      set(e) {
        let r = null, a = null;
        e && (r = this.formatDate(e[0]), a = this.formatDate(e[1])), this.useQueryParams && this.updateQueryParams(r, a), this.$emit("update:modelValue", [r, a]);
      }
    }
  },
  methods: {
    formatDate(e) {
      return _t(e, "yyyy-MM-dd");
    },
    datePickerFormat(e) {
      const r = e[0], a = e[1];
      if (!r || !a) return "";
      if (r instanceof Date && a instanceof Date) {
        if (Ms(r, a)) return "Mois en cours";
        if (Cs(r, a)) return "Mois précédent";
        if (Ps(r, a)) return "Semaine en cours";
        if (Os(r, a)) return "Semaine précédente";
      }
      const t = _t(r, "dd/MM/yyyy"), n = _t(a, "dd/MM/yyyy");
      return `Du ${t} au ${n}`;
    },
    loadQueryParams() {
      const e = this.$route.query.start_date, r = this.$route.query.end_date;
      e && r && (this.internalValue = [new Date(e), new Date(r)]);
    },
    updateQueryParams(e, r) {
      const a = { ...this.$route.query };
      !e || !r ? (delete a.start_date, delete a.end_date) : (a.start_date = e, a.end_date = r), this.$router.push({ query: a });
    }
  }
};
function Us(e, r, a, t, n, l) {
  const o = hn("Datepicker");
  return k(), be(o, {
    placeholder: a.placeholder,
    class: "text-sm w-full rounded-md",
    modelValue: l.internalValue,
    "onUpdate:modelValue": r[0] || (r[0] = (u) => l.internalValue = u),
    range: "",
    multiCalendars: "",
    presetRanges: l.presetRanges,
    autoApply: "",
    enableTimePicker: !1,
    format: l.datePickerFormat,
    locale: "fr",
    "max-date": /* @__PURE__ */ new Date()
  }, null, 8, ["placeholder", "modelValue", "presetRanges", "format", "max-date"]);
}
const Es = /* @__PURE__ */ Ys(Is, [["render", Us]]);
export {
  Es as DateRangePicker
};
