<template>
  <div>
    <PageHeaderTitle title="Insurer reportings" />
    <div class="mb-6 grid grid-cols-1">
      <div class="px-4 py-5 bg-white shadow rounded-lg sm:px-5 sm:py-3 font-bold">
        <p class="text-gray-900 text-md">Récapitulatif</p>
        <hr class="my-2 border-gray-300" />
        <div class="grid grid-rows-4 md:grid-cols-4 md:grid-rows-none mt-2">
          <div>
            <p class="text-gray-700 font-normal">Montant à verser aux assureurs</p>
            <p class="text-red-700 font-light text-2xl">
              {{ formatPrice(totalCommissionsAmountDueToInsurer) }}
            </p>
          </div>
          <div>
            <p class="text-gray-700 font-normal">Montant à percevoir des assureurs</p>
            <p class="text-green-700 font-light text-2xl">
              {{ formatPrice(totalClaimsAmountDueToEstaly) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-6 flex w-full gap-10 items-center">
      <MonthlyDateFilterSelector
        class="w-1/4"
        placeholder="Sélectionner une période"
        @newDateSelected="updateDateFilters"
      />
      <SearchBarFilter
        v-for="(filterCategory, filtersName, filterIndex) in availableFiltersWithOptions"
        v-bind:key="filterIndex"
        v-bind:filterName="filtersName"
        v-bind:filterIdx="filterIndex"
        v-bind:current-page="currentPage"
        v-bind:available-filters="filters"
        v-bind:selected-filters="currentFilteringData"
        @updateFilterSearch="updateCurrentFilteringData"
      />
    </div>
    <Table :columns="columns" :data="insurerReportings" :is-downloadable="true" @download="downloadReporting">
      <template #commissionsPaymentStatus="{ row, column }">
        <select
          :value="row[column.field]"
          @change="(event) => handleStatusUpdate({ field: column.field, row, value: event.target.value })"
          :class="[
            'block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6',
            getSelectClass(row[column.field], column),
          ]"
        >
          <option v-for="option in column.options" :key="option" :value="option">
            {{ $t(option) }}
          </option>
        </select>
      </template>
      <template #claimsPaymentStatus="{ row, column }">
        <select
          :value="row[column.field]"
          @change="(event) => handleStatusUpdate({ field: column.field, row, value: event.target.value })"
          :class="[
            'block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6',
            getSelectClass(row[column.field], column),
          ]"
        >
          <option v-for="option in column.options" :key="option" :value="option">
            {{ $t(option) }}
          </option>
        </select>
      </template>
    </Table>
    <Pagination
      v-bind:currentPage="currentPage"
      v-bind:pages="pages"
      v-bind:startIdx="startIdx"
      v-bind:endIdx="endIdx"
      v-bind:total="totalNumberOfInsurerReportings"
      v-on:changePage="handleChangePage"
      v-if="pages.length > 1"
    />
  </div>
</template>

<script>
import PageHeaderTitle from "@/components/utils/PageHeaderTitle.vue";
import MonthlyDateFilterSelector from "@/components/menu/MonthlyDateFilterSelector.vue";
import SearchBarFilter from "@/components/menu/SearchBarFilter.vue";
import Table from "@/components/utils/Table.vue";
import Pagination from "@/components/utils/Pagination.vue";
import BackofficeService from "@/api/services/backoffice";
import { mapActions } from "vuex";
import { formatPrice } from "@/utils/price_formatter";

const PAGE_SIZE = 25;

export default {
  components: {
    PageHeaderTitle,
    MonthlyDateFilterSelector,
    SearchBarFilter,
    Table,
    Pagination,
  },

  computed: {
    pages() {
      if (this.totalNumberOfInsurerReportings < PAGE_SIZE) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfInsurerReportings / PAGE_SIZE)).keys()].map((e) => e + 1);
    },

    startIdx() {
      return (this.currentPage - 1) * PAGE_SIZE;
    },

    endIdx() {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfInsurerReportings);
    },

    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1)
      );
    },
  },

  data() {
    return {
      insurerReportings: [],
      columns: [
        { label: "Assureur", field: "insurerName" },
        { label: "Numéro de police", field: "insurerContractGroupReference" },
        { label: "Période", field: "period" },
        { label: "Montant primes à verser", field: "commissionsAmountDueToInsurer", numeric: true, price: true },
        { label: "Montant sinistres à percevoir", field: "claimsAmountDueToEstaly", numeric: true, price: true },
        {
          label: "Statut paiement primes",
          field: "commissionsPaymentStatus",
          slot: "commissionsPaymentStatus",
          type: "selector",
          options: ["pending", "paid"],
          colors: {
            pending: "bg-yellow-50 text-yellow-600",
            paid: "bg-green-50 text-green-600",
          },
        },
        {
          label: "Statut paiement sinistres",
          field: "claimsPaymentStatus",
          slot: "claimsPaymentStatus",
          type: "selector",
          options: ["pending", "paid"],
          colors: {
            pending: "bg-yellow-50 text-yellow-600",
            paid: "bg-green-50 text-green-600",
          },
        },
      ],
      filters: {
        insurers: [],
        insurer_contract_group_references: [],
        commissions_payment_status: [],
        claims_payment_status: [],
      },
      totalNumberOfInsurerReportings: 0,
      currentPage: 1,
      currentFilteringData: {
        insurers: [],
        insurer_contract_group_references: [],
        commissions_payment_status: [],
        claims_payment_status: [],
        start_date: null,
        end_date: null,
      },
      totalCommissionsAmountDueToInsurer: null,
      totalClaimsAmountDueToEstaly: null,
    };
  },

  mounted() {
    this.retrieveInsurerReportings();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    formatPrice,

    updateDateFilters: function (startDate, endDate) {
      this.currentFilteringData["start_date"] = startDate;
      this.currentFilteringData["end_date"] = endDate;
      this.handleChangePage(1);
    },

    updateCurrentFilteringData: function (filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveInsurerReportings();
    },

    getSelectClass(value, column) {
      if (column.type === "selector" && column.colors && column.colors[value]) {
        return column.colors[value];
      }
      return "bg-white text-gray-900";
    },

    downloadReporting(insurerReporting) {
      window.open(insurerReporting.fileUrl, "_blank");
    },

    async retrieveInsurerReportings() {
      try {
        const response = await BackofficeService.getInsurerReportings(this.currentPage, this.currentFilteringData);
        this.insurerReportings = response.data.insurerReportings;
        this.totalNumberOfInsurerReportings = response.data.totalNumberOfInsurerReportings;
        this.filters = response.data.filters;
        this.totalCommissionsAmountDueToInsurer = response.data.totalCommissionsAmountDueToInsurer;
        this.totalClaimsAmountDueToEstaly = response.data.totalClaimsAmountDueToEstaly;
        console.log(this.totalCommissionsAmountDueToInsurer);
      } catch (error) {
        console.log(error);
      }
    },

    async handleStatusUpdate({ field, row, value }) {
      try {
        const data = {
          [field]: value,
        };
        await BackofficeService.updateInsurerReporting(row.id, data);
        this.notify({
          category: "simple",
          type: "success",
          title: "Status updated",
        });
        await this.retrieveInsurerReportings();
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Error when trying to update status",
          text: error.response.data.error,
        });
      }
    },
  },
};
</script>
